import { TOOLTIP_INFO } from './sidebar';

export const MAP_CONSTANTS = {
  BASEMAP: {
    STREET: 'mapbox://styles/common-spirit-health/cl20kf7yo000515tiz1n2896f',
    DARK: 'mapbox://styles/common-spirit-health/cl20kg5g9000615tidoakjh37',
    SATELLITE: 'mapbox://styles/common-spirit-health/cl20kgxal000014mn4b3s8tn5'
  },
  BASEMAP_NAME: {
    STREET: 'STREET',
    DARK: 'DARK',
    SATELLITE: 'SATELLITE'
  },
  TOKEN: process.env.REACT_APP_MAPBOX_TOKEN || '',
  US_BOUNDS: [
    [-138.867188, 12.125264],
    [-50.097656, 56.072035]
  ],
  US_BOUNDS_FIT: [
    [-127.001953, 24.527135],
    [-64.775391, 49.724479]
  ],
  CENTER: [-96.9677047, 38.029519],
  ZOOM: 4,
  DIVISION: 'Division',
  MARKET: 'Market',
  GME: 'GME',
  EBITDA: 'EBITDA',
  HEALT_EQUITY: 'Health Equity',
  LICENSED_BEDS: 'Licensed Beds',
  FIELDS_CSH: {
    CHARITY_MEDICAID_SERVICE: 'charity_medicaid_service',
    ENTITY_TYPE: 'entity_type',
    ENTITYID: 'entityid',
    GME: 'gme',
    HEALTH_EQUALITY: 'health_equity',
    STATE_ABBREV: 'abbrev',
    TAX_STATUS: 'tax_status',
    LICENSED_BEDS_ACUTE: 'licensed_beds_acute',
    LICENSED_BEDS_LTC: 'licensed_beds_ltc',
    OPERATING_BEDS_ACUTE: 'operating_beds_acute',
    OPERATING_BEDS_LTC: 'operating_beds_ltc',
    PATIENT_CARE_QUALITY_ACUTE_SEPSIS_MORTALITY: 'patient_care_quality_acute_sepsis_mortality',
    PATIENT_CARE_QUALITY_CONTINUUM_OF_CARE_HYPERTENSION_MANAGEME:
      'patient_care_quality_continuum_of_care_hypertension_manageme',
    PATIENT_EXPERIENCE_ACUTE_CARE_EXPERIENCE: 'patient_experience_acute_care_experience',
    PATIENT_EXPERIENCE_MEDICAL_PRACTICE_EXPERIENCE: 'patient_experience_medical_practice_experience',
    PATIENT_EXPERIENCE_VIRTUAL_MEDICAL_PRACTICE_EXPERIENCE: 'patient_experience_virtual_medical_practice_experience',
    PATIENT_SAFETY: 'patient_safety',
    EMPLOYEE_ENGAGEMENT: 'employee_engagement',
    NEW_HIRE_ADJUSTED_RETENTION_RATE: 'new_hire_adjusted_retention_rate',
    PHYSICIAN_APP_EXPERIENCE: 'physician_app_experience',
    EBITDA_AFTER_SPECIAL_CHARGES: 'ebitda_after_special_charges',
    DAYS_CASH: 'days_cash',
    NPSR_GROWTH: 'npsr_growth',
    REVENUE_FROM_DIVERSIFIED_NON_ACUTE_SOURCES: 'revenue_from_diversified_non_acute_sources',
    PHILANTHROPY: 'philanthropy',
    MEDIAN_INCOME: 'median_income',
    POPULATION_PER_INCOME_GROUP: 'population_per_income_group',
    INCOME_PER_CAPITA: 'income_per_capita',
    INCOME_LESS_10000: 'income_less_10000',
    INCOME_10000_14999: 'income_10000_14999',
    INCOME_15000_19999: 'income_15000_19999',
    INCOME_20000_24999: 'income_20000_24999',
    INCOME_25000_29999: 'income_25000_29999',
    INCOME_30000_34999: 'income_30000_34999',
    INCOME_35000_39999: 'income_35000_39999',
    INCOME_40000_44999: 'income_40000_44999',
    INCOME_45000_49999: 'income_45000_49999',
    INCOME_50000_59999: 'income_50000_59999',
    INCOME_60000_74999: 'income_60000_74999',
    INCOME_75000_99999: 'income_75000_99999',
    INCOME_100000_124999: 'income_100000_124999',
    INCOME_125000_149999: 'income_125000_149999',
    INCOME_150000_199999: 'income_150000_199999',
    INCOME_200000_or_more: 'income_200000_or_more',
    POPULATION_UNDER_5_YEARS_OLD: 'population_under_5_years_old',
    POPULATION_OVER_16_YEARS_OLD: 'population_over_16_years_old',
    POPULATION_BY_RACE: 'population_by_race',
    POPULATION_BY_EDUCATION_ATTAINMENT: 'population_by_education_attainment',
    GENDER: 'gender',
    FEMALE_POP: 'female_pop',
    MALE_POP: 'male_pop',
    MEDIAN_AGE: 'median_age',
    UNDER_5_POP: 'under_5',
    MALE_UNDER_5_POP: 'male_under_5',
    FEMALE_UNDER_5_POP: 'female_under_5',
    POP_16_OVER: 'pop_16_over',
    POP_BY_RACE: 'pop_by_race',
    WHITE_POP: 'white_pop',
    BLACK_POP: 'black_pop',
    ASIAN_POP: 'asian_pop',
    HISPANIC_POP: 'hispanic_pop',
    AMERINDIAN_POP: 'amerindian_pop',
    OTHER_RACE_POP: 'other_race_pop',
    HIGH_SCHOOL_DIPLOMA: 'high_school_diploma',
    ASSOCIATES_DEGREE: 'associates_degree',
    BACHELORS_DEGREE: 'bachelors_degree',
    MASTERS_DEGREE: 'masters_degree',
    DOCTORATE_DEGREE: 'doctorate_degree',
    OWNER_OCCUPIED_HOUSING_UNITS_MEDIAN_VALUE: 'owner_occupied_housing_units_median_value',
    OWNER_OCCUPIED_HOUSING_UNITS: 'owner_occupied_housing_units',
    HOUSING_UNITS_RENTER_OCCUPIED: 'housing_units_renter_occupied',
    MEDIAN_RENT: 'median_rent',
    PERCENT_INCOME_SPENT_ON_RENT: 'percent_income_spent_on_rent',
    RENT_OVER_50_PERCENT: 'rent_over_50_percent',
    RENT_40_TO_50_PERCENT: 'rent_40_to_50_percent',
    RENT_35_TO_40_PERCENT: 'rent_35_to_40_percent',
    RENT_30_TO_35_PERCENT: 'rent_30_to_35_percent',
    RENT_25_TO_30_PERCENT: 'rent_25_to_30_percent',
    RENT_20_TO_25_PERCENT: 'rent_20_to_25_percent',
    RENT_15_TO_20_PERCENT: 'rent_15_to_20_percent',
    RENT_10_TO_15_PERCENT: 'rent_10_to_15_percent',
    RENT_UNDER_10_PERCENT: 'rent_under_10_percent',
    EMPLOYED_POP: 'employed_pop',
    UNEMPLOYED_POP: 'unemployed_pop',
    HOUSEHOLD_LANGUAGE_ENGLISH: 'household_language_english',
    HOUSEHOLD_LANGUAGE_SPANISH_LIMITED_ENGLISH: 'household_language_spanish_limited_english',
    HOUSEHOLD_LANGUAGE_OTHER_INDO_EUROPEAN_LIMITED_ENGLISH: 'household_language_other_indo_european_limited_english',
    HOUSEHOLD_LANGUAGE_ASIAN_AND_PACIFIC_ISLAND_LIMITED_ENGLISH:
    'household_language_asian_and_pacific_island_limited_english',
    HOUSEHOLD_LANGUAGE_OTHER_LIMITED_ENGLISH: 'household_language_other_limited_english',
    INTERNET_SUBSCRIPTION_NO_ACCESS: 'internet_subscription_no_access',
    INTERNET_SUBSCRIPTION_TOTAL: 'internet_subscription_total',
    INTERNET_SUBSCRIPTION_BROADBAND: 'internet_subscription_broadband',
    INTERNET_SUBSCRIPTION_SATELLITE: 'internet_subscription_satellite',
    INTERNET_SUBSCRIPTION_DIAL_UP: 'internet_subscription_dial_up',
  },
  FIELDS_STATE: {
    ABBREV: 'abbrev'
  },
  FIELDS_DIVISION: {
    DIVISION: 'division'
  },
  FIELDS_CENSUS: {
    ENTITYID: 'entityid',
    CARTODBID: 'cartodb_id'
  },
  LAYER_NAME_LIST: [
    'airport-label',
    'country-label',
    'state-label',
    'natural-line-label',
    'natural-point-label',
    'path-pedestrian-label',
    'road-label-simple',
    'settlement-major-label',
    'settlement-minor-label',
    'settlement-subdivision-label',
    'country-boundaries',
    'download-1-a3vozz'
  ],
  POPUP_ACGME_TITLES: {
    INSTITUTION: 'Institution',
    PHONE: 'phone',
    EFFECTIVE_DATE: 'Effective date',
    EMAIL: 'Email'
  },
  POPUP_CENSUS_TITLES: {
    WHITE: 'White',
    BLACK: 'Black',
    ASIAN: 'Asian',
    HISPANIC: 'Hispanic',
    AMERICAN_INDIAN: 'American Indian',
    OTHER: 'Other',
    MEDIAN_INCOME: 'Median Income',
    INCOME_ON_RENT: '% Income Spent on Rent',
    EMPLOYED_POP: '% Population Employed',
    HOUSEHOLD_LANGUAGE_TOTAL: '% Household with Limited English',
    INTERNET_SUBSCRIPTION_BROADBAND: '% Population with No Internet Access',
    INTERNET_SUBSCRIPTION_NO_ACCESS: '% Population with Broadband Internet'
  },
  POPUP_CHEA_TITLES: {
    URL: 'Url'
  },
  LEGEND_TITLES: {
    CENSUS_BLOCK_GROUPS: 'Census Block Groups',
    ACGME_PROGRAMS: 'ACGME Programs',
    CHEA_PROGRAMS: 'CHEA Programs',
    CSH_FACILITES: 'CSH Facilities'
  }
};

export const STATE = 'state';

export const DIVISION = 'division';
const POPULATION_LEGEND = [
  { className: 'census-female-0-20', name: '0-20%' },
  { className: 'census-female-20-40', name: '20-40%' },
  { className: 'census-female-40-60', name: '40-60%' },
  { className: 'census-female-60-80', name: '60-80%' },
  { className: 'census-female-80-100', name: '80-100%' }
];
const POPULATION_LEGEND_UNDER = [
  { className: 'census-pop-under-0-20', name: '0-1%' },
  { className: 'census-pop-under-20-40', name: '1%-2%' },
  { className: 'census-pop-under-40-60', name: '2%-3%' },
  { className: 'census-pop-under-60-80', name: '3%-4%' },
  { className: 'census-pop-under-80-100', name: '4%-5%' }
];
const POPULATION_POP_EDU = [
  { className: 'census-pop-under-0-20', name: '0-6%' },
  { className: 'census-pop-under-20-40', name: '6%-12%' },
  { className: 'census-pop-under-40-60', name: '12%-18%' },
  { className: 'census-pop-under-60-80', name: '18%-24%' },
  { className: 'census-pop-under-80-100', name: '24%-30%' }
];
const POPULATION_MALE_LEGEND = [
  { className: 'census-male-0-20', name: '0-20%' },
  { className: 'census-male-20-40', name: '20-40%' },
  { className: 'census-male-40-60', name: '40-60%' },
  { className: 'census-male-60-80', name: '60-80%' },
  { className: 'census-male-80-100', name: '80-100%' }
];
const INTERNET_LEGEND = [
  { className: 'census-internet_5-0-20', name: '0-20%' },
  { className: 'census-internet_5-20-40', name: '20-40%' },
  { className: 'census-internet_5-40-60', name: '40-60%' },
  { className: 'census-internet_5-60-80', name: '60-80%' },
  { className: 'census-internet_5-80-100', name: '80-100%' }
];
const MEDIAN_AGE = [
  { className: 'census-median-age-0-20', name: '0 - 30 Yrs' },
  { className: 'census-median-age-20-40', name: '30 - 40 Yrs' },
  { className: 'census-median-age-40-60', name: '40 - 50 Yrs' },
  { className: 'census-median-age-60-80', name: '50 - 60 Yrs' },
  { className: 'census-median-age-80-100', name: 'More than 60 Yrs' }
];
const MEDIAN_INCOME = [
  { className: 'census-median_inc-0-20', name: '0 - $20,000' },
  { className: 'census-median_inc-20-40', name: '$20,000 - $40,000' },
  { className: 'census-median_inc-40-60', name: '$40,000 - $60,000' },
  { className: 'census-median_inc-60-80', name: '$60,000 - $80,000' },
  { className: 'census-median_inc-80-100', name: 'More than $80,000' }
];
const HOUSING_UNITS_RENT = [
  { className: 'census-median_inc-0-20', name: '0 - 10%' },
  { className: 'census-median_inc-20-40', name: '10% - 20%' },
  { className: 'census-median_inc-40-60', name: '20% - 30%' },
  { className: 'census-median_inc-60-80', name: '30% - 40%' },
  { className: 'census-median_inc-80-100', name: '40% - 50%' }
];
const HOUSING_UNITS_OWN = [
  { className: 'census-median_inc-0-20', name: '0 - 20%' },
  { className: 'census-median_inc-20-40', name: '20% - 40%' },
  { className: 'census-median_inc-40-60', name: '40% - 60%' },
  { className: 'census-median_inc-60-80', name: '60% - 80%' },
  { className: 'census-median_inc-80-100', name: '80% - 100%' }
];
const HOME_MEDIAN = [
  { className: 'census-median_inc-0-20', name: '0-$300,000' },
  { className: 'census-median_inc-20-40', name: '$300,000-$500,000' },
  { className: 'census-median_inc-40-60', name: '$500,000-$700,000' },
  { className: 'census-median_inc-60-80', name: '$700,000-$1,000,000' },
  { className: 'census-median_inc-80-100', name: 'More than $1,000,000' }
];
const ECONOMY_SUBSLIDER = [
  { className: 'census-median_inc-0-20', name: '0 - 5%' },
  { className: 'census-median_inc-20-40', name: '5% - 10%' },
  { className: 'census-median_inc-40-60', name: '10% - 15%' },
  { className: 'census-median_inc-60-80', name: '15% - 20%' },
  { className: 'census-median_inc-80-100', name: 'More than 20%' }
];
const ECONOMY_SUBSLIDER_MORE = [
  { className: 'census-median_inc-0-20', name: '0 - 6%' },
  { className: 'census-median_inc-20-40', name: '6% - 12%' },
  { className: 'census-median_inc-40-60', name: '12% - 18%' },
  { className: 'census-median_inc-60-80', name: '18% - 24%' },
  { className: 'census-median_inc-80-100', name: 'More than 24%' }
];
const MEDRIA_RENT_SUBSLIDER = [
  { className: 'census-median_inc-0-20', name: '0 - 1%' },
  { className: 'census-median_inc-20-40', name: '2% - 5%' },
  { className: 'census-median_inc-40-60', name: '5% - 10%' },
  { className: 'census-median_inc-60-80', name: '10% - 15%' },
  { className: 'census-median_inc-80-100', name: 'More than 15%' }
];
const MEDIA_RENT = [
  { className: 'census-median_ren-0-20', name: '0 - $500' },
  { className: 'census-median_ren-20-40', name: '$500 - $1,000' },
  { className: 'census-median_ren-40-60', name: '$1,000 - $1,500' },
  { className: 'census-median_ren-60-80', name: '$1,500 - $2,000' },
  { className: 'census-median_ren-80-100', name: 'More than $2,000' }
];
const TECH_DIAL_SAT = [
  { className: 'census-tech-dial-0-20', name: '0% - 5%' },
  { className: 'census-tech-dial-20-40', name: '5% - 10%' },
  { className: 'census-tech-dial-40-60', name: '10% - 15%' },
  { className: 'census-tech-dial-60-80', name: '15% - 20%' },
  { className: 'census-tech-dial-80-100', name: 'More than 20%' }
];
const LANG_ESP_IND_AS_OTH = [
  { className: 'census-tech-dial-0-20', name: '0% - 1%' },
  { className: 'census-tech-dial-20-40', name: '1% - 3%' },
  { className: 'census-tech-dial-40-60', name: '3% - 5%' },
  { className: 'census-tech-dial-60-80', name: '5% - 10%' },
  { className: 'census-tech-dial-80-100', name: 'More than 10%' }
];
const LANG_EN = [
  { className: 'census-tech-dial-0-20', name: '0 - 20%' },
  { className: 'census-tech-dial-20-40', name: '20% - 40%' },
  { className: 'census-tech-dial-40-60', name: '40% - 60%' },
  { className: 'census-tech-dial-60-80', name: '60% - 80%' },
  { className: 'census-tech-dial-80-100', name: '80% - 100%' }
];

const MEDRIA_RENT_UNEMPLOYED = [
  { className: 'census-median_inc-0-20', name: '0 - 1%' },
  { className: 'census-median_inc-20-40', name: '1% - 2%' },
  { className: 'census-median_inc-40-60', name: '2% - 3%' },
  { className: 'census-median_inc-60-80', name: '3% - 4%' },
  { className: 'census-median_inc-80-100', name: '4% - 5%' }
];

const MEDRIA_RENT_EMPLOYED = [
  { className: 'census-median_inc-0-20', name: '0 - 20%' },
  { className: 'census-median_inc-20-40', name: '20% - 30%' },
  { className: 'census-median_inc-40-60', name: '30% - 40%' },
  { className: 'census-median_inc-60-80', name: '40% - 50%' },
  { className: 'census-median_inc-80-100', name: '50% - 60%' }
];

export const LEGENDS = {

  [TOOLTIP_INFO.FEMALE.title]: POPULATION_LEGEND,
  [TOOLTIP_INFO.MALE.title]: POPULATION_MALE_LEGEND,
  [TOOLTIP_INFO.MEDIAN_AGE.title]: MEDIAN_AGE,
  [TOOLTIP_INFO.FEMALE_UNDER_5.title]: POPULATION_LEGEND_UNDER,
  [TOOLTIP_INFO.MALE_UNDER_5.title]: POPULATION_LEGEND_UNDER,
  [TOOLTIP_INFO.POPULATION_ABOVE_16.title]: POPULATION_LEGEND,
  [TOOLTIP_INFO.WHITE.title]: POPULATION_LEGEND,
  [TOOLTIP_INFO.BLACK.title]: POPULATION_LEGEND,
  [TOOLTIP_INFO.HISPANIC.title]: POPULATION_LEGEND,
  [TOOLTIP_INFO.ASIAN.title]: POPULATION_LEGEND,
  [TOOLTIP_INFO.AMERINDIAN.title]: POPULATION_LEGEND,
  [TOOLTIP_INFO.OTHER.title]: POPULATION_LEGEND,
  [TOOLTIP_INFO.POPULATION_EDUCATION_HIGH_SCHOOL.title]: POPULATION_POP_EDU,
  [TOOLTIP_INFO.POPULATION_EDUCATION_ASSOCIATES.title]: POPULATION_POP_EDU,
  [TOOLTIP_INFO.POPULATION_EDUCATION_BACHELORS.title]: POPULATION_POP_EDU,
  [TOOLTIP_INFO.POPULATION_EDUCATION_MASTERS.title]: POPULATION_POP_EDU,
  [TOOLTIP_INFO.POPULATION_EDUCATION_DOCTORATE.title]: POPULATION_POP_EDU,
  [TOOLTIP_INFO.MEDIAN_INCOME.title]: MEDIAN_INCOME,
  [TOOLTIP_INFO.INCOME_PER_CAPITA.title]: MEDIAN_INCOME,
  [TOOLTIP_INFO.INCOME_LESS_10000.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_10000_14999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_15000_19999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_20000_24999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_25000_29999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_30000_34999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_35000_39999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_40000_44999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_45000_49999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_50000_59999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_60000_74999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_75000_99999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_100000_124999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_125000_149999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_150000_199999.title]: ECONOMY_SUBSLIDER,
  [TOOLTIP_INFO.INCOME_200000_or_more.title]: ECONOMY_SUBSLIDER_MORE,
  [TOOLTIP_INFO.MEDIAN_HOME_VALUE.title]: HOME_MEDIAN,
  [TOOLTIP_INFO.HOUSING_UNITS_OWNED.title]: HOUSING_UNITS_OWN,
  [TOOLTIP_INFO.HOUSING_UNITS_RENTED.title]: HOUSING_UNITS_RENT,
  [TOOLTIP_INFO.MEDIAN_RENT.title]: MEDIA_RENT,
  [TOOLTIP_INFO.RENT_OVER_50_PERCENT_INCOME.title]: MEDRIA_RENT_SUBSLIDER,
  [TOOLTIP_INFO.RENT_IS_40_50_PERCENT_INCOME.title]: MEDRIA_RENT_SUBSLIDER,
  [TOOLTIP_INFO.RENT_IS_35_40_PERCENT_INCOME.title]: MEDRIA_RENT_SUBSLIDER,
  [TOOLTIP_INFO.RENT_IS_30_35_PERCENT_INCOME.title]: MEDRIA_RENT_SUBSLIDER,
  [TOOLTIP_INFO.RENT_IS_25_30_PERCENT_INCOME.title]: MEDRIA_RENT_SUBSLIDER,
  [TOOLTIP_INFO.RENT_IS_20_25_PERCENT_INCOME.title]: MEDRIA_RENT_SUBSLIDER,
  [TOOLTIP_INFO.RENT_IS_15_20_PERCENT_INCOME.title]: MEDRIA_RENT_SUBSLIDER,
  [TOOLTIP_INFO.RENT_IS_10_15_PERCENT_INCOME.title]: MEDRIA_RENT_SUBSLIDER,
  [TOOLTIP_INFO.RENT_UNDER_10_PERCENT_INCOME.title]: MEDRIA_RENT_SUBSLIDER,
  [TOOLTIP_INFO.EMPLOYED.title]: MEDRIA_RENT_EMPLOYED,
  [TOOLTIP_INFO.UNEMPLOYED.title]: MEDRIA_RENT_UNEMPLOYED,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_LIMITED_ENGLISH.title]: LANG_EN,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_SPEAK_SPANISH.title]: LANG_ESP_IND_AS_OTH,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_INDO_EUROPEAN_LANGUAGE.title]: LANG_ESP_IND_AS_OTH,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ASIAN_PACIFIC_ISLAND_LANGUAGE.title]: LANG_ESP_IND_AS_OTH,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ANOTHER_LANGUAGE.title]: LANG_ESP_IND_AS_OTH,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_NO_INTERNET_ACCESS.title]: INTERNET_LEGEND,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_INTERNET_ACCESS.title]: INTERNET_LEGEND,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_BROADBAND_INTERNET.title]: INTERNET_LEGEND,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_SATELLITE_INTERNET.title]: TECH_DIAL_SAT,
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_DIAL_UP_INTERNET.title]: TECH_DIAL_SAT,
  [TOOLTIP_INFO.DEFAULT.title]: [
    { className: 'census-default', name: 'Boundaries' }
  ]
};

export const BASIC_FILTER_STATES = [
  'match',
  ['get', MAP_CONSTANTS.FIELDS_STATE.ABBREV],
  ['none'],
  true,
  false
];

export const BASIC_FILTER_CENSUS = [
  'match',
  ['get', MAP_CONSTANTS.FIELDS_CENSUS.CARTODBID],
  ['none'],
  true,
  false
];

export const BASIC_FILTER_FACILITIES = [
  'match',
  ['get', MAP_CONSTANTS.FIELDS_CENSUS.ENTITYID],
  ['none'],
  true,
  false
];

export const BASIC_ENTITIES_FILTER = [
  'in',
  ['get', MAP_CONSTANTS.FIELDS_CSH.ENTITYID],
  ['literal', []]
];

export const BASIC_FILTER_DIVISIONS = [
  'match',
  ['get', MAP_CONSTANTS.FIELDS_DIVISION.DIVISION],
  ['none'],
  true,
  false
];

export const DRIVING_DISTANCE = [
  { label: '10 min', value: 'cp_distance10' },
  { label: '20 min', value: 'cp_distance20' },
  { label: '30 min', value: 'cp_distance30' },
  { label: '60 min', value: 'cp_distance60' }
];

export const RADIUS_WHITH = [
  { label: '0.5 miles', value: 'cp_buffer05' },
  { label: '1 mile', value: 'cp_buffer1' },
  { label: '3 miles', value: 'cp_buffer3' },
  { label: '10 miles', value: 'cp_buffer10' },
  { label: '20 miles', value: 'cp_buffer20' },
  { label: '30 miles', value: 'cp_buffer30' }
];

export const DISTANCE_TABLES = {
  [DRIVING_DISTANCE[0].value]: 'boundary_distance10',
  [DRIVING_DISTANCE[1].value]: 'boundary_distance20',
  [DRIVING_DISTANCE[2].value]: 'boundary_distance30',
  [DRIVING_DISTANCE[3].value]: 'boundary_distance60'
};

export const RADIUS_TABLES = {
  [RADIUS_WHITH[0].value]: 'boundary_buffer05',
  [RADIUS_WHITH[1].value]: 'boundary_buffer1',
  [RADIUS_WHITH[2].value]: 'boundary_buffer3',
  [RADIUS_WHITH[3].value]: 'boundary_buffer10',
  [RADIUS_WHITH[4].value]: 'boundary_buffer20',
  [RADIUS_WHITH[5].value]: 'boundary_buffer30'
};
