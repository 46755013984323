import axios from 'axios';
import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { GenericAny } from '../@types';
import { ENDPOINTS } from '../constants';
import { SidebarFirstTabState } from '../context/SidebarFirstTabContext';
import { PrimaryAttributesState } from '../context/PrimaryAtributesContext';
import { HospitalCharacteristicsState } from '../context/HospitalCharacteristicsContext';
import { PatientServicesState } from '../context/PatientServicesContext';
import { FinantialHealthState } from '../context/FinantialHealthContext';
import { StaffRetentionState } from '../context/StaffRetentionContext';
import { EconomyState } from '../context/EconomyContext';
import { PopulationState } from '../context/PopulationContext';
import { HousingState } from '../context/HousingContext';
import { LanguageState } from '../context/LanguageContext';
import { TechnologyState } from '../context/TechnologyContext';
import { DrivingState } from '../context/DrivingContext';

const fetchPage = async (page: number, limit: number, search: string, body: GenericAny) => {
  const { data } = await axios.post(ENDPOINTS.GET_FACILITIES(page + 1, limit, search), body);
  return data;
};

export const useFacilitiesRequest = () => {
  const [body, setBody] = useState({
    customFields: ['entityid']
  });
  const { data } = useQuery(
    ['facilities', 0, 10000, '', body],
    () => fetchPage(0, 10000, '', body)
  );
  const { area, subdropdown } = useContext(SidebarFirstTabState);
  const {
    entities, healthEq, charity, graduate, taxStatus
  } = useContext(PrimaryAttributesState);
  const { sliders: hospitalSliders } = useContext(HospitalCharacteristicsState);
  const { sliders: patientSliders } = useContext(PatientServicesState);
  const { sliders: finantialSliders } = useContext(FinantialHealthState);
  const { sliders: staffRetentionSliders } = useContext(StaffRetentionState);
  const { sliders: economySliders } = useContext(EconomyState);
  const { sliders: populationSliders } = useContext(PopulationState);
  const { sliders: housingSliders } = useContext(HousingState);
  const { sliders: languageSliders } = useContext(LanguageState);
  const { sliders: technologySliders } = useContext(TechnologyState);
  const { drivingTime } = useContext(DrivingState);

  useEffect(() => {
    if (area && subdropdown) {
      setBody((oldBody) => ({
        ...oldBody,
        area,
        subdropdown: subdropdown.join(',')
      }));
    }
  }, [area, subdropdown]);

  useEffect(() => {
    const areTrue = entities.filter((c) => c.checked).map((c) => c.value.toUpperCase());
    if (areTrue.length > 0) {
      setBody((oldBody) => ({
        ...oldBody,
        entities: areTrue
      }));
    }
    if (graduate !== null) {
      setBody((oldBody) => ({
        ...oldBody,
        graduate
      }));
    }
    if (healthEq[0] !== 0 || healthEq[1] !== 100) {
      setBody((oldBody) => ({
        ...oldBody,
        healthEq
      }));
    }
    if (charity[0] !== 0 || charity[1] !== 100) {
      setBody((oldBody) => ({
        ...oldBody,
        charity
      }));
    }
    if (taxStatus !== null) {
      setBody((oldBody) => ({
        ...oldBody,
        taxStatus
      }));
    }
    const newArray = [
      ...economySliders,
      ...populationSliders,
      ...housingSliders,
      ...languageSliders,
      ...technologySliders
    ];
    const parsedData: GenericAny[] = [];
    newArray.forEach((slider) => {
      if (slider.subSliders && slider.subSliders.length > 0) {
        slider.subSliders.forEach((subSlider) => {
          parsedData.push({
            value: [subSlider.value[0] * (subSlider.multiplier || 1), subSlider.value[1] * (subSlider.multiplier || 1)],
            field: subSlider.field
          });
        });
      } else {
        parsedData.push({
          value: [slider.value[0] * (slider.multiplier || 1), slider.value[1] * (slider.multiplier || 1)],
          field: slider.field
        });
      }
    });
    setBody((oldBody) => ({
      ...oldBody,
      data: parsedData,
      table: drivingTime
    }));
  }, [
    entities,
    healthEq,
    charity,
    graduate,
    taxStatus,
    economySliders,
    populationSliders,
    housingSliders,
    languageSliders,
    technologySliders,
    drivingTime
  ]);

  useEffect(() => {
    const ranges: { value: number[]; column: string; }[] = [];
    hospitalSliders.forEach((hospital) => {
      ranges.push({
        value: hospital.value,
        column: hospital.field
      });
    });
    patientSliders.forEach((patient) => {
      ranges.push({
        value: patient.value,
        column: patient.field
      });
    });
    staffRetentionSliders.forEach((staff) => {
      ranges.push({
        value: staff.value,
        column: staff.field
      });
    });
    finantialSliders.forEach((finantial) => {
      ranges.push({
        value: finantial.value,
        column: finantial.field
      });
    });
    setBody((oldBody) => ({
      ...oldBody,
      ranges
    }));
  }, [hospitalSliders, patientSliders, finantialSliders, staffRetentionSliders]);
  return {
    data
  };
};
