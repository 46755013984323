import classNames from 'classnames';
import { useState, useContext } from 'react';
import { SidebarFirstTab } from './SidebarFirstTab';
import { SidebarSecondTab } from './SidebarSecondTab';
import { SidebarThirdTab } from './SidebarThirdTab';
import { BboxDispatch, BboxState } from '../context/BboxContext';

export const Sidebar = (
  { setTab, setActive }: { setTab: (tab: number) => void, setActive: (activeTab: boolean) => void }
) => {
  const { tabSelected } = useContext(BboxState);
  const { setTabSelected, setHasChangeNavbar, setIsZooming } = useContext(BboxDispatch);
  const [needsReset, setNeedsReset] = useState(false);
  const [needsResetSecond, setNeedsResetSecond] = useState(false);
  const [needsResetThrid, setNeedsResetThird] = useState(false);

  const handleReset = () => {
    if (tabSelected === 0) setNeedsReset(true);
    if (tabSelected === 1) setNeedsResetSecond(true);
    if (tabSelected === 2) setNeedsResetThird(true);
  };
  const handleOnclick = () => {
    if (tabSelected === 2) {
      setTab(1);
      setHasChangeNavbar(true);
      setIsZooming(false);
      setActive(false);
    } else {
      setTabSelected(tabSelected + 1);
    }
  };
  return (
    <div className="colform">
      <div className="groupdata">
        <div className="steps">
          <div className="trace">
            <div
              className={classNames('block', { active: tabSelected === 0 })}
              onClick={() => {
                setTabSelected(0);
                setIsZooming(false);
                setHasChangeNavbar(false);
              }}
              onKeyDown={() => {
                setTabSelected(0);
                setIsZooming(false);
                setHasChangeNavbar(false);
              }}
              role="button"
              tabIndex={0}
            >
              <div className="number"><span className="txt">1</span></div>
              <div className="description">
                <p className="txts">Select Area for Analysis</p>
              </div>
            </div>
            <div
              className={classNames('block', { active: tabSelected === 1 })}
              onClick={() => {
                setTabSelected(1);
                setHasChangeNavbar(false);
              }}
              onKeyDown={() => {
                setTabSelected(1);
                setHasChangeNavbar(false);
              }}
              role="button"
              tabIndex={0}
            >
              <div className="number"><span className="txt">2</span></div>
              <div className="description">
                <p className="txts">Filter By CSH Facilities</p>
              </div>
            </div>
            <div
              className={classNames('block', { active: tabSelected === 2 })}
              onClick={() => {
                setTabSelected(2);
                setHasChangeNavbar(false);
              }}
              onKeyDown={() => {
                setTabSelected(2);
                setHasChangeNavbar(false);
              }}
              role="button"
              tabIndex={0}
            >
              <div className="number"><span className="txt">3</span></div>
              <div className="description">
                <p className="txts">Filter by Census Block Group</p>
              </div>
            </div>
          </div>
          <div className="space">
            <div className="line" />
          </div>
        </div>
        {tabSelected === 0 && (<SidebarFirstTab needsReset={needsReset} setNeedsReset={setNeedsReset} />)}
        {tabSelected === 1 && (<SidebarSecondTab needsReset={needsResetSecond} setNeedsReset={setNeedsResetSecond} />)}
        {tabSelected === 2 && (<SidebarThirdTab needsReset={needsResetThrid} setNeedsReset={setNeedsResetThird} />)}
      </div>
      <div className="action">
        <div className="reset-minimal">
          <span
            tabIndex={0}
            role="button"
            onClick={handleReset}
            onKeyDown={handleReset}
            className="txt"
          >
            Reset
          </span>
        </div>
        <button
          type="button"
          className="light"
          onClick={handleOnclick}
        >
          <span className="icarrownext" />
          <span className="txt">
            {tabSelected === 2 ? 'My Analysis' : 'Next'}
          </span>
        </button>
      </div>
    </div>
  );
};
