import React, { useCallback, useMemo, useState } from 'react';
import { HospitalSlider } from '../@types';
import { MAP_CONSTANTS, TOOLTIP_INFO } from '../constants';
import {
  HospitalCharacteristicsDispatch, HospitalCharacteristicsState
} from '../context/HospitalCharacteristicsContext';

const options = [
  {
    label: 'Licensed Beds - Acute',
    label2: '',
    max: 1500,
    field: MAP_CONSTANTS.FIELDS_CSH.LICENSED_BEDS_ACUTE,
    description: TOOLTIP_INFO.LICENSED_BEDS_ACUTE.description,
  },
  {
    label: 'Licensed Beds - LTC',
    max: 250,
    field: MAP_CONSTANTS.FIELDS_CSH.LICENSED_BEDS_LTC,
    description: TOOLTIP_INFO.LICENSED_BEDS_LTC.description,
  },
  {
    label: 'Operating Beds - Acute',
    max: 1000,
    field: MAP_CONSTANTS.FIELDS_CSH.OPERATING_BEDS_ACUTE,
    description: TOOLTIP_INFO.OPERATING_BEDS_ACUTE.description,
  },
  {
    label: 'Operating Beds - LTC',
    max: 100,
    field: MAP_CONSTANTS.FIELDS_CSH.OPERATING_BEDS_LTC,
    description: TOOLTIP_INFO.OPERATING_BEDS_LTC.description,
  }
];

export const HospitalCharacteristicsProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [sliders, setSliders] = useState<HospitalSlider[]>(
    options.map((option) => ({
      max: option.max,
      value: [0, option.max],
      field: option.field,
      label: option.label,
      label2: option.label2 ?? '',
      subtitle: 'Select or input score range.',
      description: option.description,
    }))
  );
  const resetSliders = useCallback(() => {
    setSliders(options.map((option) => ({
      max: option.max,
      value: [0, option.max],
      field: option.field,
      label: option.label,
      label2: option.label2 ?? '',
      subtitle: 'Select or input score range.',
      description: ''
    })));
  }, [setSliders]);
  const state = useMemo(() => ({
    sliders
  }), [sliders]);
  const setters = useMemo(() => ({
    setSliders, resetSliders
  }), [setSliders, resetSliders]);
  return (
    <HospitalCharacteristicsState.Provider value={state}>
      <HospitalCharacteristicsDispatch.Provider value={setters}>
        {children}
      </HospitalCharacteristicsDispatch.Provider>
    </HospitalCharacteristicsState.Provider>
  );
};
