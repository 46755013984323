import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: '"MarcellusRegular", serif'
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          fontFamily: '"MarcellusRegular", serif',
          fontSize: 14,
          padding: '0',
          marginBottom: '10px',
          marginTop: '10px',
          '&:.Mui-expanded': {
            margin: '0',
          },
        },
        root: {
          width: '100%',
          flexDirection: 'row-reverse',
          paddingRight: 0,
          margin: 0,
          '&:.Mui-expanded': {
            margin: '0',
          },
        },
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '50px',
          borderRadius: '16px',
          width: '100%',
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#7B7A80',
          '&.Mui-checked': {
            color: '#5d3dc7',
            left: '-6px',
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#fff',
            border: '2px solid #5d3dc7',
            opacity: '1',
          },
        },
        thumb: {
          width: '10px',
          height: '10px',
          marginTop: '8px',
          marginLeft: '9px',
          '&.Mui-checked + .MuiSwitch-track': {
            marginRight: '100px',
          },
        },
        track: {
          backgroundColor: '#fff',
          border: '2px solid #7B7A80',
          width: '100%',
          height: '20px',
          borderRadius: '20px',
          opacity: '1',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:focus-visible': {
            backgroundColor: 'transparent'
          },
          '&:selected': {
            backgroundColor: 'brown',
          },
          '&:hover': {
            backgroundColor: 'rgb(93, 61, 199, 0.1)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgb(93, 61, 199, 0.2)',
            '&.Mui-focusVisible': { background: 'rgb(93, 61, 199, 0.2)' }
          }
        },
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#B4B6B8',
          '&.Mui-checked': {
            color: '#5D3DC7'
          }
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          textAlign: 'center'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: '239px',
          backgroundColor: 'black',
          color: '#fff',
          borderRadius: '12px',
          padding: '12px',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          fontSize: '12px !important',
          height: '40px !important',
        },
        body: {
          fontSize: '12px !important'
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'var(--primary-purple-100)',
            '&:hover': {
              backgroundColor: 'var(--primary-purple-100)'
            }
          },
          '&:hover': {
            backgroundColor: 'var(--primary-purple-100)'
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingRight: '23px'
          }
        }
      }
    }
  }
});
