import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AUTH_TOKEN, LOGIN_ROUTE } from '../constants';
import { BboxDispatch } from '../context/BboxContext';

export const Navbar = (
  {
    active,
    setTab,
    setActive
  }
  :{
    active: boolean,
    setTab: (tab: number) => void,
    setActive: (activeTab: boolean) => void
  }
) => {
  const navigate = useNavigate();
  const { setHasChangeNavbar, setIsZooming } = useContext(BboxDispatch);
  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    navigate(LOGIN_ROUTE);
  };
  return (
    <div className="header">
      <div className="nbleft">
        <div className="iclogo" />
      </div>
      <div className="nbright">
        <nav className="menu">
          <ol>
            <li>
              <span
                role="button"
                tabIndex={0}
                onClick={() => {
                  setTab(0);
                  setActive(true);
                  setHasChangeNavbar(true);
                  setIsZooming(false);
                }}
                onKeyDown={() => setTab(0)}
                className={active ? 'txtl title-active' : 'txtl title-txtl'}
              >
                Explore
              </span>
              <div className={active ? 'hr-txtl' : ''} />
            </li>
            <li>
              <span
                className={!active ? 'txtl title-active' : 'txtl title-txtl'}
                role="button"
                tabIndex={0}
                onClick={() => {
                  setTab(1);
                  setActive(false);
                }}
                onKeyDown={() => setTab(1)}
              >
                My Analysis
              </span>
              <div className={!active ? 'hr-txtl' : ''} />
            </li>
          </ol>
        </nav>
        <div className="userarea">
          <button type="button" className="light btn1">
            <span className="icuser" />
          </button>
          <div className="logout">
            <button
              type="button"
              className="light"
              onClick={logout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
