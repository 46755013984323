import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch
} from '@mui/material';
import classNames from 'classnames';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FilterAreaOptions } from '../@types';
import { MAP_CONSTANTS } from '../constants';

export const FilterArea = ({
  title,
  options,
  showExtra,
  panel,
  visible,
  legendTitle,
  setPanelExpanded,
  setVisible
}: FilterAreaOptions) => (
  <Accordion
    disableGutters
    expanded={panel === title}
    onChange={(event, isExpanded: boolean) => {
      const target: HTMLElement = event.target as HTMLElement;
      const targetString = target.innerHTML;
      if (!targetString.includes(title)) {
        return;
      }
      setPanelExpanded(isExpanded ? title : '');
    }}
  >
    <AccordionSummary
      sx={{ minHeight: 32 }}
      expandIcon={<ExpandMore />}
    >
      <div className="accordion-header">
        <div style={{ marginLeft: '5px' }}>
          {title}
        </div>
        <Switch
          checked={visible}
          onChange={(event) => {
            event.stopPropagation();
            setVisible(!visible);
            if (!visible) {
              setPanelExpanded(title);
            } else if (panel === title) {
              setPanelExpanded('');
            }
          }}
        />
      </div>
    </AccordionSummary>
    <AccordionDetails>
      <div className="option2">
        {showExtra && <p className="txt Title little-space">{legendTitle ?? ''}</p>}
        <div className={
          classNames('category', { 'category-census': title === MAP_CONSTANTS.LEGEND_TITLES.CENSUS_BLOCK_GROUPS })
        }
        >
          {
            options?.map((option) => (
              <div className="item" key={option.className}>
                <div className="symbol">
                  <div className={option.className} />
                </div>
                <div className="txt">{option.name}</div>
              </div>
            ))
          }
        </div>
      </div>
    </AccordionDetails>
  </Accordion>
);
