import { DrivingnProvider } from './DrivingProvider';
import { EconomyProvider } from './EconomyProvider';
import { HousingProvider } from './HousingProvider';
import { LanguageProvider } from './LanguageProvider';
import { PopulationProvider } from './PopulationProvider';
import { TechnologyProvider } from './TechnologyProvider';

export const SidebarThirdTabProvider = ({ children }: React.PropsWithChildren<unknown>) => (
  <EconomyProvider>
    <PopulationProvider>
      <HousingProvider>
        <LanguageProvider>
          <TechnologyProvider>
            <DrivingnProvider>
              {children}
            </DrivingnProvider>
          </TechnologyProvider>
        </LanguageProvider>
      </HousingProvider>
    </PopulationProvider>
  </EconomyProvider>
);
