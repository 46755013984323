import { createContext } from 'react';
import { graduateType } from '../@types';

export const PrimaryAttributesState = createContext({
  entities: [],
  healthEq: [0, 100],
  charity: [0, 100],
  graduate: null,
  taxStatus: null,
} as {
  entities: { checked: boolean, value: string }[],
  healthEq: number[],
  charity: number[],
  graduate: graduateType | null,
  taxStatus: graduateType | null,
});

export const PrimaryAttributesSetters = createContext({
  setEntities: () => {
    console.info('PrimaryAttributesSetters is not set');
  },
  setHealthEq: () => {
    console.info('PrimaryAttributesSetters is not set');
  },
  setCharity: () => {
    console.info('PrimaryAttributesSetters is not set');
  },
  setGraduate: () => {
    console.info('PrimaryAttributesSetters is not set');
  },
  setTaxStatus: () => {
    console.info('PrimaryAttributesSetters is not set');
  }
} as {
  setEntities: (entities: { checked: boolean, value: string}[]) => void,
  setHealthEq: (healthEq: number[]) => void,
  setCharity: (charity: number[]) => void,
  setGraduate: (graduate: graduateType | null) => void,
  setTaxStatus: (taxStatus: graduateType | null) => void
});
