import { HeadCell } from '../@types';
import {
  moneyFormat,
  sliderPercentageOneDecimal, toTax, toUpperCase, twoDecimal, yearFormat
} from '../utils';

export const CSV_FILENAME = 'CSH-Facilities-Export.csv';

export const headers: HeadCell[] = [
  {
    id: 'entityid',
    label: 'ID',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'entity_type',
    label: 'Entity Type',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'operating_dba_name',
    label: 'Name',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'physical_address',
    label: 'Address',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'city',
    label: 'City',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'state',
    label: 'State',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'zip',
    label: 'Zip',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'division',
    label: 'Division',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'gme',
    label: 'Graduate Medical Education',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'physician_enterprise_division',
    label: 'Physician Enterprise Division',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'licensed_beds_acute',
    label: 'Licensed Beds - Acute',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'licensed_beds_ltc',
    label: 'Licensed Beds - LTC',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'operating_beds_acute',
    label: 'Operating Beds - Acute',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'operating_beds_ltc',
    label: 'Operating Beds - LTC',
    numeric: false,
    disablePadding: true,
    format: toUpperCase,
  },
  {
    id: 'tax_status',
    label: 'Tax Status',
    numeric: false,
    disablePadding: true,
    format: toTax
  },
  {
    id: 'patient_care_quality_acute_sepsis_mortality',
    label: 'Patient Care Quality',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'patient_experience_acute_care_experience',
    label: 'Patient Experience - Acute',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'patient_experience_medical_practice_experience',
    label: 'Patient Experience - Medical',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'patient_safety',
    label: 'Patient Safety',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'employee_engagement',
    label: 'Employee Engagement',
    numeric: false,
    disablePadding: true,
    format: twoDecimal
  },
  {
    id: 'new_hire_adjusted_retention_rate',
    label: 'Retention Rate',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'physician_app_experience',
    label: 'Physician App Experience',
    numeric: false,
    disablePadding: true,
    format: twoDecimal
  },
  {
    id: 'health_equity',
    label: 'Health Equity',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'value_based_agreements_growth_performance',
    label: 'Growth Performance',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal,
  },
  {
    id: 'charity_medicaid_service',
    label: 'Charity Medicaid Service',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'ebitda_after_special_charges',
    label: 'EBITDA',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'days_cash',
    label: 'Days Cash',
    numeric: false,
    disablePadding: true,
    format: toUpperCase
  },
  {
    id: 'npsr_growth',
    label: 'NPSR Growth',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'revenue_from_diversified_non_acute_sources',
    label: 'Revenue',
    numeric: false,
    disablePadding: true,
    format: toUpperCase
  },
  {
    id: 'philanthropy',
    label: 'Philanthropy',
    numeric: false,
    disablePadding: true,
    format: toUpperCase
  },
  {
    id: 'female_pop',
    label: 'Female',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'male_pop',
    label: 'Male',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'median_age',
    label: 'Median Age',
    numeric: false,
    disablePadding: true,
    format: yearFormat
  },
  {
    id: 'female_under_5',
    label: 'Under 5 Females',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'male_under_5',
    label: 'Under 5 Males',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'pop_16_over',
    label: 'Over 16 Yrs',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'white_pop',
    label: 'White',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'black_pop',
    label: 'Black',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'hispanic_pop',
    label: 'Hispanic',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'asian_pop',
    label: 'Asian',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'amerindian_pop',
    label: 'Am. Indian',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'other_race_pop',
    label: 'Other Race',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'high_school_diploma',
    label: 'HS Diploma',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'associates_degree',
    label: 'Assoc. Degree',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'bachelors_degree',
    label: 'Bachelors Degree',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'masters_degree',
    label: 'Masters Degree',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'doctorate_degree',
    label: 'Doctorate Degree',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'median_income',
    label: 'Median Income',
    numeric: false,
    disablePadding: true,
    format: moneyFormat
  },
  {
    id: 'income_per_capita',
    label: 'Income Per Capita',
    numeric: false,
    disablePadding: true,
    format: moneyFormat
  },
  {
    id: 'income_less_10000',
    label: 'Inc < $10000',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_10000_14999',
    label: '$10K to $15K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_15000_19999',
    label: '$15K to $20K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_20000_24999',
    label: '$20K to $25K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_25000_29999',
    label: '$25K to $30K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_30000_34999',
    label: '$30K to $35K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_35000_39999',
    label: '$35K to $40K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_40000_44999',
    label: '$40K to $45K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_45000_49999',
    label: '$45K to $50K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_50000_59999',
    label: '$50K to $55K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_60000_74999',
    label: '$60K to $75K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_75000_99999',
    label: '$75K to $100K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_100000_124999',
    label: '$100K to $125K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_125000_149999',
    label: '$125K to $150K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_150000_199999',
    label: '$150K to $200K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'income_200000_or_more',
    label: 'Over $200K',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'owner_occupied_housing_units_median_value',
    label: 'Med. Home Value',
    numeric: false,
    disablePadding: true,
    format: moneyFormat
  },
  {
    id: 'owner_occupied_housing_units',
    label: '% Owned',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'housing_units_renter_occupied',
    label: '% Rented',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'median_rent',
    label: 'Median Rent',
    numeric: false,
    disablePadding: true,
    format: moneyFormat
  },
  {
    id: 'percent_income_spent_on_rent',
    label: 'Avg. % Inc. Rent',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'rent_over_50_percent',
    label: 'Rent > 50%',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'rent_40_to_50_percent',
    label: 'Rent 40-50%',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'rent_35_to_40_percent',
    label: 'Rent 35-40%',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'rent_30_to_35_percent',
    label: 'Rent 30-35%',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'rent_25_to_30_percent',
    label: 'Rent 25-30%',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'rent_20_to_25_percent',
    label: 'Rent 20-25%',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'rent_15_to_20_percent',
    label: 'Rent 15-20%',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'rent_10_to_15_percent',
    label: 'Rent 10-15%',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'rent_under_10_percent',
    label: 'Rent < 10%',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'employed_pop',
    label: '% Employed',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'unemployed_pop',
    label: '% Unemployed',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'household_language_english',
    label: 'Limited English',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'household_language_spanish_limited_english',
    label: 'Only Spanish',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'household_language_other_indo_european_limited_english',
    label: 'Only Indo. Euro',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'household_language_asian_and_pacific_island_limited_english',
    label: 'Only AAPI',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'household_language_other_limited_english',
    label: 'Only Another',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'internet_subscription_no_access',
    label: 'No Internet',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'internet_subscription_total',
    label: 'With Internet',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'internet_subscription_broadband',
    label: 'Only Broadband',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'internet_subscription_satellite',
    label: 'Only Satellite',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  },
  {
    id: 'internet_subscription_dial_up',
    label: 'Only Dial-up',
    numeric: false,
    disablePadding: true,
    format: sliderPercentageOneDecimal
  }
];
