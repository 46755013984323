import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import {
  useRef,
  useState,
  useContext,
  useEffect
} from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { Economy } from './Economy';
import { Population } from './Population';
import { Housing } from './Housing';
import { Language } from './Language';
import {
  DRIVING_DISTANCE, ENDPOINTS, MAP_CONSTANTS, RADIUS_WHITH, ResetProps, TABS_THIRD
} from '../constants';
import { useScrollSelect } from '../hooks/useScrollSelect';
import { useArrayRef } from '../hooks/useArrayref';
import { Technology } from './Technology';
import { AboutUs } from './AboutUs';
import { FilterContextDispatch } from '../context/FilterContext';
import { BboxDispatch } from '../context/BboxContext';
import { TYPES } from '../constants/context';
import { PopulationDispatch, PopulationState } from '../context/PopulationContext';
import { EconomyDispatch, EconomyState } from '../context/EconomyContext';
import { GenericAny } from '../@types';
import { HousingDispatch, HousingState } from '../context/HousingContext';
import { LanguageDispatch, LanguageState } from '../context/LanguageContext';
import { TechnologyDispatch, TechnologyState } from '../context/TechnologyContext';
import { DrivingDispatch, DrivingState } from '../context/DrivingContext';

const fetchPage = async (body: GenericAny, table: string) => {
  const { data } = await axios.post(ENDPOINTS.GET_FILTERED_BY_CENSUS(), {
    data: body,
    table
  });
  return data;
};

export const SidebarThirdTab = ({ needsReset, setNeedsReset }: ResetProps) => {
  const { drivingTime, selectArea } = useContext(DrivingState);
  const { setDrivingTime, setDrivingLabel, setSelectArea } = useContext(DrivingDispatch);
  const [selectedOpt, setSelectedOpt] = useState(0);
  const [filters, setFilters] = useState<GenericAny[]>([]);
  const [refs, addRef] = useArrayRef();
  const [removeFilters, setRemoveFilters] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { sliders: economySliders } = useContext(EconomyState);
  const { sliders: populationSliders } = useContext(PopulationState);
  const { sliders: housingSliders } = useContext(HousingState);
  const { sliders: languageSliders } = useContext(LanguageState);
  const { sliders: technologySliders } = useContext(TechnologyState);
  const {
    dispatch
  } = useContext(FilterContextDispatch);

  const {
    setIsZooming
  } = useContext(BboxDispatch);

  const { data, error } = useQuery(
    ['filtered-by-census', filters, drivingTime],
    () => fetchPage(filters, drivingTime)
  );

  useEffect(() => {
    if (!error && data) {
      // console.info(data);
      const cleanData = data.data.map((item: GenericAny) => item.entityid);
      // console.info(cleanData);
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.ENTITYID,
          value: ['in', ['get', MAP_CONSTANTS.FIELDS_CSH.ENTITYID], ['literal', [...cleanData]]]
        }
      });
    }
  }, [error, data, dispatch]);

  useEffect(() => {
    const newArray = [
      ...economySliders,
      ...populationSliders,
      ...housingSliders,
      ...languageSliders,
      ...technologySliders
    ];
    const parsedData: GenericAny[] = [];
    newArray.forEach((slider) => {
      if (slider.subSliders && slider.subSliders.length > 0) {
        slider.subSliders.forEach((subSlider) => {
          parsedData.push({
            value: [subSlider.value[0] * (subSlider.multiplier || 1), subSlider.value[1] * (subSlider.multiplier || 1)],
            field: subSlider.field
          });
        });
      } else {
        parsedData.push({
          value: [slider.value[0] * (slider.multiplier || 1), slider.value[1] * (slider.multiplier || 1)],
          field: slider.field
        });
      }
    });
    setFilters([...parsedData]);
  }, [economySliders, populationSliders, housingSliders, languageSliders, technologySliders]);

  const { resetSliders: resetSlidersEconomy } = useContext(EconomyDispatch);
  const { resetSliders: resetSlidersPopulation } = useContext(PopulationDispatch);
  const { resetSliders: resetSlidersHousing } = useContext(HousingDispatch);
  const { resetSliders: resetSlidersLanguage } = useContext(LanguageDispatch);
  const { resetSliders: resetSlidersTechnology } = useContext(TechnologyDispatch);

  useScrollSelect(refs, scrollRef, setSelectedOpt);

  useEffect(() => {
    dispatch({ type: TYPES.SET_SHOW_STATES, payload: false });
    dispatch({ type: TYPES.SET_SHOW_DIVISIONS, payload: false });
  }, [dispatch]);

  useEffect(() => {
    setIsZooming(true);
  }, [setIsZooming]);

  useEffect(() => {
    if (needsReset) {
      resetSlidersEconomy();
      resetSlidersPopulation();
      resetSlidersHousing();
      resetSlidersLanguage();
      resetSlidersTechnology();
      setNeedsReset(false);
      setRemoveFilters(true);
      setSelectArea(true);
      setDrivingTime(DRIVING_DISTANCE[0].value);
      setDrivingLabel(DRIVING_DISTANCE[0].label);
    }
    if (removeFilters) {
      setRemoveFilters(false);
      const newArray = [
        ...economySliders,
        ...populationSliders,
        ...housingSliders,
        ...languageSliders,
        ...technologySliders
      ];
      newArray.forEach((slider) => {
        if (slider.subSliders && slider.subSliders.length > 0) {
          slider.subSliders.forEach((subSlider) => {
            dispatch({
              type: TYPES.REMOVE,
              payload: subSlider.field
            });
          });
        } else {
          dispatch({
            type: TYPES.REMOVE,
            payload: slider.field
          });
        }
      });
    }
  }, [
    needsReset,
    setNeedsReset,
    resetSlidersPopulation,
    resetSlidersEconomy,
    resetSlidersHousing,
    resetSlidersLanguage,
    resetSlidersTechnology,
    setSelectArea,
    setDrivingTime,
    setDrivingLabel,
    removeFilters,
    economySliders,
    populationSliders,
    housingSliders,
    languageSliders,
    technologySliders,
    dispatch
  ]);
  return (
    <>
      <div className="attributeoptions">
        <a href="#aboutus">
          <button
            type="button"
            className={classNames('light btn6', { active: selectedOpt === TABS_THIRD.INFORMATION })}
            onClick={() => setSelectedOpt(0)}
          >
            <Tooltip
              title="About Us"
              TransitionProps={{ timeout: 0 }}
            >
              <span className="icg206" />
            </Tooltip>
          </button>
        </a>
        <a href="#population">
          <button
            type="button"
            className={classNames('light btn1', { active: selectedOpt === TABS_THIRD.POPULATION })}
            onClick={() => setSelectedOpt(TABS_THIRD.POPULATION)}
          >
            <Tooltip
              title="Population"
              TransitionProps={{ timeout: 0 }}
            >
              <span className="icg201" />
            </Tooltip>
          </button>
        </a>
        <a href="#economy">
          <button
            type="button"
            className={classNames('light btn2', { active: selectedOpt === TABS_THIRD.ECONOMY })}
            onClick={() => setSelectedOpt(TABS_THIRD.ECONOMY)}
          >
            <Tooltip
              title="Economy"
              TransitionProps={{ timeout: 0 }}
            >
              <span className="icg202" />
            </Tooltip>
          </button>
        </a>
        <a href="#housing">
          <button
            type="button"
            className={classNames('light btn3', { active: selectedOpt === TABS_THIRD.HOUSING })}
            onClick={() => setSelectedOpt(TABS_THIRD.HOUSING)}
          >
            <Tooltip
              title="Housing"
              TransitionProps={{ timeout: 0 }}
            >
              <span className="icg203" />
            </Tooltip>
          </button>
        </a>
        <a href="#language">
          <button
            type="button"
            className={classNames('light btn4', { active: selectedOpt === TABS_THIRD.LANGUAGE })}
            onClick={() => setSelectedOpt(TABS_THIRD.LANGUAGE)}
          >
            <Tooltip
              title="Language"
              TransitionProps={{ timeout: 0 }}
            >
              <span className="icg204" />
            </Tooltip>
          </button>
        </a>
        <a href="#technology">
          <button
            type="button"
            className={classNames('light btn5', { active: selectedOpt === TABS_THIRD.TECHNOLOGY })}
            onClick={() => setSelectedOpt(TABS_THIRD.TECHNOLOGY)}
          >
            <Tooltip
              title="Technology"
              TransitionProps={{ timeout: 0 }}
            >
              <span className="icg205" />
            </Tooltip>
          </button>
        </a>
      </div>
      <div className="sbattrib">
        <div className="title txtl">Area by driving time or distance</div>
        <div className="sbopt">
          <button
            type="button"
            className={classNames('light btn1', { active: !selectArea })}
            onClick={() => {
              setSelectArea(false);
              setDrivingTime(RADIUS_WHITH[0].value);
            }}
          >
            <span className={classNames({ icg301: selectArea, 'icg301-light': !selectArea })} />
          </button>
          <button
            type="button"
            className={classNames('light btn1', { active: selectArea })}
            onClick={() => {
              setSelectArea(true);
              setDrivingTime(DRIVING_DISTANCE[0].value);
            }}
          >
            <span className={classNames({ icg303: !selectArea, 'icg303-light': selectArea })} />
          </button>
          <div className="timearea">
            <FormControl>
              <Select
                displayEmpty
                onChange={(e: SelectChangeEvent<string>, childContainer: GenericAny) => {
                  setDrivingLabel(childContainer.props.children.props.children);
                  setDrivingTime(e.target.value as string);
                }}
                value={drivingTime}
                renderValue={
                  drivingTime ? undefined : () => <span className="placeholder-subdropdown">Select dist.</span>
                }
              >
                {selectArea
                  ? DRIVING_DISTANCE.map((element: GenericAny) => (
                    <MenuItem value={element.value} key={element.value}>
                      <span className="txt">{element.label}</span>
                    </MenuItem>
                  ))
                  : RADIUS_WHITH.map((element: GenericAny) => (
                    <MenuItem value={element.value} key={element.value}>
                      <span className="txt">{element.label}</span>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div id="awscroll3" ref={scrollRef}>
        <AboutUs ref={addRef} />
        <Population ref={addRef} />
        <Economy ref={addRef} />
        <Housing ref={addRef} />
        <Language ref={addRef} />
        <Technology ref={addRef} />
      </div>
    </>
  );
};
