import { useMemo, useState } from 'react';
import { VisibilityDispatch, VisibilityState } from '../context/VisibilityContext';

export const VisibilityProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [facilitiesVisible, setFacilitiesVisible] = useState(true);
  const [cheaVisible, setCheaVisible] = useState(false);
  const [publicaVisible, setPublicaVisible] = useState(false);
  const [censusVisible, setCensusVisible] = useState(false);
  const state = useMemo(() => ({
    facilitiesVisible, cheaVisible, publicaVisible, censusVisible
  }), [facilitiesVisible, cheaVisible, publicaVisible, censusVisible]);
  const setters = useMemo(() => ({
    setFacilitiesVisible, setCheaVisible, setPublicaVisible, setCensusVisible
  }), [setFacilitiesVisible, setCheaVisible, setPublicaVisible, setCensusVisible]);
  return (
    <VisibilityState.Provider value={state}>
      <VisibilityDispatch.Provider value={setters}>
        {children}
      </VisibilityDispatch.Provider>
    </VisibilityState.Provider>
  );
};
