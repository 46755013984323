import { MAP_CONSTANTS } from '.';
import { TOOLTIP_INFO } from './sidebar';
import { MAP_LAYERS } from './mapLayers';
import { housingParser, mapStylePointer } from '../utils';

const MAP_STYLES_MALE = [
  0,
  'hsla(0,7%,100%, 0)',
  0.2,
  'hsl(180, 75%, 89%)',
  0.4,
  'hsl(197, 69%, 83%)',
  0.6,
  '#73caed',
  0.8,
  'hsl(204, 72%, 48%)'
];
const MEDIAN_HOME = [
  0, 'hsla(0,0,100%, 0)',
  300000, '#c8d2ef',
  500000, '#4a96de',
  700000, '#2023cb',
  1000000, '#7363ee',
  1500000, '#cbb352'
];
const MAP_STYLE_POPULATION = [
  0, 'hsla(0,0,100%, 0)', 0.2, '#c5bde0', 0.4, '#f6daf0', 0.6, '#ca8cd4', 0.8, '#d540dd', 1, '#f8305b'
];
const MAP_STYLE_POPULATION_UNDER = [
  0, 'hsla(0,0,100%, 0)', 0.01, '#c5bde0', 0.02, '#f6daf0', 0.03, '#ca8cd4', 0.04, '#d540dd', 0.05, '#f8305b'
];
const MAP_STYLE_POPULATION_EDUCATION = [
  0, 'hsla(0,0,100%, 0)', 0.06, '#c5bde0', 0.12, '#f6daf0', 0.18, '#ca8cd4', 0.24, '#d540dd', 0.30, '#f8305b'
];
const MAP_STYLE_TECHNOLOGY = [
  0, 'hsla(0,0,100%, 0)', 0.2, '#f4ebf2', 0.4, '#f6daf0', 0.6, '#ca8cd4', 0.8, '#5f4ad3', 1, '#1622c0'
];
const MAP_STYLE_MEDIAN_AGE = [
  0, 'hsla(0,0,100%, 0)', 30, '#b0a9ea', 40, '#ab72b6', 50, '#423fde', 60, '#a118c3', 100, '#c31b18'
];
const MAP_STYLE_INCOME = [
  0, 'hsla(0,0,100%, 0)', 20000, '#c8d1ef', 40000, '#4a96de', 60000, '#2023cb', 80000, '#7363ee', 100000, '#cbb352'
];
const MEDIA_RENT = [
  0, 'hsla(0,0,100%, 0)', 500, '#c9e4da', 1000, '#88dd7e', 1500, '#53b0d5', 2000, '#bc2ae5', 2200, '#0b38ea'
];
export const MAP_STYLES = {
  [MAP_LAYERS.CSH_FACILITIES_TABLE]: [
    {
      type: 'circle',
      'source-layer': 'pluto15v1',
      layout: {},
      paint: {
        'circle-color': [
          'match',
          ['get', 'entity_type'],
          ['CLINIC'],
          '#f073c6',
          [
            'OUTPATIENT SURGERY CENTER',
            'OUTPATIENT TREATMENT CENTER',
            'OUTPATIENT REHABILITATION CENTER',
            'OUTPATIENT LABORATORY'
          ],
          '#a81e7a',
          ['AMBULATORY SURGERY CENTER'],
          '#4310e9',
          ['ACUTE CARE HOSPITAL'],
          '#46a1a4',
          ['AMBULATORY CARE CENTER'],
          'hsl(39, 83%, 49%)',
          ['FREESTANDING EMERGENCY ROOM'],
          '#5beaf0',
          ['SPECIALTY HOSPITAL'],
          '#f0735f',
          '#000000'
        ],
        'circle-opacity': 0.28,
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 3, 5, 10, 10]
      },
      hoverLayer: false
    },
    {
      type: 'circle',
      source: 'composite',
      'source-layer': 'pluto15v1',
      paint: {
        'circle-color': [
          'match',
          ['get', 'entity_type'],
          ['CLINIC'],
          '#f073c6',
          [
            'OUTPATIENT SURGERY CENTER',
            'OUTPATIENT TREATMENT CENTER',
            'OUTPATIENT REHABILITATION CENTER',
            'OUTPATIENT LABORATORY'
          ],
          '#a81e7a',
          ['AMBULATORY SURGERY CENTER'],
          '#4310e9',
          ['ACUTE CARE HOSPITAL'],
          '#46a1a4',
          ['AMBULATORY CARE CENTER'],
          'hsl(39, 83%, 49%)',
          ['FREESTANDING EMERGENCY ROOM'],
          '#5BEAF0',
          ['SPECIALTY HOSPITAL'],
          '#f0735f',
          '#000000'
        ],
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 3, 3, 10, 5]
      },
      hoverLayer: false
    },
    {
      type: 'circle',
      'source-layer': 'pluto15v1',
      layout: {},
      paint: {
        'circle-color': '#fff',
        'circle-opacity': 1,
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 3, 2, 10, 3]
      },
      filter: ['match', ['get', MAP_CONSTANTS.FIELDS_CSH.ENTITYID], [''], true, false],
      hoverLayer: true
    }
  ],
  [MAP_LAYERS.CSH_STATE_TABLE]: [
    {
      type: 'fill',
      'source-layer': 'pluto15v1',
      layout: {
        visibility: 'none'
      },
      paint: {
        'fill-outline-color': 'hsl(283, 89%, 39%)',
        'fill-opacity': 0.6,
        'fill-color': 'hsl(292, 69%, 46%)'
      },
      hoverLayer: true
    },
    {
      type: 'line',
      'source-layer': 'pluto15v1',
      layout: {},
      paint: {
        'line-color': '#fff',
        'line-width': 1.5
      },
      filter: ['match', ['get', MAP_CONSTANTS.FIELDS_STATE.ABBREV], [''], true, false],
      hoverLayer: true
    },
    {
      type: 'line',
      layout: {},
      paint: {
        'line-color': '#fff',
        'line-width': 3
      },
      filter: ['match', ['get', MAP_CONSTANTS.FIELDS_STATE.ABBREV], [''], true, false],
      hoverLayer: false
    }
  ],
  [MAP_LAYERS.CSH_CHEA_TABLE]: [
    {
      type: 'circle',
      'source-layer': 'pluto15v1',
      layout: {
        visibility: 'none'
      },
      paint: {
        'circle-color': '#E6961E',
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 3, 9, 10, 11],
        'circle-opacity': 0.75
      }
    },
    {
      type: 'symbol',
      layout: {
        'icon-image': 'ic-chea',
        'icon-size': 0.47,
        visibility: 'none'
      }
    }
  ],
  [MAP_LAYERS.CSH_PUBLICA_TABLE]: [
    {
      type: 'circle',
      'source-layer': 'pluto15v1',
      layout: {
        visibility: 'none'
      },
      paint: {
        'circle-color': '#3DC75B',
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 3, 9, 10, 11],
        'circle-opacity': 0.75
      }
    },
    {
      type: 'symbol',
      layout: {
        'icon-image': 'ic-acgme',
        'icon-size': 0.47,
        visibility: 'none'
      }
    }
  ],
  [MAP_LAYERS.CSH_STATE_DIVISION]: [
    {
      type: 'fill',
      'source-layer': 'pluto15v1',
      layout: {
        visibility: 'none'
      },
      paint: {
        'fill-outline-color': 'hsl(283, 89%, 39%)',
        'fill-opacity': 0.6,
        'fill-color': 'hsl(292, 69%, 46%)'
      },
      hoverLayer: true
    },
    {
      type: 'line',
      'source-layer': 'pluto15v1',
      layout: {},
      paint: {
        'line-color': '#fff',
        'line-width': 2
      },
      filter: ['match', ['get', MAP_CONSTANTS.FIELDS_DIVISION.DIVISION], [''], true, false],
      hoverLayer: true
    },
    {
      type: 'line',
      layout: {},
      paint: {
        'line-color': '#fff',
        'line-width': 3
      },
      filter: ['match', ['get', MAP_CONSTANTS.FIELDS_DIVISION.DIVISION], [''], true, false],
      hoverLayer: false
    }
  ],
  [MAP_LAYERS.CSH_SOCIO_TABLE]: [
    {
      type: 'fill',
      'source-layer': 'pluto15v1',
      layout: {
        visibility: 'none'
      },
      paint: {
        'fill-outline-color': '#b0aeb2',
        'fill-opacity': 0.3,
        'fill-color': '#b0aeb2'
      }
    },
    {
      type: 'line',
      'source-layer': 'pluto15v1',
      layout: {},
      paint: {
        'line-color': '#fff',
        'line-width': 2
      },
      filter: ['match', ['get', MAP_CONSTANTS.FIELDS_CENSUS.CARTODBID], [''], true, false],
      hoverLayer: true
    }
  ]
};

export const CENSUS_STYLES = {
  [TOOLTIP_INFO.MALE.title]: {
    paint: mapStylePointer('male_pop', MAP_STYLES_MALE)
  },
  [TOOLTIP_INFO.FEMALE.title]: {
    paint: mapStylePointer('female_pop', MAP_STYLE_POPULATION)
  },
  [TOOLTIP_INFO.MEDIAN_AGE.title]: {
    paint: mapStylePointer('median_age', MAP_STYLE_MEDIAN_AGE)
  },
  [TOOLTIP_INFO.MALE_UNDER_5.title]: {
    paint: mapStylePointer('male_under', MAP_STYLE_POPULATION_UNDER)
  },
  [TOOLTIP_INFO.FEMALE_UNDER_5.title]: {
    paint: mapStylePointer('female_und', MAP_STYLE_POPULATION_UNDER)
  },
  [TOOLTIP_INFO.POPULATION_ABOVE_16.title]: {
    paint: mapStylePointer('pop_16_ove', MAP_STYLE_POPULATION)
  },
  [TOOLTIP_INFO.WHITE.title]: {
    paint: mapStylePointer('white_pop', MAP_STYLE_POPULATION)
  },
  [TOOLTIP_INFO.BLACK.title]: {
    paint: mapStylePointer('black_pop', MAP_STYLE_POPULATION)
  },
  [TOOLTIP_INFO.HISPANIC.title]: {
    paint: mapStylePointer('hispanic_p', MAP_STYLE_POPULATION)
  },
  [TOOLTIP_INFO.ASIAN.title]: {
    paint: mapStylePointer('asian_pop', MAP_STYLE_POPULATION)
  },
  [TOOLTIP_INFO.AMERINDIAN.title]: {
    paint: mapStylePointer('amerindian', MAP_STYLE_POPULATION)
  },
  [TOOLTIP_INFO.OTHER.title]: {
    paint: mapStylePointer('other_race', MAP_STYLE_POPULATION)
  },
  [TOOLTIP_INFO.POPULATION_EDUCATION_HIGH_SCHOOL.title]: {
    paint: mapStylePointer('high_schoo', MAP_STYLE_POPULATION_EDUCATION)
  },
  [TOOLTIP_INFO.POPULATION_EDUCATION_ASSOCIATES.title]: {
    paint: mapStylePointer('associates', MAP_STYLE_POPULATION_EDUCATION)
  },
  [TOOLTIP_INFO.POPULATION_EDUCATION_BACHELORS.title]: {
    paint: mapStylePointer('bachelors_', MAP_STYLE_POPULATION_EDUCATION)
  },
  [TOOLTIP_INFO.POPULATION_EDUCATION_MASTERS.title]: {
    paint: mapStylePointer('masters_de', MAP_STYLE_POPULATION_EDUCATION)
  },
  [TOOLTIP_INFO.POPULATION_EDUCATION_DOCTORATE.title]: {
    paint: mapStylePointer('doctorate_', MAP_STYLE_POPULATION_EDUCATION)
  },
  [TOOLTIP_INFO.MEDIAN_INCOME.title]: {
    paint: mapStylePointer('median_inc', MAP_STYLE_INCOME)
  },
  [TOOLTIP_INFO.INCOME_PER_CAPITA.title]: {
    paint: mapStylePointer('income_per', MAP_STYLE_INCOME)
  },
  [TOOLTIP_INFO.INCOME_LESS_10000.title]: {
    paint: mapStylePointer(
      'income_les',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_10000_14999.title]: {
    paint: mapStylePointer(
      'income_100',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_15000_19999.title]: {
    paint: mapStylePointer(
      'income_150',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_20000_24999.title]: {
    paint: mapStylePointer(
      'income_200',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_25000_29999.title]: {
    paint: mapStylePointer(
      'income_250',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_30000_34999.title]: {
    paint: mapStylePointer(
      'income_300',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_35000_39999.title]: {
    paint: mapStylePointer(
      'income_350',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_40000_44999.title]: {
    paint: mapStylePointer(
      'income_400',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_45000_49999.title]: {
    paint: mapStylePointer(
      'income_450',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_50000_59999.title]: {
    paint: mapStylePointer(
      'income_500',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_60000_74999.title]: {
    paint: mapStylePointer(
      'income_600',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_75000_99999.title]: {
    paint: mapStylePointer(
      'income_750',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_100000_124999.title]: {
    paint: mapStylePointer(
      'income_1_1',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_125000_149999.title]: {
    paint: mapStylePointer(
      'income_125',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_150000_199999.title]: {
    paint: mapStylePointer(
      'income_1_2',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.20, 0.3],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.INCOME_200000_or_more.title]: {
    paint: mapStylePointer(
      'income_2_1',
      housingParser(
        [0, 0.06, 0.12, 0.18, 0.24, 0.4],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.MEDIAN_HOME_VALUE.title]: {
    paint: mapStylePointer('owner_oc_1', MEDIAN_HOME)
  },
  [TOOLTIP_INFO.HOUSING_UNITS_OWNED.title]: {
    paint: mapStylePointer(
      'occupied_h',
      housingParser(
        [0, 0.2, 0.4, 0.6, 0.8, 1],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.HOUSING_UNITS_RENTED.title]: {
    paint: mapStylePointer(
      'housing_1',
      housingParser(
        [0, 0.1, 0.2, 0.3, 0.4, 0.5],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.MEDIAN_RENT.title]: {
    paint: mapStylePointer('median_ren', MEDIA_RENT)
  },
  [TOOLTIP_INFO.RENT_OVER_50_PERCENT_INCOME.title]: {
    paint: mapStylePointer(
      'rent_over_',
      housingParser(
        [0, 0.01, 0.05, 0.1, 0.15, 0.2],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.RENT_IS_40_50_PERCENT_INCOME.title]: {
    paint: mapStylePointer(
      'rent_40_to',
      housingParser(
        [0, 0.01, 0.05, 0.1, 0.15, 0.2],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.RENT_IS_35_40_PERCENT_INCOME.title]: {
    paint: mapStylePointer(
      'rent_35_to',
      housingParser(
        [0, 0.01, 0.05, 0.1, 0.15, 0.2],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.RENT_IS_30_35_PERCENT_INCOME.title]: {
    paint: mapStylePointer(
      'rent_30_to',
      housingParser(
        [0, 0.01, 0.05, 0.1, 0.15, 0.2],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.RENT_IS_25_30_PERCENT_INCOME.title]: {
    paint: mapStylePointer(
      'rent_25_to',
      housingParser(
        [0, 0.01, 0.05, 0.1, 0.15, 0.2],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.RENT_IS_20_25_PERCENT_INCOME.title]: {
    paint: mapStylePointer(
      'rent_20_to',
      housingParser(
        [0, 0.01, 0.05, 0.1, 0.15, 0.2],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.RENT_IS_15_20_PERCENT_INCOME.title]: {
    paint: mapStylePointer(
      'rent_15_to',
      housingParser(
        [0, 0.01, 0.05, 0.1, 0.15, 0.2],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.RENT_IS_10_15_PERCENT_INCOME.title]: {
    paint: mapStylePointer(
      'rent_10_to',
      housingParser(
        [0, 0.01, 0.05, 0.1, 0.15, 0.2],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.RENT_UNDER_10_PERCENT_INCOME.title]: {
    paint: mapStylePointer(
      'rent_under',
      housingParser(
        [0, 0.01, 0.05, 0.1, 0.15, 0.2],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.EMPLOYED.title]: {
    paint: mapStylePointer(
      'employed_p',
      housingParser(
        [0, 0.2, 0.3, 0.4, 0.5, 0.6],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.UNEMPLOYED.title]: {
    paint: mapStylePointer(
      'unemployed',
      housingParser(
        [0, 0.01, 0.02, 0.03, 0.04, 0.05],
        ['hsla(0,0,100%, 0)', '#c8d2ef', '#4a96de', '#2023cb', '#7363ee', '#cbb352']
      )
    )
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_LIMITED_ENGLISH.title]: {
    paint: mapStylePointer(
      'househol_2',
      housingParser(
        [0, 0.2, 0.4, 0.6, 0.8, 1],
        ['hsla(0,0,100%, 0)', '#f4ebf2', '#f6daf0', '#ca8cd4', '#5f4ad3', '#1622c0']
      )
    )
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_SPEAK_SPANISH.title]: {
    paint: mapStylePointer(
      'househol_3',
      housingParser(
        [0, 0.2, 0.4, 0.6, 0.8, 1],
        ['hsla(0,0,100%, 0)', '#f4ebf2', '#f6daf0', '#ca8cd4', '#5f4ad3', '#1622c0']
      )
    )
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_INDO_EUROPEAN_LANGUAGE.title]: {
    paint: mapStylePointer(
      'househol_4',
      housingParser(
        [0, 0.2, 0.4, 0.6, 0.8, 1],
        ['hsla(0,0,100%, 0)', '#f4ebf2', '#f6daf0', '#ca8cd4', '#5f4ad3', '#1622c0']
      )
    )
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ASIAN_PACIFIC_ISLAND_LANGUAGE.title]: {
    paint: mapStylePointer(
      'househol_5',
      housingParser(
        [0, 0.2, 0.4, 0.6, 0.8, 1],
        ['hsla(0,0,100%, 0)', '#f4ebf2', '#f6daf0', '#ca8cd4', '#5f4ad3', '#1622c0']
      )
    )
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ANOTHER_LANGUAGE.title]: {
    paint: mapStylePointer(
      'househol_6',
      housingParser(
        [0, 0.2, 0.4, 0.6, 0.8, 1],
        ['hsla(0,0,100%, 0)', '#f4ebf2', '#f6daf0', '#ca8cd4', '#5f4ad3', '#1622c0']
      )
    )
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_NO_INTERNET_ACCESS.title]: {
    paint: mapStylePointer('internet_5', MAP_STYLE_TECHNOLOGY)
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_INTERNET_ACCESS.title]: {
    paint: mapStylePointer('internet_s', MAP_STYLE_TECHNOLOGY)
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_BROADBAND_INTERNET.title]: {
    paint: mapStylePointer('internet_2', MAP_STYLE_TECHNOLOGY)
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_SATELLITE_INTERNET.title]: {
    paint: mapStylePointer(
      'internet_4',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.2, 3],
        ['hsla(0,0,100%, 0)', '#f4ebf2', '#f6daf0', '#ca8cd4', '#5f4ad3', '#1622c0']
      )
    )
  },
  [TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_DIAL_UP_INTERNET.title]: {
    paint: mapStylePointer(
      'internet_1',
      housingParser(
        [0, 0.05, 0.1, 0.15, 0.2, 3],
        ['hsla(0,0,100%, 0)', '#f4ebf2', '#f6daf0', '#ca8cd4', '#5f4ad3', '#1622c0']
      )
    )
  },
  [TOOLTIP_INFO.DEFAULT.title]: mapStylePointer()
};

export const BOUNDARIES_STYLES = [
  {
    type: 'fill',
    'source-layer': 'pluto15v1',
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-outline-color': '#db29d8',
      'fill-opacity': 1,
      'fill-color': 'hsla(301, 71%, 51%, 0.4)'
    }
  }, {
    type: 'line',
    'source-layer': 'pluto15v1',
    layout: {
      'line-join': 'miter',
      'line-miter-limit': 2,
      'line-cap': 'butt',
      visibility: 'none'
    },
    paint: {
      'line-color': 'hsl(301, 71%, 51%)',
      'line-width': 1
    },
    hoverLayer: true
  }, {
    type: 'line',
    'source-layer': 'pluto15v1',
    layout: {
      'line-join': 'miter',
      'line-miter-limit': 2,
      'line-cap': 'butt',
      visibility: 'none'
    },
    paint: {
      'line-color': 'hsl(0, 62%, 97%)',
      'line-width': 0.4,
      'line-offset': -2
    },
    hoverLayer: true
  }
];
