import React, { useEffect } from 'react';

export const useScrollSelect = (
  refs: HTMLDivElement[],
  scrollRef: React.RefObject<HTMLDivElement>,
  setSelectedOpt: React.Dispatch<React.SetStateAction<number>>
) => {
  useEffect(() => {
    const fn = () => {
      const topDistance = scrollRef.current?.scrollTop ?? 0;
      let found = false;
      let accumulatedHeight = 0;
      const offsetLimit = 100;
      refs.forEach((div: HTMLDivElement, divIndex: number) => {
        if (found) return;
        if (topDistance <= (accumulatedHeight + div.getBoundingClientRect().height - offsetLimit)) {
          setSelectedOpt(divIndex);
          found = true;
        }
        accumulatedHeight += div.getBoundingClientRect().height;
      });
    };
    const ref = scrollRef.current;
    ref?.addEventListener('scroll', fn);
    return () => {
      ref?.removeEventListener('scroll', fn);
    };
  }, [refs, scrollRef, setSelectedOpt]);
};
