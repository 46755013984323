export const URL = process.env.REACT_APP_URL || 'http://localhost:9000';
export const URL_API = `${URL}/api`;

const AUTH = 'auth';
const CARTO_DB = 'cartodb';
const ISOCHRONE_API_URL = 'https://api.mapbox.com/isochrone/v1/mapbox/';
export const ENDPOINTS = {
  LOGIN: () => `${URL}/${AUTH}/login`,
  TILES: (table?: string) => {
    const param = table ? `?table=${table}` : '';
    return `${URL}/${CARTO_DB}/tiles${param}`;
  },
  GET_COLUMN: (column: string) => `${URL}/${CARTO_DB}/get-column?column=${column}`,
  GET_BBOX_STATE: (stateAbbrev: string) => `${URL}/${CARTO_DB}/bbox?abbrev=${stateAbbrev}`,
  GET_BBOX_DIVISIONS: (division: string) => `${URL}/${CARTO_DB}/bbox-divisions?division=${division}`,
  GET_FACILITIES: (page: number, limit: number, search?: string) => {
    let url = `${URL}/${CARTO_DB}/get-facilities?page=${page}&limit=${limit}`;
    if (search) {
      url += `&search=${search}`;
    }
    return url;
  },
  GET_FILTERED_BY_CENSUS: () => `${URL}/${CARTO_DB}/filter-by-census`,
  GET_STATES: `${URL}/${CARTO_DB}/states`,
  GET_BBOX_FILTERED: `${URL}/${CARTO_DB}/filteredbbox`,
  GET_DRIVING_DISTANCE: (
    profile:string,
    lat: number,
    lng: number,
    minutes: number
  ) => `${ISOCHRONE_API_URL}/${profile}/${lat},${lng}?contours_minutes=${minutes}&polygons=true&access_token=
    ${process.env.REACT_APP_MAPBOX_TOKEN || ''}`
};
