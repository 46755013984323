import { Tooltip } from '@mui/material';
import {
  useContext,
  useEffect,
  forwardRef
} from 'react';
import { FinantialHealthSlider, GenericAny } from '../@types';
import { TYPES } from '../constants/context';
import { FilterContextDispatch } from '../context/FilterContext';
import { FinantialHealthDispatch, FinantialHealthState } from '../context/FinantialHealthContext';
import { TooltipContent } from './ToolipContent';
import VizonomySlider from './VizonomySlider';

export const FinantialHealth = forwardRef<HTMLDivElement, GenericAny>((props, ref) => {
  const { dispatch } = useContext(FilterContextDispatch);
  const { sliders } = useContext(FinantialHealthState);
  const { setSliders } = useContext(FinantialHealthDispatch);

  useEffect(() => {
    const filters = sliders.filter((slider) => {
      if (slider.value[0] === slider.min && slider.value[1] === slider.max) {
        return false;
      }
      return true;
    }).map((slider) => {
      const [min, max] = slider.value;
      return {
        field: slider.field,
        value: ['all', ['>=', ['get', slider.field], min],
          ['<=', ['get', slider.field], max]]
      };
    });
    filters.forEach((filter) => {
      dispatch({ type: TYPES.REMOVE_AND_REPLACE, payload: filter });
    });
  }, [sliders, dispatch]);

  return (
    <div id="finantial" ref={ref}>
      <div className="pattributebar">
        <p className="txtl">Financial Health</p>
      </div>
      {
        sliders.map((slider: FinantialHealthSlider, sliderIndex: number) => (
          <div key={slider.field}>
            <div className="attributedetail">
              <div className="btit1">
                <div className="title">
                  <span className="txt">
                    {slider.label}
                    <Tooltip
                      title={(
                        <TooltipContent
                          title={slider.label}
                          description={slider.description}
                        />
                        )}
                      TransitionProps={{ timeout: 0 }}
                    >
                      <button type="button" className="light">
                        <span className="icinfo" />
                      </button>
                    </Tooltip>
                  </span>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="btit2 txt">
                {slider.subtitle}
              </div>
            </div>
            <div className="optionchk">
              <VizonomySlider
                format={slider.format}
                step={slider.step}
                min={slider.min}
                max={slider.max}
                value={slider.value}
                setValue={(value: number[]) => {
                  const newSliders = sliders.map((s: FinantialHealthSlider, i: number) => {
                    if (i === sliderIndex) {
                      return {
                        ...s,
                        value
                      };
                    }
                    return s;
                  });
                  setSliders(newSliders);
                }}
              />
            </div>
          </div>
        ))
      }
    </div>
  );
});
