import { useState } from 'react';
import { TabManager } from '../components/TabManager';
import { MapProviderWrapper } from '../provider/MapProviderWrapper';

export const Map = () => {
  const [tab, setTab] = useState(0);
  return (
    <MapProviderWrapper>
      <div className="body">
        <TabManager tab={tab} setTab={setTab} />
      </div>
    </MapProviderWrapper>
  );
};
