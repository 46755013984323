import { createContext } from 'react';
import { MapContextPropsDispatch, MapContextPropsState } from '../@types';

export const FilterContextState = createContext({
  state: {},
} as MapContextPropsState);

export const FilterContextDispatch = createContext({
  dispatch: () => {
    console.info('FilterContextDispatch is not set');
  },
} as MapContextPropsDispatch);
