import React, { useMemo, useReducer } from 'react';
import { GenericAny } from '../@types';
import { TYPES } from '../constants/context';
import { FilterContextDispatch, FilterContextState } from '../context/FilterContext';

const initialState = {
  filter: ['all'],
  showStates: false,
  states: [],
  showDivisions: false,
  currentCensusStyle: '',
  gateSidebar: false
};

const findWord = (word: string, filter: GenericAny) => {
  if (Array.isArray(filter)) {
    let found = false;
    filter.forEach((f: GenericAny) => {
      found = found || findWord(word, f);
    });
    return found;
  }
  return word === filter;
};

const reducer = (state: GenericAny, action: { type: GenericAny; payload: GenericAny; }) => {
  switch (action.type) {
    case TYPES.RESET_FILTER:
      return initialState;
    case TYPES.SET_FILTER:
      return { ...state, filter: action.payload };
    case TYPES.ADD_FILTER:
      return { ...state, filter: [...state.filter, action.payload] };
    case TYPES.REMOVE_AND_REPLACE:
      return {
        ...state,
        filter: [...state.filter.filter(
          (f: GenericAny) => !findWord(action.payload.field, f)
        ),
        action.payload.value]
      };
    case TYPES.REMOVE:
      return { ...state, filter: state.filter.filter((f: GenericAny) => !findWord(action.payload, f)) };
    case TYPES.SET_SHOW_STATES:
      return { ...state, showStates: action.payload };
    case TYPES.SET_STATES:
      return { ...state, states: action.payload };
    case TYPES.SET_SHOW_DIVISIONS:
      return { ...state, showDivisions: action.payload };
    case TYPES.SET_CURRENT_CENSUS:
      return { ...state, currentCensusStyle: action.payload };
    case TYPES.SET_GATE_SIDEBAR:
      return { ...state, gateSidebar: action.payload };
    default:
      return state;
  }
};

export const FilterProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateValue = useMemo(
    () => ({ state }),
    [state]
  );
  const dispatchValue = useMemo(
    () => ({ dispatch }),
    []
  );
  return (
    <FilterContextState.Provider value={stateValue}>
      <FilterContextDispatch.Provider value={dispatchValue}>
        {children}
      </FilterContextDispatch.Provider>
    </FilterContextState.Provider>
  );
};
