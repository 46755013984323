import { createContext } from 'react';
import { TechnologySlider } from '../@types';

export const TechnologyState = createContext({
  sliders: []
} as {
  sliders: TechnologySlider[]
});

export const TechnologyDispatch = createContext({
  setSliders: () => {
    console.info('TechnologyDispatch is not set');
  },
  resetSliders: () => {
    console.info('TechnologyDispatch is not set');
  }
} as {
  setSliders: (sliders: TechnologySlider[]) => void,
  resetSliders: () => void
});
