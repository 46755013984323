import React, { useState, useMemo } from 'react';
import { DRIVING_DISTANCE } from '../constants';
import { BoundariesState, BoundariesDispatch } from '../context/BoundariesContext';

const initialState = {
  drivingTime: DRIVING_DISTANCE[0].value
};

export const BoundariesProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [drivingTime, setDrivingTime] = useState<string>(initialState.drivingTime);
  const states = useMemo(
    () => ({
      drivingTime
    }),
    [drivingTime]
  );

  const setters = useMemo(
    () => ({
      setDrivingTime
    }),
    []
  );

  return (
    <BoundariesState.Provider value={states}>
      <BoundariesDispatch.Provider value={setters}>{children}</BoundariesDispatch.Provider>
    </BoundariesState.Provider>
  );
};
