import { QueryClient } from 'react-query';

const ONE_HOUR_IN_MS = 3600000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: ONE_HOUR_IN_MS,
    },
  },
});

export default queryClient;
