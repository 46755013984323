import { FinantialHealthProvider } from './FinantialHealthProvider';
import { HospitalCharacteristicsProvider } from './HospitalCharacteristicsProvider';
import { PatientServicesProvider } from './PatientServicesProvider';
import { PrimaryAttributesProvider } from './PrimaryAttributesProvider';
import { StaffRetentionProvider } from './StaffRetentionProvider';

export const SidebarSecondTabProvider = ({ children }: React.PropsWithChildren<unknown>) => (
  <PrimaryAttributesProvider>
    <HospitalCharacteristicsProvider>
      <PatientServicesProvider>
        <StaffRetentionProvider>
          <FinantialHealthProvider>
            {children}
          </FinantialHealthProvider>
        </StaffRetentionProvider>
      </PatientServicesProvider>
    </HospitalCharacteristicsProvider>
  </PrimaryAttributesProvider>
);
