import { GenericAny } from '../@types';

export const AREA_OR_DOMAIN = [
  { name: 'National', value: '' },
  { name: 'CSH Division', value: 'division' },
  { name: 'Physician Enterprise Division', value: 'physician_enterprise_division' },
  { name: 'Market', value: 'market' },
  { name: 'State', value: 'state' }
];

export const INVERTED_AREA_OR_DOMAIN = {
  division: 'CSH Division',
  physician_enterprise_division: 'Physician Enterprise Division',
  market: 'Market',
  state: 'State',
  '': ''
};

export const TAX_YES = '501(c)(3)';

export const enum TABS_SECOND {
  INFORMATION,
  PRIMARY,
  HOSPITAL,
  PATIENT,
  STAFF,
  FINANTIAL
}

export const enum TABS_THIRD {
  INFORMATION,
  POPULATION,
  ECONOMY,
  HOUSING,
  LANGUAGE,
  TECHNOLOGY
}

export interface ResetProps {
  needsReset: boolean;
  setNeedsReset: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ICLayerProps {
  label: string;
}

export const TOOLTIP_INFO: { [key: string]: GenericAny } = {
  ENTITY_TYPE: {
    title: 'Entity Type',
    description: 'Defines type of facility'
  },
  GRADUATE_MEDICAL_EDUCATION: {
    title: 'Graduate Medical Education',
    description: 'CSH facility with a GME Program'
  },
  HEALTH_EQUITY: {
    title: 'Health Equity',
    description: `% improvement in capturing REaL (race, ethnicity, and language)
    data within CommonSpirit registration platforms across acute and ambulatory encounters.`
  },
  CHARITY: {
    title: 'Charity & Medicaid Service',
    description: 'Total Charity and Medicaid revenue as a percentage of gross revenue'
  },
  TAX_STATUS: {
    title: 'Tax Status',
    description: 'Is the location a 501(c)(3) entity'
  },
  LICENSED_BEDS_ACUTE: {
    title: 'Licensed Beds - Acute',
    description: '# of licensed beds associated with a hospital'
  },
  LICENSED_BEDS_LTC: {
    title: 'Licensed Beds - LTC',
    description: '# of licensed beds associated with LTC facility'
  },
  OPERATING_BEDS_ACUTE: {
    title: 'Operating Beds - Acute',
    description: '# of beds able to receive a patient associated with a hospital'
  },
  OPERATING_BEDS_LTC: {
    title: 'Operating Beds - LTC',
    description: '# of beds able to receive a patient associated with LTC facility'
  },
  PATIENT_CARE_QUALITY_ACUTE_SEPSIS_MORTALITY: {
    title: 'Patient Care Quality - Acute',
    description: `Sepsis Mortality: Comparison of observed to expected (O:E) mortality for inpatient
      encounters using the Midas Risk Adjustment (RA) model.
      The model uses statistical modeling incorporating over 700 variables.
      A percentile ranking is computed for the O:E ratio. `
  },
  PATIENT_CARE_QUALITY_CONTINUUM_OF_CARE_HYPERTENSION_MANAGEME: {
    title: 'Patient Care Quality - Continuum of Care',
    description: `Hypertension Management: Percentage of patients 18 - 85 years of age who had a diagnosis and/or
     active problem of hypertension and whose blood pressure was adequately controlled (<140/<90 mmHg)
     during the measurement period.`
  },
  PATIENT_EXPERIENCE_ACUTE_CARE_EXPERIENCE: {
    title: 'Patient Experience - Acute Care Experience',
    description: 'A composite of nine domains expressed as a percentile rank against the Press Ganey database. '
  },
  PATIENT_EXPERIENCE_MEDICAL_PRACTICE_EXPERIENCE: {
    title: 'Patient Experience - Medical Practice Experience',
    description: 'A composite of four measures expressed as a percentile rank against the Press Ganey database. '
  },
  PATIENT_EXPERIENCE_VIRTUAL_MEDICAL_PRACTICE_EXPERIENCE: {
    title: 'Patient Experience - Virtual Medical Practice Experience',
    description: 'A composite of four measures expressed as a percentile rank against the Press Ganey database. '
  },
  PATIENT_SAFETY: {
    title: 'Patient Safety: Hospital-Acquired Infection Composite',
    description: `A composite of standardized infection ratio (SIR) of five different infections. 
      A percentile is computed for each SIR and the measure is reported as an average of the percentiles.`
  },
  EMPLOYEE_ENGAGEMENT: {
    title: 'Employee Engagement',
    description: `Score based on the following questions from the annual employee survey:
    I would recommend this organization as a good place to work; I would recommend this organization
    to friends and family who need care; I would like to be working at this organization three years from now;
    I am proud to tell people I work for this organization;
    I would stay with this organization if offered a similar position elsewhere;
    Overall, I am a satisfied employee.`
  },
  NEW_HIRE_ADJUSTED_RETENTION_RATE: {
    title: 'New Hire Adjusted Retention Rate',
    description: `Trailing 12 month retention rate of benefits eligible employees with less than one year of tenure.
      Exits that count against retention include voluntary (employee initiated) resignations of benefits eligible,
      full-time or part-time staff, except those resignations that do not reflect true voluntary turnover
      (e.g. reinstatements) or resignations related to the vaccine mandate. Residents and Interns are not included
      in the employee population being measured.`
  },
  PHYSICIAN_APP_EXPERIENCE: {
    title: 'Physician/APP Experience',
    description: `Three question composite measuring provider engagement and retention from the annual
      Press Ganey Physician/APP experience survey: My facility leadership involves me in decisions that effect my work;
      My facility is open and responsive to my input; My facility recognizes clinicians for excellent work.`
  },
  EBITDA_AFTER_SPECIAL_CHARGES: {
    title: 'EBITDA',
    description: 'Earnings Before Interest, Depreciation, Taxes and Amorization relative to total operating revenue.'
  },
  DAYS_CASH: {
    title: 'Days Cash',
    description: `Cash and cash equivalents, short-term investments and Board-designated assets for capital resources
      over total expenses minus depreciation and amortization divided by calendar days
      (normalized for investment returns). Measure adjusted for excluding any FEMA and/or CARES
      funding in FY22 and any unpaid balances of Medicare Advances/Deferred Employer Payroll Taxes.'`
  },
  NPSR_GROWTH: {
    title: 'NPSR % Growth',
    description: 'NPSR revenue % improvement (same store)'
  },
  REVENUE_FROM_DIVERSIFIED_NON_ACUTE_SOURCES: {
    title: 'Revenue from Diversified (Non-Acute) Sources',
    description: `Non-acute hospital outpatient net revenue, physician enterprise net revenue,
      revenue associated with national service lines, ambulatory revenue (not consolidated with operations
      for hospital), and share of operating income booked as other operating revenue associated with any ambulatory
      activity accounted for under equity method.`
  },
  PHILANTHROPY: {
    title: 'Philanthropy',
    description: 'Percentage of total revenue allocated to philanthropic activities.'
  },
  // population
  GENDER: {
    title: 'Gender',
    description: `Percent female and male populations within each community 
      whose perimeter around each CSH facility is defined above.`,
    subtitle: 'Define the percent males and females within each CSH Facility boundary.'
  },
  MEDIAN_AGE: {
    title: 'Median Age',
    description: `The median age of all people within each community 
      whose perimeter around each CSH facility is defined above.`,
    subtitle: 'Define the median age (national is 38 years old).'
  },
  POPULATION_UNDER_5: {
    title: '% of Population Under 5 Yrs Old',
    description: `Percent female and male populations under 5 Yrs old within each community 
      whose perimeter around each CSH facility is defined above.`,
    subtitle: 'Define the percent of population under 5 years old by gender.'
  },
  POPULATION_ABOVE_16: {
    title: '% of Population Over 16 Yrs Old',
    description: `Percent population over 16 Yrs old within each community 
      whose perimeter around each CSH facility is defined above.`,
    subtitle: 'Define the percent of population over 16 Yrs Old.'
  },
  POPULATION_RACE: {
    title: '% of Population by Race',
    description: `Percent population by race within each community 
    whose perimeter around each CSH facility is defined above.`,
    subtitle: 'Define the percent of population by race.'
  },
  POPULATION_RACE_WHITE: {
    title: '% of Population by Race - White',
    description:
      'Percent white population within each community whose perimeter around each CSH facility is defined above.'
  },
  POPULATION_RACE_BLACK: {
    title: '% of Population by Race - Black',
    description:
      'Percent black population within each community whose perimeter around each CSH facility is defined above.'
  },
  POPULATION_RACE_HISPANIC: {
    title: '% of Population by Race - Hispanic',
    description:
      'Percent hispanic population within each community whose perimeter around each CSH facility is defined above.'
  },
  POPULATION_RACE_ASIAN: {
    title: '% of Population by Race - Asian',
    description:
      'Percent asian population within each community whose perimeter around each CSH facility is defined above.'
  },
  POPULATION_RACE_AMERICAN_INDIAN: {
    title: '% of Population by Race - American Indian',
    description: `Percent American Indian population within each community 
      whose perimeter around each CSH facility is defined above.`
  },
  POPULATION_RACE_OTHER: {
    title: '% of Population by Race - Other',
    description: `Percent population - whose race is other - within each community 
      whose perimeter around each CSH facility is defined above.`
  },
  POPULATION_EDUCATION_HIGH_SCHOOL: {
    title: 'High School Diploma',
    description: `Percent high school population within each community 
      whose perimeter around each CSH facility is defined above.`
  },
  POPULATION_EDUCATION_ASSOCIATES: {
    title: 'Associates Degree',
    description: `Percent population - with an associates degree - within each community 
      whose perimeter around each CSH facility is defined above.`
  },
  POPULATION_EDUCATION_BACHELORS: {
    title: 'Bachelors Degree',
    description: `Percent population - with a bachelors degree - within each community 
      whose perimeter around each CSH facility is defined above.`
  },
  POPULATION_EDUCATION_MASTERS: {
    title: 'Masters Degree',
    description: `Percent population - with a masters degree - within each community 
      whose perimeter around each CSH facility is defined above.`
  },
  POPULATION_EDUCATION_DOCTORATE: {
    title: 'Doctorate',
    description: `Percent population - with a doctorate degree - within each community 
      whose perimeter around each CSH facility is defined above.`
  },
  //  economy
  MEDIAN_INCOME: {
    title: 'Median Income',
    description: `Median Household Income in the past 12 Months. Within a geographic area, 
    the median income received by every household on a regular basis before payments for 
    personal income taxes, social security, union dues, medicare deductions, etc.`,
    subtitle: 'Define the median income per household (national is $67,521).'
  },
  POPULATION_PER_INCOME_GROUP: {
    title: 'Population Per Income Group',
    description: 'Define the percent of population whose income falls within each of the income categories below.',
    subtitle: 'Define the percent of population whose income falls within each of the income categories below.'
  },
  INCOME_PER_CAPITA: {
    title: 'Income Per Capita',
    description: `Per Capita Income in the past 12 Months. Per capita income is the mean income computed 
    for every man, woman, and child. It is derived by dividing the total income by the total population.`,
    subtitle: 'Define the income per capita per census block group (national is $53,504).'
  },
  INCOME_LESS_10000: {
    title: 'Income Less than $10,000',
    description: `Households with income less than $10,000. 
    The number of households in a geographic area whose annual income was less than $10,000.`,
  },
  INCOME_10000_14999: {
    title: 'Income between $10,000 and $14,999',
    description: `Households with income of $10,000 to $14,999. 
    The number of households in a geographic area whose annual income was between $10,000 and $14,999.`,
  },
  INCOME_15000_19999: {
    title: 'Income between $15,000 and $19,999',
    description: `Households with income of $15,000 to $19,999. 
    The number of households in a geographic area whose annual income was between $15,000 and $19,999.`,
  },
  INCOME_20000_24999: {
    title: 'Income between $20,000 and $24,999',
    description: `Households with income of $20,000 to $24,999. 
    The number of households in a geographic area whose annual income was between $20,000 and $24,999.`,
  },
  INCOME_25000_29999: {
    title: 'Income between $25,000 and $29,999',
    description: `Households with income of $25,000 to $29,999. 
    The number of households in a geographic area whose annual income was between $20,000 and $24,999.`,
  },
  INCOME_30000_34999: {
    title: 'Income between $30,000 and $34,999',
    description: `Households with income of $30,000 to $34,999. 
    The number of households in a geographic area whose annual income was between $30,000 and $34,999.`,
  },
  INCOME_35000_39999: {
    title: 'Income between $35,000 and $39,999',
    description: `Households with income of $35,000 to $39,999. 
    The number of households in a geographic area whose annual income was between $35,000 and $39,999.`,
  },
  INCOME_40000_44999: {
    title: 'Income between $40,000 and $44,999',
    description: `Households with income of $40,000 to $44,999. 
    The number of households in a geographic area whose annual income was between $40,000 and $44,999.`,
  },
  INCOME_45000_49999: {
    title: 'Income between $45,000 and $49,999',
    description: `Households with income of $45,000 to $49,999. 
    The number of households in a geographic area whose annual income was between $45,000 and $49,999.`,
  },
  INCOME_50000_59999: {
    title: 'Income between $50,000 and $59,999',
    description: `Households with income of $50,000 to $59,999. 
    The number of households in a geographic area whose annual income was between $50,000 and $59,999.`,
  },
  INCOME_60000_74999: {
    title: 'Income between $60,000 and $74,999',
    description: `Households with income of $60,000 to $74,999. 
    The number of households in a geographic area whose annual income was between $60,000 and $74,999.`,
  },
  INCOME_75000_99999: {
    title: 'Income between $75,000 and $99,999',
    description: `Households with income of $75,000 to $99,999. 
    The number of households in a geographic area whose annual income was between $75,000 and $99,999.`,
  },
  INCOME_100000_124999: {
    title: 'Income between $100,000 and $124,999',
    description: `Households with income of $100,000 to $124,999. 
    The number of households in a geographic area whose annual income was between $100,000 and $124,999.`,
  },
  INCOME_125000_149999: {
    title: 'Income between $125,000 and $149,999',
    description: `Households with income of $125,000 to $149,999. 
    The number of households in a geographic area whose annual income was between $125,000 and $149,999.`,
  },
  INCOME_150000_199999: {
    title: 'Income between $150,000 and $199,999',
    description: `Households with income of $150,000 to $199,999. 
    The number of households in a geographic area whose annual income was between $150,000 and $1999,999.`,
  },
  INCOME_200000_or_more: {
    title: 'Income more than $200,000',
    description: `Households with income of $200,000 Or More. 
    The number of households in a geographic area whose annual income was more than $200,000.`,
  },
  POPULATION_UNDER_5_YEARS_OLD: {
    title: '% of Population Under 5 Yrs Old',
    description: 'Define the percent of population under 5 years old by gender.'
  },
  POPULATION_OVER_16_YEARS_OLD: {
    title: '% of Population Over 16 Yrs Old',
    description: 'Define the percent of population over 16 Yrs Old.'
  },
  POPULATION_BY_RACE: {
    title: '% of Population by Race',
    description: 'Define the percent of population by race.'
  },
  POPULATION_BY_EDUCATION_ATTAINMENT: {
    title: '% of Population by Education Attainment',
    description: 'Define the percent of population by educational attainment.',
    subtitle: 'Define the percent of population by educational attainment.'
  },
  DEFAULT: {
    title: 'Features'
  },
  MALE: {
    title: 'Male'
  },
  FEMALE: {
    title: 'Female'
  },
  MALE_UNDER_5: {
    title: 'Male Under 5'
  },
  FEMALE_UNDER_5: {
    title: 'Female Under 5'
  },
  WHITE: {
    title: 'White'
  },
  BLACK: {
    title: 'Black'
  },
  HISPANIC: {
    title: 'Hispanic'
  },
  ASIAN: {
    title: 'Asian'
  },
  AMERINDIAN: {
    title: 'American Indian'
  },
  OTHER: {
    title: 'Other'
  },
  //  housing
  MEDIA_HOME_VALUE: {
    title: 'Median Home Value',
    description: `Owner-Occupied Housing Units Median Value. The middle value (median) of owner occupied housing units 
    within each community whose perimeter around each CSH facility is defined above.`,
    subtitle: 'Define the median home value (national is $384,000).'
  },
  HOUSING_OWNED: {
    title: '% of Housing Units Owned and Rented - Owned',
    description: `Percent of Occupied housing units within each community whose perimeter around 
    each CSH facility is defined above. A housing unit is classified as occupied 
    if it is the usual place of residence of the person or group of people living in it at the time of enumeration.`
  },
  HOUSING_RENTED: {
    title: '% of Housing Units Owned and Rented - Rented',
    description: `Percent of Renter occupied housing units within each community whose perimeter around 
    each CSH facility is defined above. All occupied units which are not owner occupied, 
    whether they are rented for cash rent or occupied without payment of cash rent, are classified as renter-occupied.`
  },
  MEDIA_RENT: {
    title: 'Median Rent',
    description: `Median Rent. The median contract rent within each community whose perimeter around 
    each CSH facility is defined above. The contract rent is the monthly rent agreed to or contracted for, 
    regardless of any furnishings, utilities, fees, meals, or services that may be included. `
  },
  AVERAGE_INCOME_SPENT_ON_RENT: {
    title: 'Average Percent Income Spent on Rent',
    description: `Percent of Household Income Spent on Rent. Within a geographic area, 
    the median percentage of household income which was spent on gross rent. 
    Gross rent is the amount of the contract rent plus the estimated average monthly cost of utilities 
    (electricity, gas, water, sewer etc.) and fuels (oil, coal, wood, etc.) if these are paid by the renter. 
    Household income is the sum of the income of all people 15 years and older living in the household.`
  },
  AMOUNT_SPENT_OVER_50: {
    title: 'Amount Spent - Rent over 50% of income',
    description: `Housing units spending over 50% income on rent. Gross rent over 50 percent of household income. 
    Computed ratio of monthly gross rent to monthly household income (total household income divided by 12).`
  },
  AMOUNT_SPENT_40_50: {
    title: 'Amount Spent - Rent is 40-50% of income',
    description: `Housing units spending 40 to 49.9% income on rent. Gross rent from 40.0 to 49.9 percent of household 
    income. Computed ratio of monthly gross rent to monthly household income (total household income divided by 12).`
  },
  AMOUNT_SPENT_35_40: {
    title: 'Amount Spent - Rent is 35-40% of income',
    description: `Housing units spending 35 to 39.9% income on rent. Gross rent from 35.0 to 39.9 percent of household 
    income. Computed ratio of monthly gross rent to monthly household income (total household income divided by 12).`
  },
  AMOUNT_SPENT_30_35: {
    title: 'Amount Spent - Rent is 30-35% of income',
    description: `Housing units spending 30 to 34.9% income on rent. Gross rent from 30.0 to 34.9 percent of household 
    income. Computed ratio of monthly gross rent to monthly household income (total household income divided by 12).`
  },
  AMOUNT_SPENT_25_30: {
    title: 'Amount Spent - Rent is 25-30% of income',
    description: `Housing units spending 25 to 29.9% income on rent. Gross rent from 25.0 to 29.9 percent of household 
    income. Computed ratio of monthly gross rent to monthly household income (total household income divided by 12).`
  },
  AMOUNT_SPENT_20_25: {
    title: 'Amount Spent - Rent is 20-25% of income',
    description: `Housing units spending 20 to 24.9% income on rent. Gross rent from 20.0 to 24.9 percent of household 
    income. Computed ratio of monthly gross rent to monthly household income (total household income divided by 12).`
  },
  AMOUNT_SPENT_15_20: {
    title: 'Amount Spent - Rent is 15-20% of income',
    description: `Housing units spending 15 to 19.9% income on rent. Gross rent from 15.0 to 19.9 percent of household 
    income. Computed ratio of monthly gross rent to monthly household income (total household income divided by 12).`
  },
  AMOUNT_SPENT_10_15: {
    title: 'Amount Spent - Rent is 10-15% of income',
    description: `Housing units spending 10 to 14.9% income on rent. Gross rent from 10.0 to 14.9 percent of household 
    income. Computed ratio of monthly gross rent to monthly household income (total household income divided by 12).`
  },
  AMOUNT_SPENT_10: {
    title: 'Amount Spent - Rent under 10% of income',
    description: `Housing units spending less than 10% on rent. Gross rent less than 10 percent of household 
    income. Computed ratio of monthly gross rent to monthly household income (total household income divided by 12).`
  },
  HOUSING_EMPLOYED: {
    title: '% of Population Employed and Unemployed - Employed',
    description: `Percent Employed Population within each community whose perimeter 
    around each CSH facility is defined above. 
    The number of civilians 16 years old and over in each geography who either (1) were "at work," that is, 
    those who did any work at all during the reference week as paid employees, 
    worked in their own business or profession, 
    worked on their own farm, or worked 15 hours or more as unpaid workers on a family farm or in a family business; 
    or (2) were "with a job but not at work," that is, those who did not work during the reference week but had jobs 
    or businesses from which they were temporarily absent due to illness, bad weather, industrial dispute, vacation, 
    or other personal reasons.`
  },
  HOUSING_UNEMPLOYED: {
    title: '% of Population Employed and Unemployed - Unemployed',
    description: `Percent Unemployed Population within each community
    whose perimeter around each CSH facility is defined above.`
  },
  //  language
  HOUSEHOLDS_LIMITED_ENGLISH: {
    title: '% of Households Who Only Speak English',
    description: `Percent of households within each community - 
    whose perimeter around each CSH facility is defined above - where residents only speak English.`
  },
  HOUSEHOLDS_ONLY_SPANISH: {
    title: '% of Households Who Only Speak Spanish',
    description: `Percent of households within each community - 
    whose perimeter around each CSH facility is defined above - where residents only speak Spanish.`
  },
  HOUSEHOLDS_ONLY_INDO_EUROPEAN: {
    title: '% of Households Who Only an Indo-European Language',
    description: `Percent of households within each community - 
    whose perimeter around each CSH facility is defined above - where residents only speak an Indo-European language.`
  },
  HOUSEHOLDS_ONLY_ASIAN_PACIFIC: {
    title: '% of Households Who Only an Asian or Pacific Island Language',
    description: `Percent of households within each community - 
    whose perimeter around each CSH facility is defined above - where residents only speak an Asian language.`
  },
  HOUSEHOLDS_ONLY_ANOTHER: {
    title: '% of Households Who Only Another Language',
    description: `Percent of households within each community - 
    whose perimeter around each CSH facility is defined above - where residents only speak Another language.`
  },
  //  technology
  HOUSEHOLDS_NO_INTERNET_ACCESS: {
    title: '% of Households with no Internet Access',
    description: `Percent of households within each community - 
    whose perimeter around each CSH facility is defined above - that do Not have Internet Access.`
  },
  HOUSEHOLDS_INTERNET_ACCESS: {
    title: '% of Households with Internet Access',
    description: `Percent of households within each community - 
    whose perimeter around each CSH facility is defined above - that have Internet Access.`
  },
  HOUSEHOLDS_BROADBAND_INTERNET: {
    title: '% of Households with only Broadband Internet',
    description: `Percent of households within each community - whose perimeter 
    around each CSH facility is defined above - that have an Internet subscription (Broadband of any type).`
  },
  HOUSEHOLDS_SATELLITE_INTERNET: {
    title: '% of Households with only Satellite Internet',
    description: `Percent of households within each community - whose perimeter 
    around each CSH facility is defined above - that have an Internet subscription (Satellite of any type).`
  },
  HOUSEHOLDS_DIAL_UP_INTERNET: {
    title: '% of Households with only Dial-Up Internet',
    description: `Percent of households within each community - whose perimeter 
    around each CSH facility is defined above - that have an Internet subscription (Dial-Up of any type).`
  },
  MEDIAN_HOME_VALUE: {
    title: 'Median Home Value',
    subtitle: 'Define the median home value (national is $384,000).',
    description: `Owner-Occupied Housing Units Median Value. The middle value (median) of owner occupied 
    housing units within each community whose perimeter around each CSH facility is defined above.`
  },
  PERCENT_HOUSING_UNITS_OWNED_AND_RENTED: {
    title: '% of Housing Units Owned and Rented - Owned',
    subtitle: 'Define the percentage of all housing units owned and rented within a census block group.'
  },
  HOUSING_UNITS_OWNED: {
    title: 'Housing Units Owned',
  },
  HOUSING_UNITS_RENTED: {
    title: 'Housing Units Rented',
  },
  MEDIAN_RENT: {
    title: 'Median Rent',
    subtitle: 'Define the median rent ($) within each census block group.'
  },
  AVERAGE_PERCENT_INCOME_SPENT_ON_RENT: {
    title: '% of Population per Amount Spent on Rent',
    subtitle: `Define the percent of population whose rent falls within 
    each of the categories below, where rent is defined as a percentage of income.`
  },
  RENT_OVER_50_PERCENT_INCOME: {
    title: 'Rent over 50% of income',
  },
  RENT_IS_40_50_PERCENT_INCOME: {
    title: 'Rent is 40-50% of income',
  },
  RENT_IS_35_40_PERCENT_INCOME: {
    title: 'Rent is 35-40% of income',
  },
  RENT_IS_30_35_PERCENT_INCOME: {
    title: 'Rent is 30-35% of income',
  },
  RENT_IS_25_30_PERCENT_INCOME: {
    title: 'Rent is 25-30% of income',
  },
  RENT_IS_20_25_PERCENT_INCOME: {
    title: 'Rent is 20-25% of income',
  },
  RENT_IS_15_20_PERCENT_INCOME: {
    title: 'Rent is 15-20% of income',
  },
  RENT_IS_10_15_PERCENT_INCOME: {
    title: 'Rent is 10-15% of income',
  },
  RENT_UNDER_10_PERCENT_INCOME: {
    title: 'Rent under 10% of income',
  },
  AVERAGE_OF_POPULATION_EMPLOYED_AND_UNEMPLOYED: {
    title: '% of Population Employed and Unemployed',
    subtitle: 'Define the percentage of the population who are either employed or unemployed.'
  },
  EMPLOYED: {
    title: 'Employed',
  },
  UNEMPLOYED: {
    title: 'Unemployed',
  },
  PERCENT_OF_HOUSEHOLDS_WITH_LIMITED_ENGLISH: {
    title: '% of Households Who Only Speak English',
    subtitle: `Define the percentage of households within each census 
    block group who only speak English.`
  },
  PERCENT_OF_HOUSEHOLDS_WHO_ONLY_SPEAK_SPANISH: {
    title: '% of Households Who Only Speak Spanish',
    subtitle: `Define the percentage of households within 
    each census block group who only speak Spanish.`
  },
  PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_INDO_EUROPEAN_LANGUAGE: {
    title: '% of Households Who Only Speak an Indo-European Language',
    subtitle: `Define the percentage of households within
    each census block group who only speak an Indo-European language.`
  },
  PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ASIAN_PACIFIC_ISLAND_LANGUAGE: {
    title: '% of Households Who Only Speak an Asian or Pacific Island Language',
    subtitle: `Define the percentage of households within 
    each census block group who only speak an Asian or Pacific Island language.`
  },
  PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ANOTHER_LANGUAGE: {
    title: '% of Households Who Speak Only Another Language',
    subtitle: `Define the percentage of households within 
    each census block group who only speak another language other than those listed above.`
  },
  PERCENT_OF_HOUSEHOLDS_WITH_NO_INTERNET_ACCESS: {
    title: '% of Households with no Internet Access',
    subtitle: `Define the percentage of households within each
     census block group who don't have Internet access.`
  },
  PERCENT_OF_HOUSEHOLDS_WITH_INTERNET_ACCESS: {
    title: '% of Households with Internet Access',
    subtitle: `Define the percentage of households within
    each census block group who have Internet Access.`
  },
  PERCENT_OF_HOUSEHOLDS_WITH_ONLY_BROADBAND_INTERNET: {
    title: '% of Households with only Broadband Internet',
    subtitle: `Define the percentage of households within each census 
    block group who only have broadband Internet access.`
  },
  PERCENT_OF_HOUSEHOLDS_WITH_ONLY_SATELLITE_INTERNET: {
    title: '% of Households with only Satellite Internet',
    subtitle: `Define the percentage of households within 
    each census block group who only have satellite Internet access.`
  },
  PERCENT_OF_HOUSEHOLDS_WITH_ONLY_DIAL_UP_INTERNET: {
    title: '% of Households with only Dial-Up Internet',
    subtitle: `Define the percentage of households within 
    each census block group who only have dial-up Internet access.`
  }
};
