import { useMemo, useState } from 'react';
import { AreaOptions } from '../@types';
import { SidebarFirstTabDispatch, SidebarFirstTabState } from '../context/SidebarFirstTabContext';

export const SidebarFirstTabProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [area, setArea] = useState<AreaOptions>('');
  const [subdropdown, setSubdropdown] = useState<string[]>([]);
  const [dropdownName, setDropdownName] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const [hasChangedSubdropdown, setHasChangedSubdropdown] = useState(false);
  const states = useMemo(() => (
    {
      area,
      subdropdown,
      dropdownName,
      hasChanged,
      hasChangedSubdropdown
    }
  ), [area, dropdownName, subdropdown, hasChanged, hasChangedSubdropdown]);
  const setters = useMemo(() => (
    {
      setArea,
      setSubdropdown,
      setDropdownName,
      setHasChanged,
      setHasChangedSubdropdown
    }), []);
  return (
    <SidebarFirstTabState.Provider value={states}>
      <SidebarFirstTabDispatch.Provider value={setters}>
        {children}
      </SidebarFirstTabDispatch.Provider>
    </SidebarFirstTabState.Provider>
  );
};
