import { useMemo, useState } from 'react';
import { DRIVING_DISTANCE } from '../constants';
import { DrivingDispatch, DrivingState } from '../context/DrivingContext';

export const DrivingnProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [drivingTime, setDrivingTime] = useState<string>(DRIVING_DISTANCE[0].value);
  const [drivingLabel, setDrivingLabel] = useState<string>(DRIVING_DISTANCE[0].label);
  const [selectArea, setSelectArea] = useState<boolean>(true);

  const state = useMemo(() => ({
    drivingTime,
    drivingLabel,
    selectArea,
  }), [drivingTime, drivingLabel, selectArea]);
  const setters = useMemo(() => ({
    setDrivingTime,
    setDrivingLabel,
    setSelectArea,
  }), [setDrivingTime, setDrivingLabel, setSelectArea]);
  return (
    <DrivingState.Provider value={state}>
      <DrivingDispatch.Provider value={setters}>
        {children}
      </DrivingDispatch.Provider>
    </DrivingState.Provider>
  );
};
