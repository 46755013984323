import React, { useState, useMemo } from 'react';
import { StateBbox } from '../@types/components/bbox';
import { MAP_CONSTANTS } from '../constants';
import { BboxState, BboxDispatch } from '../context/BboxContext';

const initialState = {
  stateBbox: {
    stateAbbrev: '',
    data: []
  },
  selectedState: [],
  bbox: MAP_CONSTANTS.US_BOUNDS_FIT,
  tabSelected: 0,
  selectedDivision: '',
  isZooming: false,
  hasChangeNavbar: false
};

export const BboxProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [stateBbox, setStateBbox] = useState<StateBbox>(initialState.stateBbox);
  const [selectedState, setSelectedState] = useState<string[]>(initialState.selectedState);
  const [bbox, setBbox] = useState<number[][]>(initialState.bbox);
  const [tabSelected, setTabSelected] = useState<number>(initialState.tabSelected);
  const [selectedDivision, setSelectedDivision] = useState<string>(initialState.selectedDivision);
  const [isZooming, setIsZooming] = useState<boolean>(initialState.isZooming);
  const [hasChangeNavbar, setHasChangeNavbar] = useState<boolean>(initialState.hasChangeNavbar);
  const states = useMemo(
    () => ({
      stateBbox, selectedState, bbox, tabSelected, selectedDivision, isZooming, hasChangeNavbar
    }),
    [stateBbox, selectedState, bbox, tabSelected, selectedDivision, isZooming, hasChangeNavbar]
  );

  const setters = useMemo(
    () => ({
      setStateBbox, setSelectedState, setBbox, setTabSelected, setSelectedDivision, setIsZooming, setHasChangeNavbar
    }),
    []
  );

  return (
    <BboxState.Provider value={states}>
      <BboxDispatch.Provider value={setters}>{children}</BboxDispatch.Provider>
    </BboxState.Provider>
  );
};
