import { createContext } from 'react';

export const DrivingState = createContext({
  drivingTime: '',
  drivingLabel: '',
  selectArea: true,
} as {
  drivingTime: string,
  drivingLabel: string,
  selectArea: boolean,
});

export const DrivingDispatch = createContext({
  setDrivingTime: () => {
    console.info('DrivingDispatch is not set');
  },
  setDrivingLabel: () => {
    console.info('DrivingDispatch is not set');
  },
  setSelectArea: () => {
    console.info('DrivingDispatch is not set');
  }
} as {
  setDrivingTime: (drivingTime: string) => void,
  setDrivingLabel: (drivingLabel: string) => void,
  setSelectArea: (selectArea: boolean) => void
});
