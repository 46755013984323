import { useMemo, useState } from 'react';
import { graduateType } from '../@types';
import { PrimaryAttributesSetters, PrimaryAttributesState } from '../context/PrimaryAtributesContext';

export const options = [
  'Acute Care Hospital',
  'Ambulatory Care Center',
  'Ambulatory Surgery Center',
  'Clinic',
  'Freestanding Emergency Room',
  'Outpatient Laboratory',
  'Outpatient Rehabilitation Center',
  'Outpatient Surgery Center',
  'Outpatient Treatment Center',
  'Specialty Hospital'
];

export const PrimaryAttributesProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [entities, setEntities] = useState(options.map((option) => ({ checked: false, value: option })));
  const [healthEq, setHealthEq] = useState([0, 100]);
  const [charity, setCharity] = useState([0, 100]);
  const [graduate, setGraduate] = useState<graduateType | null>({ yes: true, no: true });
  const [taxStatus, setTaxStatus] = useState<graduateType | null>({ yes: true, no: true });
  const states = useMemo(() => (
    {
      entities,
      healthEq,
      charity,
      graduate,
      taxStatus
    }
  ), [entities, healthEq, charity, graduate, taxStatus]);
  const setters = useMemo(() => (
    {
      setEntities,
      setHealthEq,
      setCharity,
      setGraduate,
      setTaxStatus
    }), []);

  return (
    <PrimaryAttributesState.Provider value={states}>
      <PrimaryAttributesSetters.Provider value={setters}>
        {children}
      </PrimaryAttributesSetters.Provider>
    </PrimaryAttributesState.Provider>
  );
};
