import { createContext } from 'react';
import { StaffSlider } from '../@types';

export const StaffRetentionState = createContext({
  sliders: []
} as {
  sliders: StaffSlider[]
});

export const StaffRetentionDispatch = createContext({
  setSliders: () => {
    console.info('HospitalCharacteristicsDispatch is not set');
  },
  resetSliders: () => {
    console.info('HospitalCharacteristicsDispatch is not set');
  }
} as {
  setSliders: (sliders: StaffSlider[]) => void,
  resetSliders: () => void
});
