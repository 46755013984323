import React, { useCallback, useMemo, useState } from 'react';
import { EconomySlider, EconomySubSlider } from '../@types';
import { MAP_CONSTANTS, TOOLTIP_INFO } from '../constants';
import { EconomyDispatch, EconomyState } from '../context/EconomyContext';
import { sliderPercentage, sliderthousands } from '../utils';

const options = [
  {
    label: TOOLTIP_INFO.MEDIAN_INCOME.title,
    min: 0,
    max: 300,
    multiplier: 1000,
    format: sliderthousands,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.MEDIAN_INCOME,
    description: TOOLTIP_INFO.MEDIAN_INCOME.description,
    subtitle: TOOLTIP_INFO.MEDIAN_INCOME.subtitle,
  },
  {
    label: TOOLTIP_INFO.INCOME_PER_CAPITA.title,
    min: 0,
    max: 160,
    multiplier: 1000,
    format: sliderthousands,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.INCOME_PER_CAPITA,
    description: TOOLTIP_INFO.INCOME_PER_CAPITA.description,
    subtitle: TOOLTIP_INFO.INCOME_PER_CAPITA.subtitle,
  },
  {
    label: TOOLTIP_INFO.POPULATION_PER_INCOME_GROUP.title,
    min: 0,
    max: 0,
    format: sliderthousands,
    step: 0,
    field: MAP_CONSTANTS.FIELDS_CSH.POPULATION_PER_INCOME_GROUP,
    description: TOOLTIP_INFO.POPULATION_PER_INCOME_GROUP.description,
    subtitle: TOOLTIP_INFO.POPULATION_PER_INCOME_GROUP.subtitle,
    subSliders: [
      {
        label: TOOLTIP_INFO.INCOME_LESS_10000.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_LESS_10000,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_LESS_10000.description
      },
      {
        label: TOOLTIP_INFO.INCOME_10000_14999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_10000_14999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_10000_14999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_15000_19999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_15000_19999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_150000_199999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_20000_24999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_20000_24999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_20000_24999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_25000_29999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_25000_29999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_25000_29999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_30000_34999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_30000_34999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_30000_34999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_35000_39999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_35000_39999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_35000_39999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_40000_44999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_40000_44999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_40000_44999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_45000_49999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_45000_49999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_45000_49999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_50000_59999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_50000_59999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_50000_59999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_60000_74999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_60000_74999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_60000_74999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_75000_99999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_75000_99999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_75000_99999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_100000_124999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_100000_124999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_100000_124999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_125000_149999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_125000_149999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_125000_149999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_150000_199999.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_150000_199999,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_150000_199999.description
      },
      {
        label: TOOLTIP_INFO.INCOME_200000_or_more.title,
        min: 0,
        max: 70,
        multiplier: 1,
        value: [0, 70],
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.INCOME_200000_or_more,
        hasTooltip: true,
        description: TOOLTIP_INFO.INCOME_200000_or_more.description
      }
    ],
  }
];

export const EconomyProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [sliders, setSliders] = useState<EconomySlider[]>(
    options.map((option) => ({
      max: option.max,
      min: option.min,
      value: [option.min, option.max],
      format: option.format,
      field: option.field,
      label: option.label,
      subtitle: option.subtitle,
      multiplier: option.multiplier,
      step: option.step,
      description: option.description,
      subSliders: option.subSliders?.map((element: EconomySubSlider) => ({
        value: [element.min, element.max],
        label: element.label,
        field: element.field,
        format: element.format,
        multiplier: option.multiplier,
        max: element.max,
        min: element.min,
        step: element.step,
        hasTooltip: element.hasTooltip,
        description: element.description
      })) || [],
    }))
  );
  const resetSliders = useCallback(() => {
    setSliders(
      options.map((option) => ({
        max: option.max,
        min: option.min,
        value: [option.min, option.max],
        format: option.format,
        multiplier: option.multiplier,
        field: option.field,
        label: option.label,
        subtitle: option.subtitle,
        step: option.step,
        description: option.description,
        subSliders: option.subSliders?.map((element: EconomySubSlider) => ({
          value: [element.min, element.max],
          label: element.label,
          field: element.field,
          format: element.format,
          multiplier: option.multiplier,
          max: element.max,
          min: element.min,
          step: element.step,
          hasTooltip: element.hasTooltip,
          description: element.description
        })) || [],
      }))
    );
  }, [setSliders]);
  const state = useMemo(() => ({
    sliders
  }), [sliders]);
  const setters = useMemo(() => ({
    setSliders, resetSliders
  }), [setSliders, resetSliders]);

  return (
    <EconomyState.Provider value={state}>
      <EconomyDispatch.Provider value={setters}>
        {children}
      </EconomyDispatch.Provider>
    </EconomyState.Provider>
  );
};
