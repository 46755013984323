import React, { useCallback, useMemo, useState } from 'react';
import { PopulationSlider, PopulationSubSlider } from '../@types';
import { MAP_CONSTANTS, TOOLTIP_INFO } from '../constants';
import { PopulationDispatch, PopulationState } from '../context/PopulationContext';
import { sliderDefault, sliderPercentage } from '../utils';

const options = [
  {
    label: TOOLTIP_INFO.GENDER.title,
    min: 0,
    max: 60,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.GENDER,
    description: TOOLTIP_INFO.GENDER.description,
    subtitle: TOOLTIP_INFO.GENDER.subtitle,
    subSliders: [
      {
        label: TOOLTIP_INFO.FEMALE.title,
        min: 0,
        max: 100,
        value: [0, 100],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.FEMALE_POP,
        hasTooltip: false
      },
      {
        label: TOOLTIP_INFO.MALE.title,
        min: 0,
        max: 100,
        value: [0, 100],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.MALE_POP,
        hasTooltip: false
      }
    ]
  },
  {
    label: TOOLTIP_INFO.MEDIAN_AGE.title,
    min: 0,
    max: 70,
    format: sliderDefault,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.MEDIAN_AGE,
    description: TOOLTIP_INFO.MEDIAN_AGE.description,
    subtitle: TOOLTIP_INFO.MEDIAN_AGE.subtitle,
  },
  {
    label: TOOLTIP_INFO.POPULATION_UNDER_5.title,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.UNDER_5_POP,
    description: TOOLTIP_INFO.POPULATION_UNDER_5.description,
    subtitle: TOOLTIP_INFO.POPULATION_UNDER_5.subtitle,
    subSliders: [
      {
        label: TOOLTIP_INFO.FEMALE_UNDER_5.title,
        min: 0,
        max: 5,
        value: [0, 5],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.FEMALE_UNDER_5_POP,
        hasTooltip: false
      },
      {
        label: TOOLTIP_INFO.MALE_UNDER_5.title,
        min: 0,
        max: 5,
        value: [0, 5],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.MALE_UNDER_5_POP,
        hasTooltip: false
      }
    ]
  },
  {
    label: TOOLTIP_INFO.POPULATION_ABOVE_16.title,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.POP_16_OVER,
    description: TOOLTIP_INFO.POPULATION_ABOVE_16.description,
    subtitle: TOOLTIP_INFO.POPULATION_ABOVE_16.subtitle,
  },
  {
    label: TOOLTIP_INFO.POPULATION_RACE.title,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.POP_BY_RACE,
    description: TOOLTIP_INFO.POPULATION_RACE.description,
    subtitle: TOOLTIP_INFO.POPULATION_RACE.subtitle,
    subSliders: [
      {
        label: TOOLTIP_INFO.WHITE.title,
        min: 0,
        max: 100,
        value: [0, 100],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.WHITE_POP,
        hasTooltip: false
      },
      {
        label: TOOLTIP_INFO.BLACK.title,
        min: 0,
        max: 100,
        value: [0, 100],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.BLACK_POP,
        hasTooltip: false
      },
      {
        label: TOOLTIP_INFO.HISPANIC.title,
        min: 0,
        max: 100,
        value: [0, 100],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.HISPANIC_POP,
        hasTooltip: false
      },
      {
        label: TOOLTIP_INFO.ASIAN.title,
        min: 0,
        max: 100,
        value: [0, 100],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.ASIAN_POP,
        hasTooltip: false
      },
      {
        label: TOOLTIP_INFO.AMERINDIAN.title,
        min: 0,
        max: 100,
        value: [0, 100],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.AMERINDIAN_POP,
        hasTooltip: false
      },
      {
        label: TOOLTIP_INFO.OTHER.title,
        min: 0,
        max: 100,
        value: [0, 100],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.OTHER_RACE_POP,
        hasTooltip: false
      }
    ]
  },
  {
    label: TOOLTIP_INFO.POPULATION_BY_EDUCATION_ATTAINMENT.title,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.POPULATION_BY_EDUCATION_ATTAINMENT,
    description: TOOLTIP_INFO.POPULATION_BY_EDUCATION_ATTAINMENT.description,
    subtitle: TOOLTIP_INFO.POPULATION_BY_EDUCATION_ATTAINMENT.subtitle,
    subSliders: [
      {
        label: TOOLTIP_INFO.POPULATION_EDUCATION_HIGH_SCHOOL.title,
        min: 0,
        max: 50,
        value: [0, 50],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.HIGH_SCHOOL_DIPLOMA,
        hasTooltip: true,
        description: TOOLTIP_INFO.POPULATION_EDUCATION_HIGH_SCHOOL.description
      },
      {
        label: TOOLTIP_INFO.POPULATION_EDUCATION_ASSOCIATES.title,
        min: 0,
        max: 50,
        value: [0, 50],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.ASSOCIATES_DEGREE,
        hasTooltip: true,
        description: TOOLTIP_INFO.POPULATION_EDUCATION_ASSOCIATES.description
      },
      {
        label: TOOLTIP_INFO.POPULATION_EDUCATION_BACHELORS.title,
        min: 0,
        max: 50,
        value: [0, 50],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.BACHELORS_DEGREE,
        hasTooltip: true,
        description: TOOLTIP_INFO.POPULATION_EDUCATION_BACHELORS.description
      },
      {
        label: TOOLTIP_INFO.POPULATION_EDUCATION_MASTERS.title,
        min: 0,
        max: 50,
        value: [0, 50],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.MASTERS_DEGREE,
        hasTooltip: true,
        description: TOOLTIP_INFO.POPULATION_EDUCATION_MASTERS.description
      },
      {
        label: TOOLTIP_INFO.POPULATION_EDUCATION_DOCTORATE.title,
        min: 0,
        max: 50,
        value: [0, 50],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.DOCTORATE_DEGREE,
        hasTooltip: true,
        description: TOOLTIP_INFO.POPULATION_EDUCATION_DOCTORATE.description
      }
    ]
  }
];

export const PopulationProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [sliders, setSliders] = useState<PopulationSlider[]>(
    options.map((option) => ({
      max: option.max,
      min: option.min,
      value: [option.min, option.max],
      format: option.format,
      multiplier: option.multiplier,
      field: option.field,
      label: option.label,
      subtitle: option.subtitle,
      step: option.step,
      description: option.description,
      subSliders: option.subSliders?.map((element: PopulationSubSlider) => ({
        value: [element.min, element.max],
        label: element.label,
        field: element.field,
        format: element.format,
        multiplier: element.multiplier,
        max: element.max,
        min: element.min,
        step: element.step,
        hasTooltip: element.hasTooltip,
        description: element.description
      })) || [],
    }))
  );
  const resetSliders = useCallback(() => {
    setSliders(
      options.map((option) => ({
        max: option.max,
        min: option.min,
        value: [option.min, option.max],
        format: option.format,
        multiplier: option.multiplier,
        field: option.field,
        label: option.label,
        subtitle: option.subtitle,
        step: option.step,
        description: option.description,
        subSliders: option.subSliders?.map((element: PopulationSubSlider) => ({
          value: [element.min, element.max],
          label: element.label,
          field: element.field,
          format: element.format,
          multiplier: element.multiplier,
          max: element.max,
          min: element.min,
          step: element.step,
          hasTooltip: element.hasTooltip,
          description: element.description
        })) || [],
      }))
    );
  }, [setSliders]);
  const state = useMemo(() => ({
    sliders
  }), [sliders]);
  const setters = useMemo(() => ({
    setSliders, resetSliders
  }), [setSliders, resetSliders]);

  return (
    <PopulationState.Provider value={state}>
      <PopulationDispatch.Provider value={setters}>
        {children}
      </PopulationDispatch.Provider>
    </PopulationState.Provider>
  );
};
