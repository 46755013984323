import { useCallback, useContext } from 'react';
import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { ENDPOINTS } from '../constants/url';
import { MAP_LAYERS } from '../constants/mapLayers';
import { GenericAny } from '../@types';
import { BboxDispatch } from '../context/BboxContext';
import { getBboxFilteredVariables } from '../@types/components/bbox';

const MAP_TILES = 'map-layer';

const getTiles = async (table?: string) => {
  const { data } = await axios.get(ENDPOINTS.TILES(table));
  return data.data;
};

const getBbox = async (stateAbbrev: string) => {
  const { data } = await axios.get(ENDPOINTS.GET_BBOX_STATE(stateAbbrev));
  const bboxCoordinates = data.data.length ? [data?.data[0][0], data?.data[0][2]] : [];
  return { stateAbbrev, data: bboxCoordinates };
};

const getBboxFiltered = async ({ column, values }: getBboxFilteredVariables) => {
  const { data } = await axios.post(ENDPOINTS.GET_BBOX_FILTERED, {
    column,
    values
  });
  if (data.sucess) {
    const geojson = data.data.length ? JSON.parse(data.data[0]?.bbox) : {};
    const bboxCoordinates = [geojson.coordinates[0][0], geojson.coordinates[0][2]];
    return { stateAbbrev: '', data: bboxCoordinates };
  }
  return { stateAbbrev: '', data: [] };
};

export const useMapRequest = (table?: string) => {
  const { setStateBbox } = useContext(BboxDispatch);
  const { data: tiles } = useQuery(
    [`${MAP_TILES}-${table}`, table],
    () => getTiles(table ?? MAP_LAYERS.CSH_FACILITIES_TABLE)
  );

  const { mutate } = useMutation(getBbox, {
    onSuccess: (data) => {
      setStateBbox(data);
    },
    onError: (err: GenericAny) => {
      console.error('err', err);
    }
  });

  const getBboxOfState = useCallback((stateAbbrev: string) => {
    mutate(stateAbbrev);
  }, [mutate]);

  const { mutate: mutateFilter } = useMutation(getBboxFiltered, {
    onSuccess: (data) => {
      setStateBbox(data);
    },
    onError: (err: GenericAny) => {
      console.error('err', err);
    }
  });

  const getBboxFilteredPoints = useCallback((column: string, values: string[]) => {
    mutateFilter({ column, values });
  }, [mutateFilter]);

  return {
    getBboxFilteredPoints,
    getBboxOfState,
    tiles,
    setStateBbox
  };
};
