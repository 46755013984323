import { Tooltip } from '@mui/material';
import {
  useContext,
  useEffect,
  forwardRef
} from 'react';
import { StaffSlider, GenericAny } from '../@types';
import { TYPES } from '../constants/context';
import { FilterContextDispatch } from '../context/FilterContext';
import { StaffRetentionDispatch, StaffRetentionState } from '../context/StaffRetentionContext';
import { TooltipContent } from './ToolipContent';
import VizonomySlider from './VizonomySlider';

export const StaffRetention = forwardRef<HTMLDivElement, GenericAny>((props, ref) => {
  const { dispatch } = useContext(FilterContextDispatch);

  const { sliders } = useContext(StaffRetentionState);
  const { setSliders } = useContext(StaffRetentionDispatch);

  useEffect(() => {
    const filters = sliders.filter((slider) => {
      if (slider.value[0] === 0 && slider.value[1] === slider.max) {
        return false;
      }
      return true;
    }).map((slider) => {
      const [min, max] = slider.value;
      return {
        field: slider.field,
        value: ['all', ['>=', ['get', slider.field], min],
          ['<=', ['get', slider.field], max]]
      };
    });
    filters.forEach((filter) => {
      dispatch({ type: TYPES.REMOVE_AND_REPLACE, payload: filter });
    });
  }, [sliders, dispatch]);

  return (
    <div ref={ref} id="staff">
      <div className="pattributebar">
        <p className="txtl">Staff Retention</p>
      </div>
      {
        sliders.map((slider: StaffSlider, sliderIndex: number) => (
          <div key={slider.field}>
            <div className="attributedetail">
              <div className="btit1">
                <div className="title">
                  <span className="txt">{slider.label}</span>
                  <Tooltip
                    title={(
                      <TooltipContent
                        title={slider.label}
                        description={slider.description}
                      />
                    )}
                    TransitionProps={{ timeout: 0 }}
                  >
                    <button type="button" className="light">
                      <span className="icinfo" />
                    </button>
                  </Tooltip>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="btit2 txt">
                {slider.subtitle}
              </div>
            </div>
            <div className="optionchk">
              <VizonomySlider
                max={slider.max}
                format={slider.format}
                value={slider.value}
                step={slider.step}
                setValue={(value: number[]) => {
                  const newSliders = sliders.map((s: StaffSlider, i: number) => {
                    if (i === sliderIndex) {
                      return {
                        ...s,
                        value
                      };
                    }
                    return s;
                  });
                  setSliders(newSliders);
                }}
              />
            </div>
          </div>
        ))
      }
    </div>
  );
});
