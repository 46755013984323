import { createContext } from 'react';
import { AreaOptions } from '../@types';

export const SidebarFirstTabState = createContext({
  area: '',
  subdropdown: [],
  dropdownName: '',
  hasChanged: false,
  hasChangedSubdropdown: false
} as {
  area: AreaOptions,
  subdropdown: string[],
  dropdownName: string,
  hasChanged: boolean,
  hasChangedSubdropdown: boolean
});

export const SidebarFirstTabDispatch = createContext({
  setArea: () => {
    console.info('SidebarFirstTabDispatch is not set');
  },
  setSubdropdown: () => {
    console.info('SidebarFirstTabDispatch is not set');
  },
  setDropdownName: () => {
    console.info('SidebarFirstTabDispatch is not set');
  },
  setHasChanged: () => {
    console.info('SidebarFirstTabDispatch is not set');
  },
  setHasChangedSubdropdown: () => {
    console.info('SidebarFirstTabDispatch is not set');
  }
} as {
  setArea: (area: AreaOptions) => void,
  setSubdropdown: (subdropdown: string[]) => void,
  setDropdownName: (dropdownName: string) => void,
  setHasChanged: (hasChanged: boolean) => void,
  setHasChangedSubdropdown: (hasChangedSubdropdown: boolean) => void
});
