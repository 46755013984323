import { createContext } from 'react';
import { LanguageSlider } from '../@types';

export const LanguageState = createContext({
  sliders: []
} as {
  sliders: LanguageSlider[]
});

export const LanguageDispatch = createContext({
  setSliders: () => {
    console.info('LanguageDispatch is not set');
  },
  resetSliders: () => {
    console.info('LanguageDispatch is not set');
  }
} as {
  setSliders: (sliders: LanguageSlider[]) => void,
  resetSliders: () => void
});
