import React, { useCallback, useMemo, useState } from 'react';
import { FinantialHealthSlider } from '../@types';
import { MAP_CONSTANTS, TOOLTIP_INFO } from '../constants';
import { FinantialHealthDispatch, FinantialHealthState } from '../context/FinantialHealthContext';
import {
  sliderDefault, sliderPercentage, sliderPercentageOneDecimal, sliderReadableNumber
} from '../utils';

const options = [
  {
    label: 'EBITDA (after special charges)',
    min: -10,
    max: 20,
    format: sliderPercentage,
    step: 0.01,
    field: MAP_CONSTANTS.FIELDS_CSH.EMPLOYEE_ENGAGEMENT,
    description: TOOLTIP_INFO.EMPLOYEE_ENGAGEMENT.description
  },
  {
    label: 'Days Cash',
    min: 0,
    max: 300,
    format: sliderDefault,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.NEW_HIRE_ADJUSTED_RETENTION_RATE,
    description: TOOLTIP_INFO.NEW_HIRE_ADJUSTED_RETENTION_RATE.description
  },
  {
    label: 'NPSR % Growth',
    min: -10,
    max: 20,
    format: sliderPercentageOneDecimal,
    step: 0.01,
    field: MAP_CONSTANTS.FIELDS_CSH.NPSR_GROWTH,
    description: TOOLTIP_INFO.NPSR_GROWTH.description
  },
  {
    label: 'Revenue from Diversified (Non-Acute) Sources',
    min: 0,
    max: 2000,
    format: sliderReadableNumber,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.REVENUE_FROM_DIVERSIFIED_NON_ACUTE_SOURCES,
    description: TOOLTIP_INFO.REVENUE_FROM_DIVERSIFIED_NON_ACUTE_SOURCES.description
  },
  {
    label: 'Philanthropy',
    min: 0,
    max: 100,
    format: sliderReadableNumber,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.PHILANTHROPY,
    description: TOOLTIP_INFO.PHILANTHROPY.description
  }
];

export const FinantialHealthProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [sliders, setSliders] = useState<FinantialHealthSlider[]>(
    options.map((option) => ({
      max: option.max,
      min: option.min,
      value: [option.min, option.max],
      format: option.format,
      field: option.field,
      label: option.label,
      subtitle: 'Select or input score range.',
      step: option.step,
      description: option.description
    }))
  );
  const resetSliders = useCallback(() => {
    setSliders(
      options.map((option) => ({
        max: option.max,
        min: option.min,
        value: [option.min, option.max],
        format: option.format,
        field: option.field,
        label: option.label,
        subtitle: 'Select or input score range.',
        step: option.step,
        description: ''
      }))
    );
  }, [setSliders]);
  const state = useMemo(() => ({
    sliders
  }), [sliders]);
  const setters = useMemo(() => ({
    setSliders, resetSliders
  }), [setSliders, resetSliders]);

  return (
    <FinantialHealthState.Provider value={state}>
      <FinantialHealthDispatch.Provider value={setters}>
        {children}
      </FinantialHealthDispatch.Provider>
    </FinantialHealthState.Provider>
  );
};
