import { createContext } from 'react';
import { EconomySlider } from '../@types';

export const EconomyState = createContext({
  sliders: []
} as {
  sliders: EconomySlider[]
});

export const EconomyDispatch = createContext({
  setSliders: () => {
    console.info('EconomyDispatch is not set');
  },
  resetSliders: () => {
    console.info('EconomyDispatch is not set');
  }
} as {
  setSliders: (sliders: EconomySlider[]) => void,
  resetSliders: () => void
});
