import { createContext } from 'react';
import { StateBbox } from '../@types/components/bbox';

export const BboxState = createContext({
  stateBbox: {},
  selectedState: [],
  bbox: [[]],
  tabSelected: 0,
  selectedDivision: '',
  isZooming: false,
  hasChangeNavbar: false
} as unknown as {
  stateBbox: StateBbox,
  selectedState: string[],
  bbox: number[][],
  tabSelected: number,
  selectedDivision: string,
  isZooming: boolean,
  hasChangeNavbar: boolean
});

export const BboxDispatch = createContext({
  setStateBbox: () => {
    console.info('setStateBbox is not set');
  },
  setSelectedState: () => {
    console.info('setStateBbox is not set');
  },
  setBbox: () => {
    console.info('setBbox is not set');
  },
  setTabSelected: () => {
    console.info('setTabSelected is not set');
  },
  setSelectedDivision: () => {
    console.info('setSelectedDivision is not set');
  },
  setIsZooming: () => {
    console.info('setIsZooming is not set');
  },
  setHasChangeNavbar: () => {
    console.info('setHasChangeNavbar is not set');
  }
} as {
  setStateBbox: (stateBbox: StateBbox) => void,
  setSelectedState: (selectedState: string[]) => void,
  setBbox: (bbox: number[][]) => void,
  setTabSelected: (tabSelected: number) => void,
  setSelectedDivision: (division: string) => void,
  setIsZooming: (isZooming: boolean) => void,
  setHasChangeNavbar: (hasChangeNavbar: boolean) => void
});
