import { useContext } from 'react';
import classNames from 'classnames';
import { FilterContextDispatch, FilterContextState } from '../context/FilterContext';
import { TYPES } from '../constants/context';
import { ICLayerProps, TOOLTIP_INFO } from '../constants';

export const IcLayerContent = ({ label }: ICLayerProps) => {
  const { state } = useContext(FilterContextState);
  const { currentCensusStyle } = state;
  const { dispatch } = useContext(FilterContextDispatch);

  const setCurrentCensusStyle = (currentCensus: string) => {
    if (currentCensusStyle === currentCensus) {
      dispatch({ type: TYPES.SET_CURRENT_CENSUS, payload: TOOLTIP_INFO.DEFAULT.title });
    } else {
      dispatch({ type: TYPES.SET_CURRENT_CENSUS, payload: currentCensus });
    }
    dispatch({ type: TYPES.SET_GATE_SIDEBAR, payload: true });
  };

  return (
    <button type="button" className="light" onClick={() => setCurrentCensusStyle(label)}>
      <div
        className={
          classNames({
            iclayer: currentCensusStyle !== label,
            'iclayer-active': currentCensusStyle === label
          })
        }
      />
    </button>
  );
};
