import { useState } from 'react';
import { Dashboard } from './Dashboard';
import { MapContainer } from './MapContainer';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';

export const TabManager = ({ tab, setTab }: { tab: number, setTab: (tab: number) => void }) => {
  const [active, setActive] = useState(true);
  let Component = (
    <>
      <MapContainer />
      <Sidebar setTab={setTab} setActive={setActive} />
    </>
  );
  if (tab) {
    Component = (<Dashboard />);
  }
  return (
    <div className="container">
      <Navbar setTab={setTab} setActive={setActive} active={active} />
      <div
        className="body"
        style={{
          paddingRight: tab && 30
        }}
      >
        { Component }
      </div>
    </div>
  );
};
