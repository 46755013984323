import axios from 'axios';
import { useQueries } from 'react-query';
import { GenericAny } from '../@types';
import { DISTANCE_TABLES, RADIUS_TABLES, ENDPOINTS } from '../constants';

const getTiles = async (table?: string, key?: string) => {
  const { data } = await axios.get(ENDPOINTS.TILES(table));
  return { data: data.data, table, key };
};

export const useTilesRequest = () => {
  const allQueries: GenericAny = [];
  Object.keys(DISTANCE_TABLES).forEach((key) => {
    const table = DISTANCE_TABLES[key];
    if (table) {
      allQueries.push(
        { queryKey: [`${table}`, table], queryFn: () => getTiles(table, key) }
      );
    }
  });
  Object.keys(RADIUS_TABLES).forEach((key) => {
    const table = RADIUS_TABLES[key];
    if (table) {
      allQueries.push(
        { queryKey: [`${table}`, table], queryFn: () => getTiles(table, key) }
      );
    }
  });
  const allTiles = useQueries(allQueries)
    ?.filter((element) => element.status === 'success')?.map((element) => element.data);
  return {
    allTiles
  };
};
