import {
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';
import classNames from 'classnames';
import { FinantialHealth } from './FinantialHealth';
import { HospitalCharacteristics } from './HospitalCharacteristics';
import { PatientServices } from './PatientServices';
import { PrimaryAttributes } from './PrimaryAttributes';
import { StaffRetention } from './StaffRetention';
import { useArrayRef } from '../hooks/useArrayref';
import { TABS_SECOND, ResetProps, MAP_CONSTANTS } from '../constants';
import { PrimaryAttributesSetters } from '../context/PrimaryAtributesContext';
import {
  HospitalCharacteristicsDispatch,
  HospitalCharacteristicsState
} from '../context/HospitalCharacteristicsContext';
import { PatientServicesDispatch, PatientServicesState } from '../context/PatientServicesContext';
import { StaffRetentionDispatch, StaffRetentionState } from '../context/StaffRetentionContext';
import { FinantialHealthDispatch, FinantialHealthState } from '../context/FinantialHealthContext';
import { FilterContextDispatch } from '../context/FilterContext';
import { BboxDispatch } from '../context/BboxContext';
import { TYPES } from '../constants/context';
import { AboutUs } from './AboutUs';
import { useScrollSelect } from '../hooks/useScrollSelect';
import { options } from '../provider/PrimaryAttributesProvider';

export const SidebarSecondTab = ({ needsReset, setNeedsReset }: ResetProps) => {
  const [selectedOpt, setSelectedOpt] = useState(0);
  const [refs, addRef] = useArrayRef();
  const [removeFilters, setRemoveFilters] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const {
    setEntities, setHealthEq, setCharity, setGraduate, setTaxStatus
  } = useContext(PrimaryAttributesSetters);
  const {
    dispatch
  } = useContext(FilterContextDispatch);
  const {
    setIsZooming
  } = useContext(BboxDispatch);
  const { sliders: hospitalCharacteristicsSlider } = useContext(HospitalCharacteristicsState);
  const { sliders: patientServicesSlider } = useContext(PatientServicesState);
  const { sliders: staffRetentionSlider } = useContext(StaffRetentionState);
  const { sliders: finantialHealthSlider } = useContext(FinantialHealthState);
  const { resetSliders } = useContext(HospitalCharacteristicsDispatch);
  const { resetSliders: resetSlidersPatient } = useContext(PatientServicesDispatch);
  const { resetSliders: resetSlidersStaff } = useContext(StaffRetentionDispatch);
  const { resetSliders: resetSlidersFinantial } = useContext(FinantialHealthDispatch);

  useScrollSelect(refs, scrollRef, setSelectedOpt);

  useEffect(() => {
    dispatch({ type: TYPES.SET_SHOW_STATES, payload: false });
    dispatch({ type: TYPES.SET_SHOW_DIVISIONS, payload: false });
  }, [dispatch]);

  useEffect(() => {
    setIsZooming(true);
  }, [setIsZooming]);

  useEffect(() => {
    if (needsReset) {
      setEntities(options.map((option) => ({ checked: false, value: option })));
      setHealthEq([0, 100]);
      setCharity([0, 100]);
      setGraduate({ yes: true, no: true });
      setTaxStatus({ yes: true, no: true });
      resetSliders();
      resetSlidersPatient();
      resetSlidersStaff();
      resetSlidersFinantial();
      setNeedsReset(false);
      setRemoveFilters(true);
    }
    if (removeFilters) {
      setRemoveFilters(!removeFilters);
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.ENTITY_TYPE });
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.GME });
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.HEALTH_EQUALITY });
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.CHARITY_MEDICAID_SERVICE });
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.TAX_STATUS });
      hospitalCharacteristicsSlider.forEach((slider) => {
        dispatch({ type: TYPES.REMOVE, payload: slider.field });
      });
      patientServicesSlider.forEach((slider) => {
        dispatch({ type: TYPES.REMOVE, payload: slider.field });
      });
      staffRetentionSlider.forEach((slider) => {
        dispatch({ type: TYPES.REMOVE, payload: slider.field });
      });
      finantialHealthSlider.forEach((slider) => {
        dispatch({ type: TYPES.REMOVE, payload: slider.field });
      });
    }
  }, [
    removeFilters,
    needsReset,
    setNeedsReset,
    setEntities,
    setHealthEq,
    setCharity,
    setGraduate,
    setTaxStatus,
    resetSliders,
    resetSlidersPatient,
    resetSlidersStaff,
    resetSlidersFinantial,
    dispatch,
    hospitalCharacteristicsSlider,
    patientServicesSlider,
    staffRetentionSlider,
    finantialHealthSlider
  ]);

  return (
    <>
      <div className="attributeoptions">
        <a href="#aboutus">
          <button
            type="button"
            className={classNames('light btn6', { active: selectedOpt === TABS_SECOND.INFORMATION })}
            onClick={() => setSelectedOpt(0)}
          >
            <span className="icg106" />
          </button>
        </a>
        <a href="#primary">
          <button
            type="button"
            className={classNames('light btn1', { active: selectedOpt === TABS_SECOND.PRIMARY })}
            onClick={() => setSelectedOpt(1)}
          >
            <span className="icg101" />
          </button>
        </a>
        <a href="#hospital">
          <button
            type="button"
            className={classNames('light btn2', { active: selectedOpt === TABS_SECOND.HOSPITAL })}
            onClick={() => setSelectedOpt(2)}
          >
            <span className="icg102" />
          </button>
        </a>
        <a href="#patients">
          <button
            type="button"
            className={classNames('light btn3', { active: selectedOpt === TABS_SECOND.PATIENT })}
            onClick={() => setSelectedOpt(3)}
          >
            <span className="icg103" />
          </button>
        </a>
        <a href="#staff">
          <button
            type="button"
            className={classNames('light btn4', { active: selectedOpt === TABS_SECOND.STAFF })}
            onClick={() => setSelectedOpt(4)}
          >
            <span className="icg104" />
          </button>
        </a>
        <a href="#finantial">
          <button
            type="button"
            className={classNames('light btn5', { active: selectedOpt === TABS_SECOND.FINANTIAL })}
            onClick={() => setSelectedOpt(5)}
          >
            <span className="icg105" />
          </button>
        </a>
      </div>
      <div id="awscroll2" ref={scrollRef}>
        <AboutUs ref={addRef} />
        <PrimaryAttributes ref={addRef} />
        <HospitalCharacteristics ref={addRef} />
        <PatientServices ref={addRef} />
        <StaffRetention ref={addRef} />
        <FinantialHealth ref={addRef} />
      </div>
    </>
  );
};
