import classNames from 'classnames';
import { MAP_CONSTANTS, MAP_LAYERS } from '../constants';

export const Popup = ({
  operatingDbaName,
  entityType,
  city,
  state,
  contentArray,
  typePopup
}: PopupData) => (
  <div className={classNames('popup', { 'popup-main-wide': typePopup === MAP_LAYERS.CSH_PUBLICA_TABLE })}>
    <div className="popup-header">
      <div className="title txt">
        {operatingDbaName}
      </div>
      <div className="sub-title first-letter-capitalize txts">
        {entityType}
        {' '}
        <span className="mdot" />
        {` ${city}, `}
        <span>{state}</span>
      </div>
    </div>
    <div
      className={
        classNames(
          'popup-content',
          {
            'popup-content-wide': typePopup === MAP_LAYERS.CSH_PUBLICA_TABLE
          }
        )
      }
    >
      <div className="row">
        {contentArray.map((content, index) => (
          <div
            className={
              classNames(
                'space',
                {
                  'col-6':
                    typePopup === MAP_LAYERS.CSH_FACILITIES_TABLE
                    || (typePopup === MAP_LAYERS.CSH_PUBLICA_TABLE && index % 2 !== 0),
                  'col-5': typePopup === MAP_LAYERS.CSH_PUBLICA_TABLE && index % 2 === 0
                }
              )
            }
            key={content.title}
          >
            <div className="content-title txts">{content.title}</div>
            {content.title === MAP_CONSTANTS.POPUP_CHEA_TITLES.URL && (
              <div className="content-content txts">
                <a href={content.content} target="_blank" rel="noopener noreferrer">{content.content}</a>
              </div>
            )}
            {content.title !== MAP_CONSTANTS.POPUP_CHEA_TITLES.URL && (
              <div className="content-content txts">{content.content}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
);
