import classNames from 'classnames';
import { GenericAny } from '../@types';
import { MAP_LAYERS, MAP_CONSTANTS } from '../constants';

const money = new Intl.NumberFormat(
  'en-US',
  {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }
);
const percentage = new Intl.NumberFormat(
  'en-US',
  {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }
);

export const PopupCensus = ({
  operatingDbaName,
  entityType,
  contentArray,
  typePopup
}: PopupData) => {
  const getTitle = (content: GenericAny) => {
    let title = '';
    let value = 0;
    if (content.title !== MAP_CONSTANTS.POPUP_CENSUS_TITLES.MEDIAN_INCOME) {
      if (!Number.isNaN(content.content)) {
        value = content.content <= 1 ? content.content * 100 : content.content;
      }
      title = `${content.title}: ${percentage.format(value)}%`;
    } else {
      if (!Number.isNaN(content.content)) {
        value = content.content;
      }
      title = `${content.title}: ${money.format(value)}`;
    }
    return title;
  };
  return (
    <div className={classNames('popup', { 'popup-main-wide': typePopup === MAP_LAYERS.CSH_PUBLICA_TABLE })}>
      <div className="popup-header-census">
        <div className="title txt">
          {operatingDbaName}
        </div>
        <div className="sub-title first-letter-capitalize txts">
          {entityType}
        </div>
      </div>
      <div
        className="popup-content"
      >
        <div className="row-census">
          <div className="content-title content-title-noupper txts">Population</div>
          {contentArray.map((content) => (
            <div
              className="space"
              key={content.title}
            >
              <div className="content-content txts">
                {getTitle(content)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
