import {
  Checkbox, FormControlLabel, FormGroup, Tooltip
} from '@mui/material';
import {
  useContext,
  useEffect,
  forwardRef
} from 'react';
import { MAP_CONSTANTS, TAX_YES, TOOLTIP_INFO } from '../constants';
import { TYPES } from '../constants/context';
import { FilterContextDispatch } from '../context/FilterContext';
import { PrimaryAttributesSetters, PrimaryAttributesState } from '../context/PrimaryAtributesContext';
import { sliderPercentage } from '../utils';
import VizonomySlider from './VizonomySlider';
import { GenericAny } from '../@types';
import { TooltipContent } from './ToolipContent';

export const PrimaryAttributes = forwardRef<HTMLDivElement, GenericAny>((props, ref) => {
  const {
    entities, healthEq, charity, graduate, taxStatus
  } = useContext(PrimaryAttributesState);
  const {
    setEntities, setHealthEq, setCharity, setGraduate, setTaxStatus
  } = useContext(PrimaryAttributesSetters);
  const { dispatch } = useContext(FilterContextDispatch);

  useEffect(() => {
    const areTrue = entities.filter((c) => c.checked).map((c) => c.value.toUpperCase());
    if (areTrue.length > 0) {
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.ENTITY_TYPE,
          value: ['in', ['get', MAP_CONSTANTS.FIELDS_CSH.ENTITY_TYPE], ['literal', areTrue]]
        }
      });
    } else {
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.ENTITY_TYPE });
    }
  }, [entities, dispatch]);

  useEffect(() => {
    dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.GME });
    if (graduate?.yes && !graduate?.no) {
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.GME,
          value: ['==', ['get', MAP_CONSTANTS.FIELDS_CSH.GME], graduate?.yes]
        }
      });
    }
    if (graduate?.no && !graduate?.yes) {
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.GME,
          value: ['==', ['get', MAP_CONSTANTS.FIELDS_CSH.GME], graduate?.no]
        }
      });
    }
    if (graduate === null || !(graduate?.yes && graduate?.no)) {
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.GME });
    }
    if (!graduate?.yes && !graduate?.no) {
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.GME,
          value: ['==', ['get', MAP_CONSTANTS.FIELDS_CSH.GME], '']
        }
      });
    }
  }, [graduate, dispatch]);

  useEffect(() => {
    if (healthEq[0] === 0 && healthEq[1] === 100) {
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.HEALTH_EQUALITY });
    } else {
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.HEALTH_EQUALITY,
          value: [
            'all',
            ['>=', ['get', MAP_CONSTANTS.FIELDS_CSH.HEALTH_EQUALITY], healthEq[0]],
            ['<=', ['get', MAP_CONSTANTS.FIELDS_CSH.HEALTH_EQUALITY], healthEq[1]]
          ]
        }
      });
    }
  }, [healthEq, dispatch]);

  useEffect(() => {
    if (charity[0] === 0 && charity[1] === 100) {
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.CHARITY_MEDICAID_SERVICE });
    } else {
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.CHARITY_MEDICAID_SERVICE,
          value: [
            'all',
            ['>=', ['get', MAP_CONSTANTS.FIELDS_CSH.CHARITY_MEDICAID_SERVICE], charity[0]],
            ['<=', ['get', MAP_CONSTANTS.FIELDS_CSH.CHARITY_MEDICAID_SERVICE], charity[1]]
          ]
        }
      });
    }
  }, [charity, dispatch]);

  useEffect(() => {
    if (taxStatus?.yes && !taxStatus?.no) {
      const taxOperator = '==';
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.TAX_STATUS,
          value: [taxOperator, ['get', MAP_CONSTANTS.FIELDS_CSH.TAX_STATUS], TAX_YES]
        }
      });
    }
    if (taxStatus?.no && !taxStatus?.yes) {
      const taxOperator = '!=';
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.TAX_STATUS,
          value: [taxOperator, ['get', MAP_CONSTANTS.FIELDS_CSH.TAX_STATUS], TAX_YES]
        }
      });
    }
    if (taxStatus?.no && taxStatus?.yes) {
      dispatch({ type: TYPES.REMOVE, payload: MAP_CONSTANTS.FIELDS_CSH.TAX_STATUS });
    }
    if (!taxStatus?.no && !taxStatus?.yes) {
      const taxOperator = '==';
      dispatch({
        type: TYPES.REMOVE_AND_REPLACE,
        payload: {
          field: MAP_CONSTANTS.FIELDS_CSH.TAX_STATUS,
          value: [taxOperator, ['get', MAP_CONSTANTS.FIELDS_CSH.TAX_STATUS], null]
        }
      });
    }
  }, [taxStatus, dispatch]);

  return (
    <div ref={ref} id="primary">
      <div className="pattributebar">
        <p className="txtl">Primary Attributes</p>
      </div>
      <div className="attributedetail">
        <div className="btit1">
          <div className="title">
            <span className="txt">Entity Type</span>
            <Tooltip
              title={(
                <TooltipContent
                  title={TOOLTIP_INFO.ENTITY_TYPE.title}
                  description={TOOLTIP_INFO.ENTITY_TYPE.description}
                />
              )}
              TransitionProps={{ timeout: 0 }}
            >
              <button type="button" className="light">
                <span className="icinfo" />
              </button>
            </Tooltip>
          </div>
          <div>&nbsp;</div>
        </div>
        <div className="btit2 txt">Select one or more types.</div>
      </div>
      <div className="optionchk">
        <FormGroup>
          {entities.map((option, optionIndex) => (
            <FormControlLabel
              key={option.value}
              control={(
                <Checkbox
                  checked={option.checked}
                  onChange={() => {
                    setEntities(
                      entities.map((c, ci) => {
                        if (ci === optionIndex) {
                          return { ...c, checked: !c.checked };
                        }
                        return c;
                      })
                    );
                  }}
                />
              )}
              label={option.value}
            />
          ))}
        </FormGroup>
      </div>
      <div className="attributedetail">
        <div className="btit1">
          <div className="title">
            <span className="txt">Graduate Medical Education</span>
            <Tooltip
              title={(
                <TooltipContent
                  title={TOOLTIP_INFO.GRADUATE_MEDICAL_EDUCATION.title}
                  description={TOOLTIP_INFO.GRADUATE_MEDICAL_EDUCATION.description}
                />
              )}
              TransitionProps={{ timeout: 0 }}
            >
              <button type="button" className="light">
                <span className="icinfo" />
              </button>
            </Tooltip>
          </div>
          <div>&nbsp;</div>
        </div>
        <div className="btit2 txt">Select whether facilities included such programs.</div>
      </div>
      <div className="optionswitch">
        <FormGroup>
          <FormControlLabel
            control={
              (
                <Checkbox
                  checked={graduate ? graduate.yes : false}
                  onChange={() => {
                    setGraduate({
                      yes: graduate ? !graduate.yes : false,
                      no: graduate ? graduate.no : false
                    });
                  }}
                />
              )
            }
            label="YES"
          />
          <FormControlLabel
            control={
              (
                <Checkbox
                  checked={graduate ? graduate.no : false}
                  onChange={() => {
                    setGraduate({
                      yes: graduate ? graduate.yes : false,
                      no: graduate ? !graduate.no : false
                    });
                  }}
                />
              )
            }
            label="NO"
          />
        </FormGroup>
      </div>
      <div className="attributedetail">
        <div className="btit1">
          <div className="title">
            <span className="txt">Health Equity</span>
            <Tooltip
              title={(
                <TooltipContent
                  title={TOOLTIP_INFO.HEALTH_EQUITY.title}
                  description={TOOLTIP_INFO.HEALTH_EQUITY.description}
                />
              )}
              TransitionProps={{ timeout: 0 }}
            >
              <button type="button" className="light">
                <span className="icinfo" />
              </button>
            </Tooltip>
          </div>
          <div>&nbsp;</div>
        </div>
        <div className="btit2 txt">Select or input score range.</div>
      </div>
      <div className="optionchk">
        <VizonomySlider format={sliderPercentage} value={healthEq} setValue={setHealthEq} />
      </div>
      <div className="attributedetail">
        <div className="btit1">
          <div className="title">
            <span className="txt">Charity &amp; Medicaid Service</span>
            <Tooltip
              title={(
                <TooltipContent
                  title={TOOLTIP_INFO.CHARITY.title}
                  description={TOOLTIP_INFO.CHARITY.description}
                />
              )}
              TransitionProps={{ timeout: 0 }}
            >
              <button type="button" className="light">
                <span className="icinfo" />
              </button>
            </Tooltip>
          </div>
          <div>&nbsp;</div>
        </div>
        <div className="btit2 txt">Select or input score range.</div>
      </div>
      <div className="optionchk">
        <VizonomySlider format={sliderPercentage} value={charity} setValue={setCharity} />
      </div>
      <div className="attributedetail">
        <div className="btit1">
          <div className="title">
            <span className="txt">Tax Status</span>
            <Tooltip
              title={(
                <TooltipContent
                  title={TOOLTIP_INFO.TAX_STATUS.title}
                  description={TOOLTIP_INFO.TAX_STATUS.description}
                />
              )}
              TransitionProps={{ timeout: 0 }}
            >
              <button type="button" className="light">
                <span className="icinfo" />
              </button>
            </Tooltip>
          </div>
          <div>&nbsp;</div>
        </div>
        <div className="btit2 txt">Select whether facilities have 501(c)(3) status.</div>
      </div>
      <div className="optionswitch">
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={taxStatus ? taxStatus.yes : false}
                onChange={() => {
                  setTaxStatus({
                    yes: taxStatus ? !taxStatus.yes : false,
                    no: taxStatus ? taxStatus.no : false
                  });
                }}
              />
            )}
            label="YES"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={taxStatus ? taxStatus.no : false}
                onChange={() => {
                  setTaxStatus({
                    yes: taxStatus ? taxStatus.yes : false,
                    no: taxStatus ? !taxStatus.no : false
                  });
                }}
              />
            )}
            label="NO"
          />
        </FormGroup>
      </div>
    </div>
  );
});
