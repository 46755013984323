import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { forwardRef, useContext } from 'react';
import { GenericAny, HousingSlider, HousingSubSlider } from '../@types';
import { FilterContextState } from '../context/FilterContext';
import { HousingDispatch, HousingState } from '../context/HousingContext';
import { IcLayerContent } from './IcLayerContent';
import { TooltipContent } from './ToolipContent';
import VizonomySlider from './VizonomySlider';

export const Housing = forwardRef<HTMLDivElement, GenericAny>((props, ref) => {
  const { setSliders } = useContext(HousingDispatch);
  const { state } = useContext(FilterContextState);
  const { currentCensusStyle } = state;
  const { sliders } = useContext(HousingState);

  return (
    <div id="housing" ref={ref}>
      <div className="pattributebar">
        <p className="txtl">Housing</p>
      </div>
      {
        sliders.map((slider: HousingSlider, sliderIndex: number) => (
          !slider.invisible && (
            <div key={slider.field || slider.label}>
              <div className="attributedetail">
                <div className="btit1">
                  <div className="title">
                    <span className="txt">
                      <span className={
                          classNames({
                            txt: currentCensusStyle !== slider.label,
                            'txt-active-label': currentCensusStyle === slider.label
                          })
                        }
                      >
                        {slider.label}

                      </span>
                      {slider.description && (
                      <Tooltip
                        title={(
                          <TooltipContent
                            title={slider.label}
                            description={slider.description}
                          />
                          )}
                        TransitionProps={{ timeout: 0 }}
                      >
                        <button type="button" className="light">
                          <span className="icinfo" />
                        </button>
                      </Tooltip>
                      )}
                    </span>
                  </div>
                  {
                  (!slider.subSliders || slider.subSliders.length === 0) && (
                    <IcLayerContent label={slider.label} />
                  )
                }
                </div>
                <div className="btit2 txt">
                  {slider.subtitle}
                </div>
              </div>
              {
                slider.subSliders && slider.subSliders.length > 0
                  ? slider.subSliders?.map(
                    (subSlider: HousingSubSlider, subSliderIndex: number) => (
                      <div key={subSlider.field}>
                        <div className="btit3">
                          <div className="iclayer-conteiner">
                            <span className="txt">
                              <span className={
                                  classNames({
                                    txt: currentCensusStyle !== subSlider.label,
                                    'txt-active-label': currentCensusStyle === subSlider.label
                                  })
                                }
                              >
                                {subSlider.label}

                              </span>
                              {subSlider.hasTooltip && (
                                <Tooltip
                                  title={(
                                    <TooltipContent
                                      title={`${subSlider.title}`}
                                      description={subSlider.description ? subSlider.description : ''}
                                    />
                                    )}
                                  TransitionProps={{ timeout: 0 }}
                                >
                                  <button type="button" className="light">
                                    <span className="icinfo" />
                                  </button>
                                </Tooltip>
                              )}
                            </span>
                            <IcLayerContent label={subSlider.label} />
                          </div>
                        </div>
                        <div className="optionchk">
                          <VizonomySlider
                            format={subSlider.format}
                            step={subSlider.step}
                            min={subSlider.min}
                            max={subSlider.max}
                            value={subSlider.value}
                            setValue={(value: number[]) => {
                              const newSlider = sliders.map(
                                (sliderContainer: HousingSlider, sliderContainerIndex: number) => {
                                  if (sliderContainerIndex === sliderIndex) {
                                    const subSliders = sliderContainer.subSliders?.map(
                                      (subSliderInContainer: HousingSubSlider, subSliderInContainerIndex: number) => {
                                        if (subSliderInContainerIndex === subSliderIndex) {
                                          return {
                                            ...subSliderInContainer,
                                            value
                                          };
                                        }
                                        return subSliderInContainer;
                                      }
                                    );
                                    return {
                                      ...sliderContainer,
                                      subSliders
                                    };
                                  }
                                  return sliderContainer;
                                }
                              );
                              setSliders(newSlider);
                            }}
                          />
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="optionchk" key={slider.field}>
                      <VizonomySlider
                        format={slider.format}
                        step={slider.step}
                        min={slider.min}
                        max={slider.max}
                        value={slider.value}
                        setValue={(value: number[]) => {
                          const newSliders = sliders.map(
                            (sliderContainer: HousingSlider, sliderContainerIndex: number) => {
                              if (sliderContainerIndex === sliderIndex) {
                                return {
                                  ...sliderContainer,
                                  value
                                };
                              }
                              return sliderContainer;
                            }
                          );
                          setSliders(newSliders);
                        }}
                      />
                    </div>
                  )
              }
            </div>
          )
        ))
    }
    </div>
  );
});
