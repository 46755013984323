import { createContext } from 'react';
import { HousingSlider } from '../@types';

export const HousingState = createContext({
  sliders: []
} as {
  sliders: HousingSlider[]
});

export const HousingDispatch = createContext({
  setSliders: () => {
    console.info('HousingDispatch is not set');
  },
  resetSliders: () => {
    console.info('HousingDispatch is not set');
  }
} as {
  setSliders: (sliders: HousingSlider[]) => void,
  resetSliders: () => void
});
