import { TAX_YES } from '../constants';

export const parseToYesNo = (value: boolean | null | undefined) => {
  if (value == null || typeof value === 'undefined') return '-';
  return value ? 'Yes' : 'No';
};
export const parseToPercentage = (value: number | null | undefined) => {
  if (value == null || typeof value === 'undefined') return '-';
  return `${value}%`;
};

export const sliderPercentage = (v: number) => `${v}%`;
export const sliderthousands = (v: number) => `${v}K`;
export const slidertMillions = (v: number) => `${v}M`;
export const sliderDefault = (v: number) => `${v}`;
export const sliderPercentageOneDecimal = (v: number) => `${v.toFixed(1)}%`;
export const sliderReadableNumber = (v: number) => `${v.toLocaleString()}`;
export const sliderNoDecimal = (v: number) => `${v.toFixed(0)}`;
export const toUpperCase = (v: string) => (`${v}`).toUpperCase();
export const toTax = (v: string) => (v === TAX_YES ? v : 'NULL');
export const twoDecimal = (v: number) => `${v.toFixed(2)}`;
export const yearFormat = (v: number) => `${v.toFixed(2)} Yrs`;
export const moneyFormat = (v: number) => `$${sliderReadableNumber(+v.toFixed(0))}`;

export const filterSliderDefaultValue = (max: number, value: number[], label?: string) => {
  if (label !== 'EBITDA (after special charges)' && label !== 'NPSR % Growth') {
    if (value[1] !== max || value[0] !== 0) {
      return true;
    }
  } else if (value[1] !== max || value[0] !== -10) {
    return true;
  }
  return false;
};
export const parserBooleanToYesNo = (value: boolean | null | undefined, value2: boolean | null | undefined) => {
  if (value == null || typeof value === 'undefined') return '';
  if (value2 == null || typeof value2 === 'undefined') return '';
  if (value) return 'Yes';
  if (value2) return 'No';
  return 'None';
};
