import axios from 'axios';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GenericAny } from '../@types';
import { BAD_REQUEST, ENDPOINTS } from '../constants';

const GET_STATES = 'GET_STATES';

export const useGetColumn = () => {
  const [columns, setColumns] = useState<string[]>([]);
  const [errorText, setErrorText] = useState('');

  const getStatesNames = async () => {
    const { data } = await axios.get(ENDPOINTS.GET_STATES);
    return data.data;
  };

  const doGetColumn = async (currentColumn: string) => {
    const { data: response } = await axios.get(ENDPOINTS.GET_COLUMN(currentColumn));
    return response.data;
  };

  const { data: statesNames } = useQuery(GET_STATES, getStatesNames);

  const { mutate, error } = useMutation(doGetColumn, {
    onSuccess: (data) => {
      setColumns(data);
      setErrorText('');
    },
    onError: (err: GenericAny) => {
      if (err.response.status === BAD_REQUEST) {
        setErrorText('Unauthorized by Carto');
      } else {
        setErrorText('Something went wrong');
      }
    }
  });

  const handleSubmit = (column: string) => {
    mutate(column);
  };

  return {
    error,
    columns,
    handleSubmit,
    errorText,
    statesNames
  };
};
