export const TooltipContent = ({
  title,
  description,
}: TooltipData) => (
  <div className="txts white-text">
    <span>
      <b>{`${title}: `}</b>
    </span>
    <br />
    <br />
    {description}
  </div>
);
