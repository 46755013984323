import { createContext } from 'react';

export const VisibilityState = createContext({
  facilitiesVisible: true,
  publicaVisible: false,
  cheaVisible: false,
  censusVisible: false
} as {
  facilitiesVisible: boolean,
  publicaVisible: boolean,
  cheaVisible: boolean,
  censusVisible: boolean
});

export const VisibilityDispatch = createContext({
  setFacilitiesVisible: () => {
    console.info('setFacilitiesVisible is not set');
  },
  setPublicaVisible: () => {
    console.info('setPublicaVisible is not set');
  },
  setCheaVisible: () => {
    console.info('setCheaVisible is not set');
  },
  setCensusVisible: () => {
    console.info('setCensusVisible is not set');
  }
} as {
  setFacilitiesVisible: (facilitiesVisible: boolean) => void,
  setPublicaVisible: (publicaVisible: boolean) => void,
  setCheaVisible: (cheaVisible: boolean) => void,
  setCensusVisible: (censusVisible: boolean) => void
});
