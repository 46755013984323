import { useMemo, useState } from 'react';
import { MAP_CONSTANTS } from '../constants';
import { AccordionDispatch, AccordionState } from '../context/AccordionContext';

export const AccordionProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [basemap, setBasemap] = useState(MAP_CONSTANTS.BASEMAP_NAME.STREET);
  const state = useMemo(() => ({
    basemap
  }), [basemap]);
  const setters = useMemo(() => ({
    setBasemap
  }), [setBasemap]);
  return (
    <AccordionState.Provider value={state}>
      <AccordionDispatch.Provider value={setters}>
        {children}
      </AccordionDispatch.Provider>
    </AccordionState.Provider>
  );
};
