import { Navigate, Outlet } from 'react-router-dom';
import { AUTH_TOKEN, LOGIN_ROUTE } from '../constants';

const PrivateRoute = () => {
  const isLoggedIn = localStorage.getItem(AUTH_TOKEN);

  if (isLoggedIn) {
    return (
      <Outlet />
    );
  }
  return <Navigate replace to={LOGIN_ROUTE} />;
};

export default PrivateRoute;
