import {
  forwardRef
} from 'react';
import { GenericAny } from '../@types';

export const AboutUs = forwardRef<HTMLDivElement, GenericAny>((props, ref) => (
  <div id="aboutus" ref={ref}>
    <div className="pattributebar">
      <p className="txtl">About Us</p>
    </div>
    <div>
      <div className="attributedetail">
        <div className="btit1">
          <div className="title">
            <span className="txtss">
              Using the filters below you can search by socio-demographic characteristic as
              defined by the 2020 Amercican Community Survey from the U.S. Census. Categories
              include population, economic, housing, language, and technology accessibility
              attributes.
              <br />
              <br />
              Additionally all searches are based on the `community profile` about each Common Spirit Facility,
              where the community is defined either by the driving time or radial distance.
            </span>
          </div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
));
