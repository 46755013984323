import React, { useCallback, useMemo, useState } from 'react';
import { PatientSlider } from '../@types';
import { MAP_CONSTANTS, TOOLTIP_INFO } from '../constants';
import { PatientServicesDispatch, PatientServicesState } from '../context/PatientServicesContext';
import { sliderPercentage } from '../utils';

const options = [
  {
    label: 'Patient Care Quality - Acute',
    label2: 'Sepsis Mortality',
    max: 100,
    field: MAP_CONSTANTS.FIELDS_CSH.PATIENT_CARE_QUALITY_ACUTE_SEPSIS_MORTALITY,
    description: TOOLTIP_INFO.PATIENT_CARE_QUALITY_ACUTE_SEPSIS_MORTALITY.description,
  },
  {
    label: 'Patient Care Quality - Continuum of Care: Hypertension Management',
    max: 100,
    field: MAP_CONSTANTS.FIELDS_CSH.PATIENT_CARE_QUALITY_CONTINUUM_OF_CARE_HYPERTENSION_MANAGEME,
    description: TOOLTIP_INFO.PATIENT_CARE_QUALITY_CONTINUUM_OF_CARE_HYPERTENSION_MANAGEME.description,
  },
  {
    label: 'Patient Experience - Acute Care Experience',
    max: 100,
    field: MAP_CONSTANTS.FIELDS_CSH.PATIENT_EXPERIENCE_ACUTE_CARE_EXPERIENCE,
    description: TOOLTIP_INFO.PATIENT_EXPERIENCE_ACUTE_CARE_EXPERIENCE.description,
  },
  {
    label: 'Patient Experience - Medical Practice Experience',
    max: 100,
    field: MAP_CONSTANTS.FIELDS_CSH.PATIENT_EXPERIENCE_MEDICAL_PRACTICE_EXPERIENCE,
    description: TOOLTIP_INFO.PATIENT_EXPERIENCE_MEDICAL_PRACTICE_EXPERIENCE.description,
  },
  {
    label: 'Patient Experience - Virtual Medical Practice Experience',
    max: 100,
    field: MAP_CONSTANTS.FIELDS_CSH.PATIENT_EXPERIENCE_VIRTUAL_MEDICAL_PRACTICE_EXPERIENCE,
    description: TOOLTIP_INFO.PATIENT_EXPERIENCE_VIRTUAL_MEDICAL_PRACTICE_EXPERIENCE.description,
  },
  {
    label: 'Patient Safety',
    max: 100,
    field: MAP_CONSTANTS.FIELDS_CSH.PATIENT_SAFETY,
    description: TOOLTIP_INFO.PATIENT_SAFETY.description,
  }
];

export const PatientServicesProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [sliders, setSliders] = useState<PatientSlider[]>(
    options.map((option) => ({
      value: [0, option.max],
      max: option.max,
      label: option.label,
      label2: option.label2 ?? '',
      field: option.field,
      subtitle: 'Select or input score range.',
      description: option.description,
      format: sliderPercentage,
    }))
  );

  const resetSliders = useCallback(() => {
    setSliders(
      options.map((option) => ({
        value: [0, option.max],
        max: option.max,
        label: option.label,
        label2: option.label2 ?? '',
        field: option.field,
        subtitle: 'Select or input score range.',
        format: sliderPercentage,
        description: '',
      }))
    );
  }, [setSliders]);
  const state = useMemo(() => ({
    sliders
  }), [sliders]);
  const setters = useMemo(() => ({
    setSliders, resetSliders
  }), [setSliders, resetSliders]);

  return (
    <PatientServicesState.Provider value={state}>
      <PatientServicesDispatch.Provider value={setters}>
        {children}
      </PatientServicesDispatch.Provider>
    </PatientServicesState.Provider>
  );
};
