import { useContext, useEffect, useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { AreaOptions, GenericAny } from '../@types';
import {
  AREA_OR_DOMAIN,
  INVERTED_AREA_OR_DOMAIN,
  STATE,
  DIVISION,
  ResetProps
} from '../constants';
import { useGetColumn } from '../hooks/useGetColumn';
import { TYPES } from '../constants/context';
import { useMapRequest } from '../hooks/useMapRequest';
import { FilterContextDispatch } from '../context/FilterContext';
import { SidebarFirstTabDispatch, SidebarFirstTabState } from '../context/SidebarFirstTabContext';
import { BboxState, BboxDispatch } from '../context/BboxContext';

export const SidebarFirstTab = ({ needsReset, setNeedsReset }: ResetProps) => {
  const {
    area,
    subdropdown,
    dropdownName
  } = useContext(SidebarFirstTabState);
  const {
    setArea,
    setSubdropdown,
    setDropdownName,
    setHasChanged,
  } = useContext(SidebarFirstTabDispatch);
  const { columns, handleSubmit, statesNames } = useGetColumn();
  const { getBboxFilteredPoints, setStateBbox } = useMapRequest();
  const { dispatch } = useContext(FilterContextDispatch);
  const { selectedDivision } = useContext(BboxState);
  const { setSelectedState, setSelectedDivision } = useContext(BboxDispatch);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      setHasChanged(true);
      if (area) {
        handleSubmit(area);
      }
    }
  }, [area, firstLoad, handleSubmit, setHasChanged]);

  useEffect(() => {
    if (selectedDivision !== '') {
      setSubdropdown([selectedDivision.toUpperCase()]);
    }
  }, [selectedDivision, setSubdropdown]);

  useEffect(() => {
    if (area === STATE) {
      dispatch({ type: TYPES.SET_STATES, payload: columns });
    }
  }, [area, columns, dispatch]);

  useEffect(() => {
    if (needsReset) {
      dispatch({ type: TYPES.REMOVE, payload: area });
      setArea('');
      setSubdropdown([]);
      setDropdownName('');
      setSelectedDivision('');
      setSelectedState([]);
      setHasChanged(true);
      setNeedsReset(false);
    }
  }, [
    needsReset,
    dispatch,
    setNeedsReset,
    setArea,
    setSubdropdown,
    setDropdownName,
    area,
    setHasChanged,
    setSelectedState,
    setSelectedDivision
  ]);

  useEffect(() => {
    if (area === STATE) {
      dispatch({ type: TYPES.SET_SHOW_STATES, payload: true });
      dispatch({ type: TYPES.SET_SHOW_DIVISIONS, payload: false });
    } else if (area === DIVISION) {
      dispatch({ type: TYPES.SET_SHOW_DIVISIONS, payload: true });
      dispatch({ type: TYPES.SET_SHOW_STATES, payload: false });
    } else {
      dispatch({ type: TYPES.SET_SHOW_STATES, payload: false });
      dispatch({ type: TYPES.SET_SHOW_DIVISIONS, payload: false });
    }
    if (subdropdown.length) {
      dispatch({ type: TYPES.REMOVE, payload: area });
      dispatch({ type: TYPES.ADD_FILTER, payload: ['in', ['get', area], ['literal', [...subdropdown]]] });
      getBboxFilteredPoints(area, subdropdown);
    }
    if (area === '') {
      setStateBbox({ stateAbbrev: '', data: [] });
    }
  }, [
    subdropdown,
    area,
    dispatch,
    setSelectedState,
    setSelectedDivision,
    getBboxFilteredPoints,
    setStateBbox
  ]);

  useEffect(() => {
    setDropdownName(INVERTED_AREA_OR_DOMAIN[area]);
  }, [area, setDropdownName]);

  return (
    <div className="form">
      <div className="item">
        <div className="txt title">Area or Domain</div>
        <div className="inputs">
          <FormControl>
            <Select
              displayEmpty
              value={area}
              onChange={(event) => {
                setSubdropdown([]);
                dispatch({ type: TYPES.REMOVE, payload: area });
                setArea(event.target.value as AreaOptions);
                handleSubmit(event.target.value);
                setHasChanged(true);
              }}
            >
              <MenuItem disabled value="" className="txt">
                Select Area
              </MenuItem>
              {AREA_OR_DOMAIN.map((item) => (
                <MenuItem key={item.name} value={item.value} className="txt">
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {dropdownName && (
        <div className="item">
          <div className="txt title">{dropdownName}</div>
          <div className="inputs">
            <FormControl>
              <Select
                displayEmpty
                multiple
                value={subdropdown}
                onChange={(event) => {
                  const { value } = event.target;
                  const array = typeof value === 'string' ? value.split(',') : value;
                  setSubdropdown(array);
                  // resetAndHasChanged();
                }}
                renderValue={
                  subdropdown.length ? undefined : () => <span className="placeholder-subdropdown">Select area(s)</span>
                }
              >
                <MenuItem disabled value="">
                  <em>Select Location</em>
                </MenuItem>
                {area !== STATE && columns.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
                {area === STATE && columns.map((item) => (
                  <MenuItem key={item} value={item}>
                    {statesNames.find((elem: GenericAny) => elem.abbrev === item)?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      )}

    </div>
  );
};
