import { createContext } from 'react';
import { DRIVING_DISTANCE } from '../constants';

export const BoundariesState = createContext({
  drivingTime: DRIVING_DISTANCE[0].value
} as unknown as {
  drivingTime: string
});

export const BoundariesDispatch = createContext({
  setDrivingTime: () => {
    console.info('DrivingTime is not set');
  }
} as {
  setDrivingTime: (drivingTime: string) => void
});
