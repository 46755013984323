import { GenericAny } from '../@types';

export const mapStylePointer = (keyTable?: string, colors?: GenericAny[]) => {
  if (keyTable && colors) {
    return {
      'fill-color':
      [
        'case',
        ['==', ['get', keyTable], null],
        'hsla(0,0,100%, 0)',
        ['>', ['get', keyTable], -1],
        [
          'interpolate',
          ['linear'],
          ['get', keyTable],
          ...colors
        ],
        'hsla(0,0,100%, 0)'
      ],
      'fill-opacity': 0.65,
      'fill-outline-color': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        'hsla(0,7%,100%, 0)',
        5.886,
        'hsla(0,7%,100%, 0)',
        6.5,
        'hsla(0,7%,100%, 0)'
      ],
    };
  }
  return {
    type: 'fill',
    'source-layer': 'pluto15v1',
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-outline-color': '#b0aeb2',
      'fill-opacity': 0.3,
      'fill-color': '#b0aeb2'
    }
  };
};

export const housingParser = (limiter: number[], color: string[]) => {
  const housingPrepare = [];
  for (let index = 0; index < limiter.length * 2; index += 2) {
    housingPrepare.push(limiter[index / 2]);
    housingPrepare.push(color[index / 2]);
  }
  return housingPrepare;
};
