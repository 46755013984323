import {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import {
  MAP_LAYERS,
  MAP_STYLES,
  MAP_CONSTANTS,
  BASIC_FILTER_STATES,
  BASIC_ENTITIES_FILTER,
  STATE,
  BASIC_FILTER_DIVISIONS,
  DIVISION,
  CENSUS_STYLES,
  TOOLTIP_INFO,
  LEGENDS,
  BOUNDARIES_STYLES,
  BASIC_FILTER_CENSUS,
  BASIC_FILTER_FACILITIES
} from '../constants';
import { useMapboxMap } from '../hooks/useMapboxMap';
import { useMapRequest } from '../hooks/useMapRequest';
import { parseToPercentage, parseToYesNo } from '../utils';
import { Popup } from './Popup';
import { FilterArea } from './FilterArea';
import { FilterContextDispatch, FilterContextState } from '../context/FilterContext';
import { SidebarFirstTabState, SidebarFirstTabDispatch } from '../context/SidebarFirstTabContext';
import { BboxDispatch, BboxState } from '../context/BboxContext';
import { GenericAny } from '../@types';
import { VisibilityDispatch, VisibilityState } from '../context/VisibilityContext';
import { TYPES } from '../constants/context';
import { useTilesRequest } from '../hooks/useTilesRequest';
import { DrivingState } from '../context/DrivingContext';
import { PopupCensus } from './PopupCensus';
import { useFacilitiesRequest } from '../hooks/useFacilitiesRequest';

export const MapContainer = () => {
  const [showLayerList, setShowLayerList] = useState<boolean>(false);
  const [gateCensus, setGateCensus] = useState<boolean>(false);
  const {
    bbox,
    stateBbox,
    isZooming,
    hasChangeNavbar,
    tabSelected
  } = useContext(BboxState);
  const { drivingTime } = useContext(DrivingState);
  const {
    map,
    mapContainer,
    addVectorSource,
    addVectorLayer,
    onClickLayer,
    onceClickLayer,
    onMouseLeaveLayer,
    onMouseMoveLayer,
    onMoveEnd,
    popupRef,
    popupRefFacilities,
    setFilter,
    loaded,
    setLayerVisibility,
    resetBounds,
    layers,
    moveLayer,
    fitBounds,
    updatePaintProperty
  } = useMapboxMap(bbox);
  const { allTiles } = useTilesRequest();
  const { tiles } = useMapRequest();
  const { tiles: tilesState } = useMapRequest(MAP_LAYERS.CSH_STATE_TABLE);
  const { tiles: tilesCHEA } = useMapRequest(MAP_LAYERS.CSH_CHEA_TABLE);
  const { tiles: tilesPublica } = useMapRequest(MAP_LAYERS.CSH_PUBLICA_TABLE);
  const { tiles: tilesDivisions } = useMapRequest(MAP_LAYERS.CSH_STATE_DIVISION);
  const { tiles: tilesSocio } = useMapRequest(MAP_LAYERS.CSH_SOCIO_TABLE);
  const { data: dataEntities } = useFacilitiesRequest();
  const {
    facilitiesVisible,
    cheaVisible,
    publicaVisible,
    censusVisible
  } = useContext(VisibilityState);
  const {
    setFacilitiesVisible,
    setCheaVisible,
    setPublicaVisible,
    setCensusVisible
  } = useContext(VisibilityDispatch);
  const [panel, setPanel] = useState<string>('');
  const [hoverLayer, setHoverLayer] = useState(false);
  const {
    area,
    subdropdown,
    hasChanged
    // hasChangedSubdropdown
  } = useContext(SidebarFirstTabState);
  const { setHasChanged, setHasChangedSubdropdown, setSubdropdown } = useContext(SidebarFirstTabDispatch);
  const { state } = useContext(FilterContextState);
  const { dispatch } = useContext(FilterContextDispatch);
  const {
    filter,
    showStates,
    states,
    showDivisions,
    currentCensusStyle,
    gateSidebar
  } = state;
  const {
    setSelectedState,
    setBbox,
    setSelectedDivision,
    setIsZooming
  } = useContext(BboxDispatch);
  const [isZoomEventSetted, setIsZoomEventSetted] = useState(false);

  useEffect(() => {
    if (!loaded || isZoomEventSetted) return;
    const cb = () => {
      const bboxMapboxFormat = map.current.getBounds();
      const currentBbox = [
        [bboxMapboxFormat.getSouthWest().lng, bboxMapboxFormat.getSouthWest().lat],
        [bboxMapboxFormat.getNorthEast().lng, bboxMapboxFormat.getNorthEast().lat]
      ];
      setBbox(currentBbox);
    };
    onMoveEnd(cb);
    setIsZoomEventSetted(true);
  }, [loaded, isZoomEventSetted, map, setBbox, onMoveEnd]);

  useEffect(() => {
    if (subdropdown) {
      if (area === STATE) {
        const filterHover = [...BASIC_FILTER_STATES];
        const stateIndex = 2;
        filterHover[stateIndex] = subdropdown.length ? [...subdropdown] : ['none'];
        MAP_STYLES[MAP_LAYERS.CSH_STATE_TABLE].forEach((layerHover: GenericAny, _index) => {
          if (layerHover.type === 'line' && !layerHover.hoverLayer) {
            const currentIDHover = `${MAP_LAYERS.CSH_STATE_TABLE}-layer-${_index}`;
            if (layers.length) {
              setFilter(currentIDHover, filterHover);
              setLayerVisibility(currentIDHover, true);
            }
          }
        });
      } else if (area === DIVISION) {
        const filterHover = [...BASIC_FILTER_DIVISIONS];
        const divisionIndex = 2;
        const capValuesForMap = subdropdown.map((sub) => {
          const words = sub.split(' ');
          for (let i = 0; i < words.length; i += 1) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
          }
          return words.join(' ');
        });
        filterHover[divisionIndex] = capValuesForMap.length ? [...capValuesForMap] : ['none'];
        MAP_STYLES[MAP_LAYERS.CSH_STATE_DIVISION].forEach((layerHover: GenericAny, _index) => {
          if (layerHover.type === 'line' && !layerHover.hoverLayer) {
            const currentIDHover = `${MAP_LAYERS.CSH_STATE_DIVISION}-layer-${_index}`;
            if (layers.length) {
              setFilter(currentIDHover, filterHover);
              setLayerVisibility(currentIDHover, true);
            }
          }
        });
      }
    }
  }, [area, subdropdown, setFilter, setLayerVisibility, layers]);

  const removeAllStatesLayers = useCallback(() => {
    MAP_STYLES[MAP_LAYERS.CSH_STATE_TABLE].forEach((layer: GenericAny, index) => {
      const currentLayerID = `${MAP_LAYERS.CSH_STATE_TABLE}-layer-${index}`;
      setLayerVisibility(currentLayerID, false);
    });
  }, [setLayerVisibility]);

  const removeAllDivisionLayers = useCallback(() => {
    MAP_STYLES[MAP_LAYERS.CSH_STATE_DIVISION].forEach((layer: GenericAny, index) => {
      const currentLayerID = `${MAP_LAYERS.CSH_STATE_DIVISION}-layer-${index}`;
      setLayerVisibility(currentLayerID, false);
    });
  }, [setLayerVisibility]);

  useEffect(() => {
    if (hasChanged) {
      if (area !== STATE) {
        removeAllStatesLayers();
      }
      if (area !== DIVISION) {
        removeAllDivisionLayers();
      }
      // if (!hasChangeNavbar) {
      resetBounds();
      // }
      setHasChanged(false);
    }
  }, [area, hasChanged, resetBounds, removeAllStatesLayers, setHasChanged, removeAllDivisionLayers, hasChangeNavbar]);

  useEffect(() => {
    if (area === STATE) {
      setSelectedState(subdropdown);
    }
  }, [area, subdropdown, setSelectedState]);
  useEffect(() => {
    if (!loaded) {
      return;
    }
    MAP_STYLES[MAP_LAYERS.CSH_FACILITIES_TABLE].forEach((layer: GenericAny, index) => {
      const layerName = `${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-${index}`;
      if (!layer.hoverLayer) {
        if (filter.length > 0) {
          setFilter(layerName, filter);
        } else {
          setFilter(layerName, null);
        }
      }
    });
  }, [
    filter,
    setFilter,
    loaded,
    subdropdown,
    layers,
    setSelectedState,
    setSelectedDivision
  ]);

  useEffect(() => {
    if (layers.length) {
      MAP_STYLES[MAP_LAYERS.CSH_STATE_TABLE].forEach((layer: GenericAny, index) => {
        const currentLayerID = `${MAP_LAYERS.CSH_STATE_TABLE}-layer-${index}`;
        setLayerVisibility(currentLayerID, showStates);
        if (states.length && layer.type === 'fill') {
          const filterByStates = [...BASIC_FILTER_STATES];
          filterByStates[2] = states;
          setFilter(currentLayerID, filterByStates);
        }
      });
    }
  }, [showStates, setLayerVisibility, setFilter, states, layers]);

  useEffect(() => {
    if (layers.length) {
      MAP_STYLES[MAP_LAYERS.CSH_STATE_DIVISION].forEach((layer: GenericAny, index) => {
        const currentLayerID = `${MAP_LAYERS.CSH_STATE_DIVISION}-layer-${index}`;
        setLayerVisibility(currentLayerID, showDivisions);
      });
    }
  }, [showDivisions, setLayerVisibility, layers]);

  useEffect(() => {
    if (censusVisible && gateCensus && currentCensusStyle === '') {
      dispatch({ type: TYPES.SET_CURRENT_CENSUS, payload: TOOLTIP_INFO.DEFAULT.title });
      setGateCensus(false);
    } else if (!censusVisible && gateCensus) {
      dispatch({ type: TYPES.SET_CURRENT_CENSUS, payload: '' });
      setGateCensus(false);
    }
  }, [censusVisible, setGateCensus, dispatch, currentCensusStyle, gateCensus]);

  const getPaintProperties = useCallback(() => {
    const newPaintStyles = CENSUS_STYLES[currentCensusStyle].paint;
    if (loaded && newPaintStyles) {
      Object.entries(newPaintStyles).forEach((entry) => {
        const [key, value] = entry;
        MAP_STYLES[MAP_LAYERS.CSH_SOCIO_TABLE].forEach((layer, index) => {
          const currentLayerID = `${MAP_LAYERS.CSH_SOCIO_TABLE}-layer-${index}`;
          if (layer.type !== 'line') {
            updatePaintProperty(currentLayerID, key, value);
          }
        });
      });
    }
  }, [updatePaintProperty, loaded, currentCensusStyle]);
  const openCensusPanel = useCallback(() => {
    if (CENSUS_STYLES[currentCensusStyle] && currentCensusStyle !== '') {
      setCensusVisible(true);
      setShowLayerList(true);
      setPanel(MAP_CONSTANTS.LEGEND_TITLES.CENSUS_BLOCK_GROUPS);
      getPaintProperties();
    }
  }, [currentCensusStyle, setCensusVisible, getPaintProperties]);

  const closeCensusPanel = useCallback(() => {
    setPanel('');
    setCensusVisible(false);
  }, [setPanel, setCensusVisible]);
  useEffect(() => {
    if (currentCensusStyle !== '' && gateSidebar) {
      openCensusPanel();
    }
    if (currentCensusStyle === '' && gateSidebar) {
      closeCensusPanel();
    }
    if (currentCensusStyle !== '' && !gateSidebar) {
      getPaintProperties();
    }
    dispatch({ type: TYPES.SET_GATE_SIDEBAR, payload: false });
  }, [currentCensusStyle, gateSidebar, openCensusPanel, dispatch, closeCensusPanel, getPaintProperties]);

  useEffect(() => {
    if (tilesSocio && loaded) {
      addVectorSource(`${MAP_LAYERS.CSH_SOCIO_TABLE}-source`, tilesSocio.tiles);
      MAP_STYLES[MAP_LAYERS.CSH_SOCIO_TABLE].forEach((layer, index) => {
        const currentID = `${MAP_LAYERS.CSH_SOCIO_TABLE}-layer-${index}`;
        addVectorLayer(
          currentID,
          { ...layer, source: `${MAP_LAYERS.CSH_SOCIO_TABLE}-source` }
        );
        onMouseMoveLayer(
          currentID,
          (e: GenericAny) => {
            const features = map.current.queryRenderedFeatures(
              e.point,
              {
                layers: [currentID]
              }
            );
            const filterHover = [...BASIC_FILTER_CENSUS];
            filterHover[2] = e.features[0].properties.cartodb_id;
            MAP_STYLES[MAP_LAYERS.CSH_SOCIO_TABLE].forEach((layerHover: GenericAny, _index) => {
              if (layerHover.type === 'line' && layerHover.hoverLayer) {
                const currentIDHover = `${MAP_LAYERS.CSH_SOCIO_TABLE}-layer-${_index}`;
                setFilter(currentIDHover, filterHover);
              }
            });
            if (features.length > 0) {
              const popupNode = document.createElement('div');
              const contentPoPUpRow = [
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.WHITE,
                  content: features[0].properties.white_pop
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.BLACK,
                  content: features[0].properties.black_pop
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.ASIAN,
                  content: features[0].properties.asian_pop
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.HISPANIC,
                  content: features[0].properties.hispanic_p
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.AMERICAN_INDIAN,
                  content: features[0].properties.amerindian
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.OTHER,
                  content: features[0].properties.other_race
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.MEDIAN_INCOME,
                  content: features[0].properties.median_inc
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.INCOME_ON_RENT,
                  content: features[0].properties.percent_in
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.EMPLOYED_POP,
                  content: features[0].properties.employed_p
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.HOUSEHOLD_LANGUAGE_TOTAL,
                  // this is not the original attrib for household language, but it get lost on all changes of data
                  content: features[0].properties.househol_2
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.INTERNET_SUBSCRIPTION_BROADBAND,
                  content: features[0].properties.internet_2
                },
                {
                  title: MAP_CONSTANTS.POPUP_CENSUS_TITLES.INTERNET_SUBSCRIPTION_NO_ACCESS,
                  content: features[0].properties.internet_5
                }
              ];
              ReactDOM.render(
                <PopupCensus
                  operatingDbaName="2020 Census Block Characteristics"
                  entityType=""
                  city=""
                  state=""
                  contentArray={contentPoPUpRow}
                  typePopup={MAP_LAYERS.CSH_SOCIO_TABLE}
                />,
                popupNode
              );
              popupRef
                .current
                .setLngLat(e.lngLat)
                .setDOMContent(popupNode)
                .addTo(map.current);
            }
          }
        );
        onMouseLeaveLayer(currentID, () => {
          popupRef.current.remove();
          const filterHover = [...BASIC_FILTER_CENSUS];
          filterHover[2] = '';
          MAP_STYLES[MAP_LAYERS.CSH_SOCIO_TABLE].forEach((layerHover: GenericAny, _index) => {
            if (layerHover.type === 'line' && layerHover.hoverLayer) {
              const currentIDHover = `${MAP_LAYERS.CSH_SOCIO_TABLE}-layer-${_index}`;
              setFilter(currentIDHover, filterHover);
            }
          });
        });
      });
    }
  }, [
    tilesSocio,
    addVectorLayer,
    addVectorSource,
    loaded,
    map,
    onMouseMoveLayer,
    onMouseLeaveLayer,
    popupRef,
    setFilter
  ]);

  useEffect(() => {
    if (allTiles.length && loaded) {
      allTiles.forEach((boundaryTiles: GenericAny) => {
        const currentTiles = boundaryTiles.data.tiles;
        const currentKey = boundaryTiles.key;
        if (currentTiles && currentKey) {
          addVectorSource(`${currentKey}-source`, currentTiles);
          BOUNDARIES_STYLES.forEach((bstyle, index) => {
            addVectorLayer(
              `${currentKey}-layer-${index}`,
              { ...bstyle, source: `${currentKey}-source` }
            );
          });
        }
      });
    }
  }, [allTiles, addVectorLayer, addVectorSource, loaded]);

  const addObjToArray = useCallback((statesArray: string[], newState: string) => {
    const resultArr: string[] = statesArray.filter((st: string) => (st !== newState));
    if (resultArr.length === statesArray.length) resultArr.push(newState);
    return resultArr;
  }, []);

  const clickState = useCallback((e) => {
    if (e.features.length > 0) {
      const abbrevFilter = e.features[0].properties.abbrev;
      const currentStates = [...subdropdown];
      const newSelectedStates = addObjToArray(currentStates, abbrevFilter);
      setSubdropdown(newSelectedStates);
    }
  }, [subdropdown, setSubdropdown, addObjToArray]);

  useEffect(() => {
    if (tilesState && loaded) {
      addVectorSource(`${MAP_LAYERS.CSH_STATE_TABLE}-source`, tilesState.tiles);
      MAP_STYLES[MAP_LAYERS.CSH_STATE_TABLE].forEach((layer, index) => {
        const currentID = `${MAP_LAYERS.CSH_STATE_TABLE}-layer-${index}`;
        addVectorLayer(
          currentID,
          { ...layer, source: `${MAP_LAYERS.CSH_STATE_TABLE}-source` }
        );
        onMouseMoveLayer(currentID, (e) => {
          const filterHover = [...BASIC_FILTER_STATES];
          filterHover[2] = e.features[0].properties.abbrev;
          MAP_STYLES[MAP_LAYERS.CSH_STATE_TABLE].forEach((layerHover: GenericAny, _index) => {
            if (layerHover.type === 'line' && layerHover.hoverLayer) {
              const currentIDHover = `${MAP_LAYERS.CSH_STATE_TABLE}-layer-${_index}`;
              setFilter(currentIDHover, filterHover);
            }
          });
        });

        onMouseLeaveLayer(currentID, () => {
          const filterHover = [...BASIC_FILTER_STATES];
          MAP_STYLES[MAP_LAYERS.CSH_STATE_TABLE].forEach((layerHover: GenericAny, _index) => {
            if (layerHover.type === 'line' && layerHover.hoverLayer) {
              const currentIDHover = `${MAP_LAYERS.CSH_STATE_TABLE}-layer-${_index}`;
              setFilter(currentIDHover, filterHover);
            }
          });
        });

        onceClickLayer(currentID, clickState);
      });
    }
  }, [
    clickState,
    setSelectedState,
    tilesState,
    addVectorSource,
    addVectorLayer,
    onceClickLayer,
    onMouseLeaveLayer,
    onMouseMoveLayer,
    setFilter,
    loaded,
    dispatch,
    setHasChangedSubdropdown
  ]);
  useEffect(() => {
    if (stateBbox?.data?.length > 0 && isZooming && !hasChangeNavbar) {
      fitBounds(stateBbox.data, () => {
        setIsZooming(false);
      });
    }
  }, [stateBbox, fitBounds, setIsZooming, hasChangeNavbar, isZooming]);

  const clickDivision = useCallback((e) => {
    if (e.features.length > 0) {
      const divisionFilter = (e.features[0].properties.division as string).toUpperCase();
      const currentDivisions = [...subdropdown];
      const newSelectedDivisions = addObjToArray(currentDivisions, divisionFilter);
      setSubdropdown(newSelectedDivisions);
    }
  }, [subdropdown, setSubdropdown, addObjToArray]);

  useEffect(() => {
    const LABEL_STATES_ID = 'state-label';
    if (tilesDivisions && loaded) {
      addVectorSource(`${MAP_LAYERS.CSH_STATE_DIVISION}-source`, tilesDivisions.tiles);
      MAP_STYLES[MAP_LAYERS.CSH_STATE_DIVISION].forEach((layer, index) => {
        const currentID = `${MAP_LAYERS.CSH_STATE_DIVISION}-layer-${index}`;
        addVectorLayer(
          currentID,
          { ...layer, source: `${MAP_LAYERS.CSH_STATE_DIVISION}-source` },
          LABEL_STATES_ID
        );
        onMouseMoveLayer(currentID, (e) => {
          const filterHover = [...BASIC_FILTER_DIVISIONS];
          filterHover[2] = e.features[0].properties.division;
          MAP_STYLES[MAP_LAYERS.CSH_STATE_DIVISION].forEach((layerHover: GenericAny, _index) => {
            if (layerHover.type === 'line' && layerHover.hoverLayer) {
              const currentIDHover = `${MAP_LAYERS.CSH_STATE_DIVISION}-layer-${_index}`;
              setFilter(currentIDHover, filterHover);
            }
          });
        });

        onMouseLeaveLayer(currentID, () => {
          const filterHover = [...BASIC_FILTER_DIVISIONS];
          MAP_STYLES[MAP_LAYERS.CSH_STATE_DIVISION].forEach((layerHover: GenericAny, _index) => {
            if (layerHover.type === 'line' && layerHover.hoverLayer) {
              const currentIDHover = `${MAP_LAYERS.CSH_STATE_DIVISION}-layer-${_index}`;
              setFilter(currentIDHover, filterHover);
            }
          });
        });
        onceClickLayer(currentID, clickDivision);
      });
    }
  }, [
    onceClickLayer,
    clickDivision,
    setSelectedDivision,
    tilesDivisions,
    addVectorSource,
    addVectorLayer,
    onClickLayer,
    onMouseLeaveLayer,
    onMouseMoveLayer,
    setFilter,
    loaded,
    dispatch,
    setHasChangedSubdropdown
  ]);

  useEffect(() => {
    if (tilesPublica && loaded) {
      const sourceName = `${MAP_LAYERS.CSH_PUBLICA_TABLE}-source`;
      addVectorSource(sourceName, tilesPublica.tiles);
      MAP_STYLES[MAP_LAYERS.CSH_PUBLICA_TABLE].forEach((layer, index) => {
        addVectorLayer(
          `${MAP_LAYERS.CSH_PUBLICA_TABLE}-layer-${index}`,
          { ...layer, source: sourceName }
        );
        if (layer.type === 'circle') {
          onClickLayer(
            `${MAP_LAYERS.CSH_PUBLICA_TABLE}-layer-${index}`,
            (e: GenericAny) => {
              const features = map.current.queryRenderedFeatures(
                e.point,
                {
                  layers: [`${MAP_LAYERS.CSH_PUBLICA_TABLE}-layer-${index}`]
                }
              );
              if (features.length > 0) {
                const popupNode = document.createElement('div');
                const contentPoPUpRow = [
                  {
                    title: MAP_CONSTANTS.POPUP_ACGME_TITLES.INSTITUTION,
                    content: features[0].properties.designated_institutional_official || '-'
                  },
                  {
                    title: MAP_CONSTANTS.POPUP_ACGME_TITLES.EFFECTIVE_DATE,
                    content: features[0].properties.effective_date,
                  },
                  {
                    title: MAP_CONSTANTS.POPUP_ACGME_TITLES.PHONE,
                    content: features[0].properties.phone || '-'
                  },
                  {
                    title: MAP_CONSTANTS.POPUP_ACGME_TITLES.EMAIL,
                    content: features[0].properties.email
                  }
                ];
                ReactDOM.render(
                  <Popup
                    operatingDbaName={features[0].properties.sponsor_number_name || ''}
                    entityType={features[0].properties.accreditation_status || ''}
                    city={features[0].properties.city || ''}
                    state={features[0].properties.state1 || ''}
                    contentArray={contentPoPUpRow}
                    typePopup={MAP_LAYERS.CSH_PUBLICA_TABLE}
                  />,
                  popupNode
                );
                popupRef
                  .current
                  .setLngLat(e.lngLat)
                  .setDOMContent(popupNode)
                  .addTo(map.current);
              }
            }
          );
        }
      });
    }
  }, [
    map,
    popupRef,
    tilesPublica,
    loaded,
    addVectorSource,
    addVectorLayer,
    onMouseLeaveLayer,
    onMouseMoveLayer,
    onClickLayer
  ]);

  useEffect(() => {
    if (tilesCHEA && loaded) {
      const sourceName = `${MAP_LAYERS.CSH_CHEA_TABLE}-source`;
      addVectorSource(sourceName, tilesCHEA.tiles);
      MAP_STYLES[MAP_LAYERS.CSH_CHEA_TABLE].forEach((layer, index) => {
        addVectorLayer(
          `${MAP_LAYERS.CSH_CHEA_TABLE}-layer-${index}`,
          { ...layer, source: sourceName }
        );
        if (layer.type === 'circle') {
          onClickLayer(
            `${MAP_LAYERS.CSH_CHEA_TABLE}-layer-${index}`,
            (e: GenericAny) => {
              const popupNode = document.createElement('div');
              const features = map.current.queryRenderedFeatures(
                e.point,
                {
                  layers: [`${MAP_LAYERS.CSH_CHEA_TABLE}-layer-${index}`]
                }
              );
              if (features.length > 0) {
                const contentPoPUpRow = [
                  {
                    title: MAP_CONSTANTS.POPUP_CHEA_TITLES.URL,
                    content: features[0].properties.url || '-'
                  }
                ];
                ReactDOM.render(
                  <Popup
                    operatingDbaName={features[0].properties.chea_profile || ''}
                    entityType={features[0].properties.accredited_program || ''}
                    city={features[0].properties.city || ''}
                    state={features[0].properties.state_territory_province || ''}
                    contentArray={contentPoPUpRow}
                    typePopup={MAP_LAYERS.CSH_CHEA_TABLE}
                  />,
                  popupNode
                );
                popupRef
                  .current
                  .setLngLat(e.lngLat)
                  .setDOMContent(popupNode)
                  .addTo(map.current);
              }
            }
          );
        }
      });
    }
  }, [
    map,
    popupRef,
    tilesCHEA,
    loaded,
    addVectorSource,
    addVectorLayer,
    onMouseLeaveLayer,
    onMouseMoveLayer,
    onClickLayer
  ]);

  useEffect(() => {
    if (tiles && loaded) {
      addVectorSource(`${MAP_LAYERS.CSH_FACILITIES_TABLE}-source`, tiles.tiles);
      MAP_STYLES[MAP_LAYERS.CSH_FACILITIES_TABLE].forEach((layer, index) => {
        addVectorLayer(
          `${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-${index}`,
          { ...layer, source: `${MAP_LAYERS.CSH_FACILITIES_TABLE}-source` }
        );
      });
      onMouseMoveLayer(`${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-0`, (e) => {
        const features = map.current.queryRenderedFeatures(
          e.point,
          {
            layers: [`${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-0`]
          }
        );
        const filterHover = [...BASIC_FILTER_FACILITIES];
        filterHover[2] = features[0].properties.entityid;
        MAP_STYLES[MAP_LAYERS.CSH_FACILITIES_TABLE].forEach((layerHover: GenericAny, _index) => {
          if (layerHover.hoverLayer && !popupRefFacilities.current.isOpen()) {
            const currentIDHover = `${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-${_index}`;
            setFilter(currentIDHover, filterHover);
          }
        });
      });
      onMouseLeaveLayer(`${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-0`, () => {
        const filterHover = [...BASIC_FILTER_FACILITIES];
        MAP_STYLES[MAP_LAYERS.CSH_FACILITIES_TABLE].forEach((layerHover: GenericAny, _index) => {
          if (layerHover.hoverLayer && !popupRefFacilities.current.isOpen()) {
            const currentIDHover = `${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-${_index}`;
            setFilter(currentIDHover, filterHover);
          }
        });
      });
      onClickLayer(`${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-0`, (e) => {
        if (e.features.length > 0) {
          let filterHover = [...BASIC_FILTER_FACILITIES];
          filterHover[2] = e.features[0].properties.entityid;
          MAP_STYLES[MAP_LAYERS.CSH_FACILITIES_TABLE].forEach((layer: GenericAny, _index) => {
            if (layer.hoverLayer) {
              const currentIDHover = `${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-${_index}`;
              setFilter(currentIDHover, filterHover);
            }
          });
          const popupNode = document.createElement('div');
          const contentPoPUpRow = [
            {
              title: MAP_CONSTANTS.DIVISION,
              content: e.features[0].properties.division || '-'
            },
            {
              title: MAP_CONSTANTS.MARKET,
              content: e.features[0].properties.market || '-'
            },
            {
              title: MAP_CONSTANTS.GME,
              content: parseToYesNo(e.features[0].properties.gme),
            },
            {
              title: MAP_CONSTANTS.EBITDA,
              content: parseToPercentage(e.features[0].properties.ebitda_after_special_charges)
            },
            {
              title: MAP_CONSTANTS.HEALT_EQUITY,
              content: e.features[0].properties.health_equity || '-'
            },
            {
              title: MAP_CONSTANTS.LICENSED_BEDS,
              content: e.features[0].properties.licensed_beds_acute || '-'
            }
          ];
          ReactDOM.render(
            <Popup
              operatingDbaName={e.features[0].properties.operating_dba_name || ''}
              entityType={e.features[0].properties.entity_type || ''}
              city={e.features[0].properties.city || ''}
              state={e.features[0].properties.state || ''}
              contentArray={contentPoPUpRow}
              typePopup={MAP_LAYERS.CSH_FACILITIES_TABLE}
            />,
            popupNode
          );
          popupRefFacilities
            .current
            .setLngLat(e.lngLat)
            .setDOMContent(popupNode)
            .addTo(map.current);
          popupRefFacilities.current.on('close', () => {
            filterHover = [...BASIC_FILTER_FACILITIES];
            filterHover[2] = 'none';
            MAP_STYLES[MAP_LAYERS.CSH_FACILITIES_TABLE].forEach((layer: GenericAny, _index) => {
              if (layer.hoverLayer) {
                const currentIDHover = `${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-${_index}`;
                setFilter(currentIDHover, filterHover);
              }
            });
          });
        }
      });
    }
  }, [
    tiles,
    addVectorSource,
    addVectorLayer,
    onClickLayer,
    popupRefFacilities,
    map,
    loaded,
    setFilter,
    onMouseMoveLayer,
    onMouseLeaveLayer
  ]);

  useEffect(() => {
    if (loaded && drivingTime !== '') {
      allTiles.forEach((boundaryTiles: GenericAny) => {
        const currentKey = boundaryTiles.key;
        BOUNDARIES_STYLES.forEach((layer, index) => {
          setLayerVisibility(`${currentKey}-layer-${index}`, false);
        });
      });
      if (tabSelected === 2) {
        BOUNDARIES_STYLES.forEach((layer, index) => {
          setLayerVisibility(`${drivingTime}-layer-${index}`, true);
        });
      }
    }
  }, [drivingTime, loaded, setLayerVisibility, allTiles, tabSelected]);

  useEffect(() => {
    if (loaded) {
      MAP_STYLES[MAP_LAYERS.CSH_CHEA_TABLE].forEach((_, index) => {
        setLayerVisibility(`${MAP_LAYERS.CSH_CHEA_TABLE}-layer-${index}`, cheaVisible);
      });
      MAP_STYLES[MAP_LAYERS.CSH_PUBLICA_TABLE].forEach((_, index) => {
        setLayerVisibility(`${MAP_LAYERS.CSH_PUBLICA_TABLE}-layer-${index}`, publicaVisible);
      });
      MAP_STYLES[MAP_LAYERS.CSH_FACILITIES_TABLE].forEach((_, index) => {
        setLayerVisibility(`${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-${index}`, facilitiesVisible);
      });
      MAP_STYLES[MAP_LAYERS.CSH_SOCIO_TABLE].forEach((layer: GenericAny, index) => {
        setLayerVisibility(`${MAP_LAYERS.CSH_SOCIO_TABLE}-layer-${index}`, censusVisible);
      });
    }
  }, [
    loaded,
    setLayerVisibility,
    facilitiesVisible,
    cheaVisible,
    publicaVisible,
    censusVisible,
    currentCensusStyle
  ]);

  useEffect(() => {
    MAP_CONSTANTS.LAYER_NAME_LIST.forEach((layerId: string) => {
      moveLayer(layerId);
    });
    MAP_STYLES[MAP_LAYERS.CSH_FACILITIES_TABLE].forEach((layer, index) => {
      moveLayer(`${MAP_LAYERS.CSH_FACILITIES_TABLE}-layer-${index}`);
    });
  }, [layers, moveLayer]);

  useEffect(() => {
    if (tabSelected === 2 && dataEntities && dataEntities.data && allTiles.length && loaded) {
      allTiles.forEach((boundaryTiles: GenericAny) => {
        const currentTiles = boundaryTiles.data.tiles;
        const currentKey = boundaryTiles.key;
        if (currentTiles && currentKey) {
          BOUNDARIES_STYLES.forEach((bstyle, index) => {
            const newFilter: GenericAny = [...BASIC_ENTITIES_FILTER];
            const indexFilter = 2;
            newFilter[indexFilter][1] = [...dataEntities.data.map((value: GenericAny) => value.entityid)];
            const layerId = `${currentKey}-layer-${index}`;
            setFilter(layerId, newFilter);
          });
        }
      });
    }
  }, [dataEntities, tabSelected, setFilter, allTiles, loaded]);

  const changeGateCensus = (visibility: boolean) => {
    setGateCensus(true);
    setCensusVisible(visibility);
  };

  return (
    <div className="colmap">
      <div className="mymap">
        <div ref={mapContainer} style={{ height: '100%' }} />
      </div>
      {
        showLayerList
        && (
        <div className="layerarea">
          <FilterArea
            panel={panel}
            setPanelExpanded={setPanel}
            visible={censusVisible}
            setVisible={changeGateCensus}
            showExtra
            title={MAP_CONSTANTS.LEGEND_TITLES.CENSUS_BLOCK_GROUPS}
            options={LEGENDS[currentCensusStyle]}
            legendTitle={currentCensusStyle}
          />
          <FilterArea
            panel={panel}
            visible={publicaVisible}
            setVisible={setPublicaVisible}
            setPanelExpanded={setPanel}
            title={MAP_CONSTANTS.LEGEND_TITLES.ACGME_PROGRAMS}
            options={[
              { className: 'ic-agme', name: 'Accreditation Council for Graduate Medical Education (ACGME) Programs' }
            ]}
          />
          <FilterArea
            panel={panel}
            visible={cheaVisible}
            setVisible={setCheaVisible}
            setPanelExpanded={setPanel}
            title={MAP_CONSTANTS.LEGEND_TITLES.CHEA_PROGRAMS}
            options={[
              {
                className: 'ic_chea',
                name: 'Nursing programs approved by the Council for Higher Education Accreditation (CHEA)'
              }
            ]}
          />
          <FilterArea
            panel={panel}
            visible={facilitiesVisible}
            setVisible={setFacilitiesVisible}
            setPanelExpanded={setPanel}
            title={MAP_CONSTANTS.LEGEND_TITLES.CSH_FACILITES}
            options={[
              { className: 'cpurle100', name: 'Acute Care' },
              { className: 'cyellowa100', name: 'Ambulatory Care' },
              { className: 'cpink100', name: 'Clinic' },
              { className: 'caqua100', name: 'Freestanding ER' },
              { className: 'cnavy100', name: 'Outpatient' },
              { className: 'corange100', name: 'Other' }
            ]}
          />
        </div>
        )
      }
      <div className={classNames('barlayer', { active: showLayerList })}>
        <button
          type="button"
          className="light"
          onClick={() => {
            setShowLayerList(!showLayerList);
          }}
          onMouseEnter={() => setHoverLayer(true)}
          onMouseLeave={() => setHoverLayer(false)}
        >
          <span className={classNames({ txt: !hoverLayer, 'txt-active': hoverLayer })}>Layers</span>
          <span className={classNames({ iclayer: !hoverLayer, 'iclayer-active': hoverLayer })} />
        </button>
      </div>
      <div className="carto-logo">
        <div className="ic_carto" />
      </div>
    </div>
  );
};
