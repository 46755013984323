import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { forwardRef, useContext } from 'react';
import { GenericAny, LanguageSlider } from '../@types';
import { FilterContextState } from '../context/FilterContext';
import { LanguageDispatch, LanguageState } from '../context/LanguageContext';
import { IcLayerContent } from './IcLayerContent';
import { TooltipContent } from './ToolipContent';
import VizonomySlider from './VizonomySlider';

export const Language = forwardRef<HTMLDivElement, GenericAny>((props, ref) => {
  const { sliders } = useContext(LanguageState);
  const { setSliders } = useContext(LanguageDispatch);
  const { state } = useContext(FilterContextState);
  const { currentCensusStyle } = state;
  return (
    <div id="language" ref={ref}>
      <div className="pattributebar">
        <p className="txtl">Language</p>
      </div>
      {
        sliders.map((slider: LanguageSlider, sliderIndex: number) => (
          <div key={slider.field}>
            <div className="attributedetail">
              <div className="btit1">
                <div className="title">
                  <span className="txt">
                    <span className={
                        classNames({
                          txt: currentCensusStyle !== slider.label,
                          'txt-active-label': currentCensusStyle === slider.label
                        })
                      }
                    >
                      {slider.label}

                    </span>
                    <Tooltip
                      title={(
                        <TooltipContent
                          title={slider.label}
                          description={slider.description}
                        />
                        )}
                      TransitionProps={{ timeout: 0 }}
                    >
                      <button type="button" className="light">
                        <span className="icinfo" />
                      </button>
                    </Tooltip>
                  </span>
                </div>
                {
                  (!slider.subSliders || slider.subSliders.length === 0) && (
                    <IcLayerContent label={slider.label} />
                  )
                }
              </div>
              <div className="btit2 txt">
                {slider.subtitle}
              </div>
            </div>
            <div className="optionchk">
              <VizonomySlider
                format={slider.format}
                step={slider.step}
                min={slider.min}
                max={slider.max}
                value={slider.value}
                setValue={(value: number[]) => {
                  const newSliders = sliders.map(
                    (sliderContainer: LanguageSlider, sliderContainerIndex: number) => {
                      if (sliderContainerIndex === sliderIndex) {
                        return {
                          ...sliderContainer,
                          value
                        };
                      }
                      return sliderContainer;
                    }
                  );
                  setSliders(newSliders);
                }}
              />
            </div>
          </div>
        ))
    }
    </div>
  );
});
