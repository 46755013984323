import React, { useCallback, useMemo, useState } from 'react';
import { StaffSlider } from '../@types';
import { MAP_CONSTANTS, TOOLTIP_INFO } from '../constants';
import { StaffRetentionDispatch, StaffRetentionState } from '../context/StaffRetentionContext';
import { sliderDefault, sliderPercentage } from '../utils';

const options = [
  {
    label: 'Employee Engagement',
    max: 5,
    format: sliderDefault,
    step: 0.01,
    field: MAP_CONSTANTS.FIELDS_CSH.EMPLOYEE_ENGAGEMENT,
    description: TOOLTIP_INFO.EMPLOYEE_ENGAGEMENT.description
  },
  {
    label: 'New Hire Adjusted Retention Rate',
    max: 100,
    format: sliderPercentage,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.NEW_HIRE_ADJUSTED_RETENTION_RATE,
    description: TOOLTIP_INFO.NEW_HIRE_ADJUSTED_RETENTION_RATE.description
  },
  {
    label: 'Physician/APP Experience',
    max: 5,
    format: sliderDefault,
    step: 0.01,
    field: MAP_CONSTANTS.FIELDS_CSH.PHYSICIAN_APP_EXPERIENCE,
    description: TOOLTIP_INFO.PHYSICIAN_APP_EXPERIENCE.description
  }
];

export const StaffRetentionProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [sliders, setSliders] = useState<StaffSlider[]>(
    options.map((option) => ({
      value: [0, option.max],
      label: option.label,
      field: option.field,
      subtitle: 'Select or input score range.',
      format: option.format,
      max: option.max,
      step: option.step,
      description: option.description
    }))
  );

  const resetSliders = useCallback(() => {
    setSliders(
      options.map((option) => ({
        value: [0, option.max],
        label: option.label,
        field: option.field,
        subtitle: 'Select or input score range.',
        format: option.format,
        max: option.max,
        step: option.step,
        description: ''
      }))
    );
  }, [setSliders]);
  const state = useMemo(() => ({
    sliders
  }), [sliders]);
  const setters = useMemo(() => ({
    setSliders, resetSliders
  }), [setSliders, resetSliders]);

  return (
    <StaffRetentionState.Provider value={state}>
      <StaffRetentionDispatch.Provider value={setters}>
        {children}
      </StaffRetentionDispatch.Provider>
    </StaffRetentionState.Provider>
  );
};
