import { createContext } from 'react';

export const AccordionState = createContext({
  basemap: ''
} as {
  basemap: string
});

export const AccordionDispatch = createContext({
  setBasemap: () => {
    console.info('AccordionDispatch is not set');
  }
} as {
  setBasemap: (basemap: string) => void
});
