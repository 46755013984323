import { Tooltip } from '@mui/material';
import {
  useContext,
  useEffect,
  forwardRef
} from 'react';
import { PatientSlider, GenericAny } from '../@types';
import { TYPES } from '../constants/context';
import { FilterContextDispatch } from '../context/FilterContext';
import { PatientServicesDispatch, PatientServicesState } from '../context/PatientServicesContext';
import { TooltipContent } from './ToolipContent';
import VizonomySlider from './VizonomySlider';

export const PatientServices = forwardRef<HTMLDivElement, GenericAny>((props, ref) => {
  const { dispatch } = useContext(FilterContextDispatch);
  const { sliders } = useContext(PatientServicesState);
  const { setSliders } = useContext(PatientServicesDispatch);

  useEffect(() => {
    const filters = sliders.filter((slider) => {
      if (slider.value[0] === 0 && slider.value[1] === slider.max) {
        return false;
      }
      return true;
    }).map((slider) => {
      const [min, max] = slider.value;
      return {
        field: slider.field,
        value: ['all', ['>=', ['get', slider.field], min],
          ['<=', ['get', slider.field], max]]
      };
    });
    filters.forEach((filter) => {
      dispatch({ type: TYPES.REMOVE_AND_REPLACE, payload: filter });
    });
  }, [sliders, dispatch]);

  return (
    <div id="patients" ref={ref}>
      <div className="pattributebar">
        <p className="txtl">Patient Services</p>
      </div>
      {
        sliders.map((slider: PatientSlider, sliderIndex: number) => (
          <div key={slider.field}>
            <div className="attributedetail">
              <div className="btit1">
                <div className="title">
                  <span className="txt">
                    {slider.label}
                    {
                      slider.label2
                      && (
                      <>
                        <br />
                        {slider.label2}
                      </>
                      )
                    }
                    <Tooltip
                      title={(
                        <TooltipContent
                          title={slider.label}
                          description={slider.description}
                        />
                        )}
                      TransitionProps={{ timeout: 0 }}
                    >
                      <button type="button" className="light">
                        <span className="icinfo" />
                      </button>
                    </Tooltip>
                  </span>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="btit2 txt">
                {slider.subtitle}
              </div>
            </div>
            <div className="optionchk">
              <VizonomySlider
                format={slider.format}
                value={slider.value}
                setValue={(value: number[]) => {
                  const newSliders = sliders.map((s: PatientSlider, i: number) => {
                    if (i === sliderIndex) {
                      return {
                        ...s,
                        value
                      };
                    }
                    return s;
                  });
                  setSliders(newSliders);
                }}
              />
            </div>
          </div>
        ))
      }
    </div>
  );
});
