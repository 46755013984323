import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Input,
  Slider
} from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useMemo,
  useRef
} from 'react';
import { sliderDefault } from '../utils';

const CustomizedSlider = styled(Slider)({
  color: '#5D3DC7',
  height: 2,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#5D3DC7',
    border: '6px solid #beb1e8',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    color: 'gray'
  },
  '& .MuiSlider-mark': {
    opacity: 0,
    '&.MuiSlider-markActive': {
      opacity: 0
    },
  },
  '& .MuiSlider-markLabel': {
    fontSize: '10px'
  }
});

const totalInputWidth = 240;
const inputWidth = 56;

const VizonomySlider = ({
  value,
  setValue,
  min = 0,
  max = 100,
  format = sliderDefault,
  step = 1,
  sliderKey = '',
}: {
  sliderKey?: string;
  value: number[];
  setValue: Dispatch<SetStateAction<number[]>> | ((values: number[]) => void);
  min?: number;
  max?: number;
  format?: (v: number) => string;
  step?: number | null;
}) => {
  const minRef = useRef<HTMLInputElement>(null);
  const maxRef = useRef<HTMLInputElement>(null);

  const updateRefs = () => {
    if (minRef.current && minRef.current.value.startsWith('0') && minRef.current.value.length > 1) {
      minRef.current.value = minRef.current.value.substring(1);
    }
    if (maxRef.current && maxRef.current.value.startsWith('0') && maxRef.current.value.length > 1) {
      maxRef.current.value = maxRef.current.value.substring(1);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    updateRefs();
    const newValues = value.map((element, vIndex) => {
      if (vIndex === index) {
        return event.target.value === '' ? 0 : Number(event.target.value);
      }
      return element;
    });
    newValues.sort((a, b) => a - b);
    if (newValues[0] < min) {
      newValues[0] = min;
    }
    if (newValues[1] > max) {
      newValues[1] = max;
    }
    setValue(newValues);
  };

  const handleBlur = () => {
    updateRefs();
    if (value[0] < min) {
      setValue([min, value[1]]);
    } else if (value[1] > max) {
      setValue([value[0], max]);
    }
  };

  const marks = useMemo(() => {
    const markValues = [0, 20, 40, 60, 80, 100];
    if (max === 1) {
      return markValues
        .map((mark) => (mark * max) / 100)
        .filter((mark, markIndex, arr) => arr.indexOf(mark) === markIndex)
        .map((percentageValue) => ({
          value: percentageValue,
          label: format(percentageValue)
        }));
    }
    return markValues
      .map((mark) => Math.ceil((mark * max) / 100))
      .filter((mark, markIndex, arr) => arr.indexOf(mark) === markIndex)
      .map((percentageValue) => ({
        value: percentageValue,
        label: format(percentageValue)
      }));
  }, [format, max]);
  return (
    <Box>
      <Grid container spacing={2} alignItems="center" sx={{ minWidth: totalInputWidth }}>
        <Grid item>
          <Input
            inputRef={minRef}
            sx={{
              width: inputWidth,
              fontSize: 14,
              border: '2px solid #E3E5E5',
              borderRadius: '10px',
            }}
            disableUnderline
            fullWidth
            value={value[0]}
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, 0)}
            onBlur={handleBlur}
            inputProps={{
              min,
              max,
              type: 'number'
            }}
          />
        </Grid>
        <Grid item>
          <CustomizedSlider
            key={sliderKey}
            sx={{ minWidth: totalInputWidth - 2 * inputWidth }}
            max={max}
            marks={marks}
            value={value}
            step={step}
            onChange={(event: Event, newValue: number | number[]) => {
              setValue(newValue as number[]);
            }}
          />
        </Grid>
        <Grid item>
          <Input
            inputRef={maxRef}
            sx={{
              width: inputWidth,
              fontSize: 14,
              border: '2px solid #E3E5E5',
              borderRadius: '10px',
            }}
            disableUnderline
            fullWidth
            value={value[1]}
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, 1)}
            onBlur={handleBlur}
            inputProps={{
              min,
              max,
              type: 'number'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VizonomySlider;
