import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { BASEMAP_TYPES, GenericAny } from '../@types';
import { MAP_CONSTANTS } from '../constants';

mapboxgl.accessToken = MAP_CONSTANTS.TOKEN;

export const useMapboxMap = (intialBbox: number[][]) => {
  const mapContainer = useRef<GenericAny>('');
  const map = useRef<GenericAny>(null);
  const [zoom, setZoom] = useState(0);
  const popupRefFacilities = useRef(new mapboxgl.Popup({ offset: 15 }));
  const popupRef = useRef(new mapboxgl.Popup({ offset: 15 }));
  const [layers, setLayers] = useState<GenericAny>([]);
  const [sources, setSources] = useState<GenericAny>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [currentBasemap, setCurrentBasemap] = useState<GenericAny>('');

  const loadImagesOnInit = () => {
    if (map.current) {
      return;
    }
    map.current.loadImage('images/ic-chea.png', (error: GenericAny, image: GenericAny) => {
      if (error) {
        console.error('error on load ', error);
        return;
      }
      if (!map.current.hasImage('ic-chea')) {
        map.current.addImage('ic-chea', image);
      }
    });
    map.current.loadImage('images/ic-acgme.png', (error: GenericAny, image: GenericAny) => {
      if (error) {
        console.error('error on load ', error);
        return;
      }
      if (!map.current.hasImage('ic-acgme')) {
        map.current.addImage('ic-acgme', image);
      }
    });
  };
  useEffect(() => {
    if (map.current) {
      return;
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MAP_CONSTANTS.BASEMAP.STREET,
      bounds: intialBbox as LngLatBoundsLike,
      maxBounds: MAP_CONSTANTS.US_BOUNDS as LngLatBoundsLike,
    });
    map.current.addControl(new mapboxgl.NavigationControl({
      showCompass: false
    }));
    map.current.loadImage('images/ic-chea.png', (error: GenericAny, image: GenericAny) => {
      if (error) {
        console.error('error on load ', error);
        return;
      }
      if (!map.current.hasImage('ic-chea')) {
        map.current.addImage('ic-chea', image);
      }
    });
    map.current.loadImage('images/ic-acgme.png', (error: GenericAny, image: GenericAny) => {
      if (error) {
        console.error('error on load ', error);
        return;
      }
      if (!map.current.hasImage('ic-acgme')) {
        map.current.addImage('ic-acgme', image);
      }
    });
    map.current.on('styleimagemissing', () => {
      loadImagesOnInit();
    });
  });

  useEffect(() => {
    if (!map.current) {
      return;
    }
    map.current.on('load', () => {
      map.current.resize();
      setLoaded(true);
    });
    map.current.on('move', () => {
      setZoom(map.current.getZoom());
    });
  });

  useEffect(() => {
    if (!map.current) {
      return;
    }
    setZoom(map.current.getZoom());
  }, []);

  const addGeoJSONSource = (id: string, data: GenericAny) => {
    if (!map.current) {
      return;
    }
    map.current.addSource(id, {
      type: 'geojson',
      data
    });
  };

  const addGeoJSONLayer = (id: string, sourceId: string, layer: GenericAny) => {
    if (!map.current) {
      return;
    }
    map.current.addLayer({
      id,
      source: sourceId,
      ...layer
    });
  };

  const changeBaseMap = (baseMap: BASEMAP_TYPES) => {
    if (!map.current) {
      return;
    }
    setCurrentBasemap(baseMap);
    map.current.setStyle(MAP_CONSTANTS.BASEMAP[baseMap]);
    map.current.once('style.load', () => {
      layers.forEach((layer: GenericAny) => {
        if (map.current.getLayer(layer.id)) {
          map.current.removeLayer(layer.id);
        }
      });
      sources.forEach((source: GenericAny) => {
        if (map.current.getSource(source.id)) {
          map.current.removeSource(source.id);
        }
        map.current.addSource(source.id, source.source);
      });
      layers.forEach((layer: GenericAny) => {
        const currLayer = map.current.getLayer(layer.id);
        if (currLayer) {
          map.current.removeLayer(layer.id);
          map.current.addLayer(currLayer);
        } else {
          map.current.addLayer(layer);
        }
      });
    });
  };

  const addVectorSource = useCallback((id: string, source: string[]) => {
    if (!map.current) {
      return;
    }
    if (!map.current.getSource(id)) {
      map.current.addSource(id, {
        type: 'vector',
        tiles: source
      });
      const sourceObject = {
        id,
        source: {
          type: 'vector',
          tiles: source
        }
      };
      setSources((oldVectorSource: GenericAny) => [...oldVectorSource, sourceObject]);
    }
  }, []);

  const getSource = (id: string) => {
    if (!map.current) {
      return null;
    }
    return map.current.getSource(id);
  };

  const getLayer = (id: string) => {
    if (!map.current) {
      return null;
    }
    return map.current.getLayer(id);
  };

  const setLayerVisibilityLayers = useCallback((id: string, visibility: boolean) => {
    setLayers((oldLayers: GenericAny) => {
      const newLayers = oldLayers.map((layer: GenericAny) => {
        if (layer.id === id) {
          return {
            ...layer,
            layout: {
              ...layer.layout,
              visibility: visibility ? 'visible' : 'none'
            }
          };
        }
        return layer;
      });
      return newLayers;
    });
  }, []);

  const setLayerVisibility = useCallback((id: string, visibility: boolean) => {
    if (getLayer(id)) {
      map.current.setLayoutProperty(id, 'visibility', visibility ? 'visible' : 'none');
    }
  }, []);

  const addVectorLayer = useCallback((id: string, layer: GenericAny, beneathLayer?: string) => {
    if (!map.current) {
      return;
    }
    if (!map.current.getLayer(id)) {
      const completeLayer = {
        id,
        ...layer,
        'source-layer': 'pluto15v1'
      };
      if (beneathLayer) {
        map.current.addLayer(completeLayer, beneathLayer);
      } else {
        map.current.addLayer(completeLayer);
      }
      setLayers((oldLayers: GenericAny) => [...oldLayers, completeLayer]);
    }
  }, []);

  const onClickLayer = useCallback((id: string, callback: (e: GenericAny) => void) => {
    if (!map.current) {
      return;
    }
    map.current.on('click', id, callback);
  }, []);

  const onceClickLayer = useCallback((id: string, callback: (e: GenericAny) => void) => {
    if (!map.current) {
      return;
    }
    map.current.once('click', id, callback);
  }, []);

  const onMouseMoveLayer = useCallback((id: string, callback: (e: GenericAny) => void) => {
    if (!map.current) {
      return;
    }
    map.current.on('mousemove', id, callback);
  }, []);

  const onMouseLeaveLayer = useCallback((id: string, callback: (e: GenericAny) => void) => {
    if (!map.current) {
      return;
    }
    map.current.on('mouseleave', id, callback);
  }, []);

  const onMoveEnd = useCallback((callback: (e: GenericAny) => void) => {
    if (!map.current) {
      return;
    }
    map.current.on('moveend', callback);
  }, []);

  const getZoom = () => {
    if (!map.current) {
      return 0;
    }
    return map.current.getZoom();
  };

  const zoomIn = () => {
    if (!map.current) {
      return;
    }
    map.current.zoomIn();
    setZoom(getZoom());
  };

  const zoomOut = () => {
    if (!map.current) {
      return;
    }
    map.current.zoomOut();
    setZoom(getZoom());
  };

  const resetBounds = useCallback(() => {
    if (!map.current) {
      return;
    }
    map.current.fitBounds(
      MAP_CONSTANTS.US_BOUNDS_FIT
    );
  }, []);

  const fitBounds = (bbox: number[][], callback?: GenericAny) => {
    if (!map.current) {
      return;
    }
    map.current.fitBounds(
      bbox,
      {
        padding: 50
      }
    );
    map.current.once('moveend', () => {
      callback();
    });
  };

  const setFilter = useCallback((id: string, filter: GenericAny) => {
    if (!map.current) {
      return;
    }
    if (map.current.getLayer(id)) {
      map.current.setFilter(id, filter);
    }
  }, []);

  const moveLayer = useCallback((id: string) => {
    if (!map.current) {
      return;
    }
    if (map.current.getLayer(id)) {
      map.current.moveLayer(id);
    }
  }, []);

  const updatePaintProperty = useCallback((layerId: string, propertyName: string, value: GenericAny) => {
    if (!map.current) {
      return;
    }
    map.current.once('render', () => {
      if (map.current.getLayer(layerId)) {
        map.current.setPaintProperty(layerId, propertyName, value);
      }
    });
  }, []);

  return {
    mapContainer,
    map,
    addVectorSource,
    addVectorLayer,
    addGeoJSONSource,
    addGeoJSONLayer,
    changeBaseMap,
    getSource,
    getLayer,
    setFilter,
    popupRef,
    onClickLayer,
    onceClickLayer,
    onMouseLeaveLayer,
    onMouseMoveLayer,
    onMoveEnd,
    zoomIn,
    zoomOut,
    zoom,
    loaded,
    setLayerVisibility,
    fitBounds,
    resetBounds,
    layers,
    moveLayer,
    updatePaintProperty,
    currentBasemap,
    setLayerVisibilityLayers,
    popupRefFacilities
  };
};
