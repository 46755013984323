import React, { useCallback, useMemo, useState } from 'react';
import { HousingSlider, HousingSubSlider } from '../@types';
import { MAP_CONSTANTS, TOOLTIP_INFO } from '../constants';
import { HousingDispatch, HousingState } from '../context/HousingContext';
import { sliderPercentage, sliderthousands, slidertMillions } from '../utils';

const options = [
  {
    label: 'No label',
    subtitle: 'no subtitle',
    min: 0,
    max: 100,
    multiplier: 1,
    format: sliderPercentage,
    step: 1,
    invisible: true,
    field: MAP_CONSTANTS.FIELDS_CSH.PERCENT_INCOME_SPENT_ON_RENT,
  },
  {
    label: TOOLTIP_INFO.MEDIAN_HOME_VALUE.title,
    subtitle: TOOLTIP_INFO.MEDIAN_HOME_VALUE.subtitle,
    min: 0,
    max: 2,
    multiplier: 1000000,
    format: slidertMillions,
    step: 0.01,
    field: MAP_CONSTANTS.FIELDS_CSH.OWNER_OCCUPIED_HOUSING_UNITS_MEDIAN_VALUE,
    description: TOOLTIP_INFO.MEDIAN_HOME_VALUE.description,
  },
  {
    label: TOOLTIP_INFO.PERCENT_HOUSING_UNITS_OWNED_AND_RENTED.title,
    subtitle: TOOLTIP_INFO.PERCENT_HOUSING_UNITS_OWNED_AND_RENTED.subtitle,
    min: 0,
    max: 0,
    format: sliderthousands,
    step: 0,
    field: '',
    description: TOOLTIP_INFO.subtitle,
    subSliders: [
      {
        label: TOOLTIP_INFO.HOUSING_UNITS_OWNED.title,
        min: 0,
        max: 100,
        value: [0, 100],
        multiplier: 1,
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.OWNER_OCCUPIED_HOUSING_UNITS,
        hasTooltip: true,
        description: TOOLTIP_INFO.HOUSING_OWNED.description,
        title: TOOLTIP_INFO.HOUSING_OWNED.title
      },
      {
        label: TOOLTIP_INFO.HOUSING_UNITS_RENTED.title,
        min: 0,
        max: 100,
        value: [0, 100],
        multiplier: 1,
        format: sliderPercentage,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.HOUSING_UNITS_RENTER_OCCUPIED,
        hasTooltip: true,
        description: TOOLTIP_INFO.HOUSING_RENTED.description,
        title: TOOLTIP_INFO.HOUSING_RENTED.title
      }
    ]
  },
  {
    label: TOOLTIP_INFO.MEDIAN_RENT.title,
    subtitle: TOOLTIP_INFO.MEDIAN_RENT.subtitle,
    min: 0,
    max: 3,
    format: sliderthousands,
    multiplier: 1000,
    step: 1000,
    field: MAP_CONSTANTS.FIELDS_CSH.MEDIAN_RENT,
    description: TOOLTIP_INFO.MEDIA_RENT.description,
  },
  {
    label: TOOLTIP_INFO.AVERAGE_PERCENT_INCOME_SPENT_ON_RENT.title,
    subtitle: TOOLTIP_INFO.AVERAGE_PERCENT_INCOME_SPENT_ON_RENT.subtitle,
    min: 0,
    max: 100,
    multiplier: 1,
    format: sliderPercentage,
    step: 1,
    field: '',
    description: TOOLTIP_INFO.AVERAGE_INCOME_SPENT_ON_RENT.description,
    subSliders: [
      {
        label: TOOLTIP_INFO.RENT_OVER_50_PERCENT_INCOME.title,
        min: 0,
        max: 50,
        value: [0, 50],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.RENT_OVER_50_PERCENT,
        hasTooltip: true,
        description: TOOLTIP_INFO.AMOUNT_SPENT_OVER_50.description,
        title: TOOLTIP_INFO.AMOUNT_SPENT_OVER_50.title
      },
      {
        label: TOOLTIP_INFO.RENT_IS_40_50_PERCENT_INCOME.title,
        min: 0,
        max: 50,
        value: [0, 50],
        multiplier: 1,
        format: sliderPercentage,
        step: 0.1,
        field: MAP_CONSTANTS.FIELDS_CSH.RENT_40_TO_50_PERCENT,
        hasTooltip: true,
        description: TOOLTIP_INFO.AMOUNT_SPENT_40_50.description,
        title: TOOLTIP_INFO.AMOUNT_SPENT_40_50.title
      },
      {
        label: TOOLTIP_INFO.RENT_IS_35_40_PERCENT_INCOME.title,
        min: 0,
        max: 50,
        value: [0, 50],
        multiplier: 1,
        format: sliderPercentage,
        step: 0.01,
        field: MAP_CONSTANTS.FIELDS_CSH.RENT_35_TO_40_PERCENT,
        hasTooltip: true,
        description: TOOLTIP_INFO.AMOUNT_SPENT_35_40.description,
        title: TOOLTIP_INFO.AMOUNT_SPENT_35_40.title
      },
      {
        label: TOOLTIP_INFO.RENT_IS_30_35_PERCENT_INCOME.title,
        min: 0,
        max: 50,
        value: [0, 50],
        format: sliderPercentage,
        multiplier: 1,
        step: 0.01,
        field: MAP_CONSTANTS.FIELDS_CSH.RENT_30_TO_35_PERCENT,
        hasTooltip: true,
        description: TOOLTIP_INFO.AMOUNT_SPENT_30_35.description,
        title: TOOLTIP_INFO.AMOUNT_SPENT_30_35.title
      },
      {
        label: TOOLTIP_INFO.RENT_IS_25_30_PERCENT_INCOME.title,
        min: 0,
        max: 50,
        value: [0, 50],
        multiplier: 1,
        format: sliderPercentage,
        step: 0.01,
        field: MAP_CONSTANTS.FIELDS_CSH.RENT_25_TO_30_PERCENT,
        hasTooltip: true,
        description: TOOLTIP_INFO.AMOUNT_SPENT_25_30.description,
        title: TOOLTIP_INFO.AMOUNT_SPENT_25_30.title
      },
      {
        label: TOOLTIP_INFO.RENT_IS_20_25_PERCENT_INCOME.title,
        min: 0,
        max: 50,
        value: [0, 50],
        multiplier: 1,
        format: sliderPercentage,
        step: 0.01,
        field: MAP_CONSTANTS.FIELDS_CSH.RENT_20_TO_25_PERCENT,
        hasTooltip: true,
        description: TOOLTIP_INFO.AMOUNT_SPENT_20_25.description,
        title: TOOLTIP_INFO.AMOUNT_SPENT_20_25.title
      },
      {
        label: TOOLTIP_INFO.RENT_IS_15_20_PERCENT_INCOME.title,
        min: 0,
        max: 50,
        value: [0, 50],
        multiplier: 1,
        format: sliderPercentage,
        step: 0.01,
        field: MAP_CONSTANTS.FIELDS_CSH.RENT_15_TO_20_PERCENT,
        hasTooltip: true,
        description: TOOLTIP_INFO.AMOUNT_SPENT_15_20.description,
        title: TOOLTIP_INFO.AMOUNT_SPENT_15_20.title
      },
      {
        label: TOOLTIP_INFO.RENT_IS_10_15_PERCENT_INCOME.title,
        min: 0,
        max: 50,
        value: [0, 50],
        multiplier: 1,
        format: sliderPercentage,
        step: 0.01,
        field: MAP_CONSTANTS.FIELDS_CSH.RENT_10_TO_15_PERCENT,
        hasTooltip: true,
        description: TOOLTIP_INFO.AMOUNT_SPENT_10_15.description,
        title: TOOLTIP_INFO.AMOUNT_SPENT_10_15.title
      },
      {
        label: TOOLTIP_INFO.RENT_UNDER_10_PERCENT_INCOME.title,
        min: 0,
        max: 50,
        value: [0, 50],
        multiplier: 1,
        format: sliderPercentage,
        step: 0.01,
        field: MAP_CONSTANTS.FIELDS_CSH.RENT_UNDER_10_PERCENT,
        hasTooltip: true,
        description: TOOLTIP_INFO.AMOUNT_SPENT_10.description,
        title: TOOLTIP_INFO.AMOUNT_SPENT_10.title
      }
    ]
  },
  {
    label: TOOLTIP_INFO.AVERAGE_OF_POPULATION_EMPLOYED_AND_UNEMPLOYED.title,
    subtitle: TOOLTIP_INFO.AVERAGE_OF_POPULATION_EMPLOYED_AND_UNEMPLOYED.subtitle,
    min: 0,
    max: 0,
    format: sliderthousands,
    step: 0,
    field: '',
    description: '',
    subSliders: [
      {
        label: TOOLTIP_INFO.EMPLOYED.title,
        min: 0,
        max: 80,
        value: [0, 80],
        format: sliderPercentage,
        multiplier: 1,
        step: 1,
        field: MAP_CONSTANTS.FIELDS_CSH.EMPLOYED_POP,
        hasTooltip: true,
        description: TOOLTIP_INFO.HOUSING_EMPLOYED.description,
        title: TOOLTIP_INFO.HOUSING_EMPLOYED.title
      },
      {
        label: TOOLTIP_INFO.UNEMPLOYED.title,
        min: 0,
        max: 20,
        value: [0, 20],
        format: sliderPercentage,
        multiplier: 1,
        step: 0.01,
        field: MAP_CONSTANTS.FIELDS_CSH.UNEMPLOYED_POP,
        hasTooltip: true,
        description: TOOLTIP_INFO.HOUSING_UNEMPLOYED.description,
        title: TOOLTIP_INFO.HOUSING_UNEMPLOYED.title
      }
    ]
  }
];

export const HousingProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [sliders, setSliders] = useState<HousingSlider[]>(
    options.map((option) => ({
      max: option.max,
      min: option.min,
      value: [option.min, option.max],
      invisible: option.invisible,
      format: option.format,
      multiplier: option.multiplier,
      field: option.field,
      label: option.label,
      subtitle: option.subtitle,
      step: option.step,
      description: option.description,
      subSliders: option.subSliders?.map((element: HousingSubSlider) => ({
        value: [element.min, element.max],
        label: element.label,
        field: element.field,
        format: element.format,
        multiplier: option.multiplier,
        max: element.max,
        min: element.min,
        step: element.step,
        hasTooltip: element.hasTooltip,
        description: element.description,
        title: element.title
      })) || [],
    }))
  );
  const resetSliders = useCallback(() => {
    setSliders(
      options.map((option) => ({
        max: option.max,
        min: option.min,
        value: [option.min, option.max],
        format: option.format,
        field: option.field,
        label: option.label,
        subtitle: option.subtitle,
        multiplier: option.multiplier,
        step: option.step,
        description: option.description,
        subSliders: option.subSliders?.map((element:HousingSubSlider) => ({
          value: [element.min, element.max],
          label: element.label,
          field: element.field,
          format: element.format,
          multiplier: option.multiplier,
          max: element.max,
          min: element.min,
          step: element.step,
          hasTooltip: element.hasTooltip,
          description: element.description,
          title: element.title
        })) || [],
      }))
    );
  }, [setSliders]);
  const state = useMemo(() => ({
    sliders
  }), [sliders]);
  const setters = useMemo(() => ({
    setSliders, resetSliders
  }), [setSliders, resetSliders]);

  return (
    <HousingState.Provider value={state}>
      <HousingDispatch.Provider value={setters}>
        {children}
      </HousingDispatch.Provider>
    </HousingState.Provider>
  );
};
