export const TYPES = {
  SET_FILTER: 'SET_FILTER',
  SET_SHOW_STATES: 'SET_SHOW_STATES',
  SET_STATES: 'SET_STATES',
  ADD_FILTER: 'ADD_FILTER',
  RESET_FILTER: 'RESET_FILTER',
  REMOVE_AND_REPLACE: 'REMOVE_AND_REPLACE',
  REMOVE: 'REMOVE',
  SET_STATE_BBOX: 'SET_STATE_BBOX',
  SET_SHOW_DIVISIONS: 'SET_SHOW_DIVISIONS',
  SET_CURRENT_CENSUS: 'SET_CURRENT_CENSUS',
  SET_GATE_SIDEBAR: 'SET_GATE_SIDEBAR'
};
