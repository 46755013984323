import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { forwardRef, useContext } from 'react';
import { EconomySlider, EconomySubSlider, GenericAny } from '../@types';
import { EconomyDispatch, EconomyState } from '../context/EconomyContext';
import { FilterContextState } from '../context/FilterContext';
import { IcLayerContent } from './IcLayerContent';
import { TooltipContent } from './ToolipContent';
import VizonomySlider from './VizonomySlider';

export const Economy = forwardRef<HTMLDivElement, GenericAny>((props, ref) => {
  const { sliders } = useContext(EconomyState);
  const { setSliders } = useContext(EconomyDispatch);
  const { state } = useContext(FilterContextState);
  const { currentCensusStyle } = state;
  return (
    <div id="economy" ref={ref}>
      <div className="pattributebar">
        <p className="txtl">Economy</p>
      </div>
      {
        sliders.map((slider: EconomySlider, sliderIndex: number) => (
          <div key={slider.field}>
            <div className="attributedetail">
              <div className="btit1">
                <div className="title">
                  <span className="txt">
                    <span className={
                        classNames({
                          txt: currentCensusStyle !== slider.label,
                          'txt-active-label': currentCensusStyle === slider.label
                        })
                      }
                    >
                      {slider.label}

                    </span>
                    <Tooltip
                      title={(
                        <TooltipContent
                          title={slider.label}
                          description={slider.description}
                        />
                        )}
                      TransitionProps={{ timeout: 0 }}
                    >
                      <button type="button" className="light">
                        <span className="icinfo" />
                      </button>
                    </Tooltip>
                  </span>
                </div>
                {
                  (!slider.subSliders || slider.subSliders.length === 0) && (
                    <IcLayerContent label={slider.label} />
                  )
                }
              </div>
              <div className="btit2 txt">
                {slider.subtitle}
              </div>
            </div>
            {
                slider.subSliders && slider.subSliders.length > 0
                  ? slider.subSliders?.map(
                    (subSlider: EconomySubSlider, subSliderIndex: number) => (
                      <div key={subSlider.label.split(' ').join('')}>
                        <div className="btit3">
                          <div className="iclayer-conteiner">
                            <span className="txt">
                              <span className={
                                  classNames({
                                    txt: currentCensusStyle !== subSlider.label,
                                    'txt-active-label': currentCensusStyle === subSlider.label
                                  })
                                }
                              >
                                {subSlider.label}

                              </span>
                              {subSlider.hasTooltip && (
                                <Tooltip
                                  title={(
                                    <TooltipContent
                                      title={`${slider.label} - ${subSlider.label}`}
                                      description={subSlider.description ? subSlider.description : ''}
                                    />
                                    )}
                                  TransitionProps={{ timeout: 0 }}
                                >
                                  <button type="button" className="light">
                                    <span className="icinfo" />
                                  </button>
                                </Tooltip>
                              )}
                            </span>
                            <IcLayerContent label={subSlider.label} />
                          </div>
                        </div>
                        <div className="optionchk">
                          <VizonomySlider
                            format={subSlider.format}
                            step={subSlider.step}
                            min={subSlider.min}
                            max={subSlider.max}
                            value={subSlider.value}
                            setValue={(value: number[]) => {
                              const newSlider = sliders.map(
                                (sliderContainer: EconomySlider, sliderContainerIndex: number) => {
                                  if (sliderContainerIndex === sliderIndex) {
                                    const subSliders = sliderContainer.subSliders?.map(
                                      (subSliderInContainer: EconomySubSlider, subSliderInContainerIndex: number) => {
                                        if (subSliderInContainerIndex === subSliderIndex) {
                                          return {
                                            ...subSliderInContainer,
                                            value
                                          };
                                        }
                                        return subSliderInContainer;
                                      }
                                    );
                                    return {
                                      ...sliderContainer,
                                      subSliders
                                    };
                                  }
                                  return sliderContainer;
                                }
                              );
                              setSliders(newSlider);
                            }}
                          />
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="optionchk">
                      <VizonomySlider
                        format={slider.format}
                        step={slider.step}
                        min={slider.min}
                        max={slider.max}
                        value={slider.value}
                        setValue={(value: number[]) => {
                          const newSliders = sliders.map(
                            (sliderContainer: EconomySlider, sliderContainerIndex: number) => {
                              if (sliderContainerIndex === sliderIndex) {
                                return {
                                  ...sliderContainer,
                                  value
                                };
                              }
                              return sliderContainer;
                            }
                          );
                          setSliders(newSliders);
                        }}
                      />
                    </div>
                  )
              }
          </div>
        ))
    }
    </div>
  );
});
