import { GenericAny, HeadCell, TableData } from '../@types';
import { CSV_FILENAME, headers } from '../constants';
import { parserBooleanToYesNo } from './parsers';

export const downloadCsv = (downloadData: TableData[], filters: string[], otherfilters: GenericAny[]) => {
  let fileContent = 'CSH Filters Applied: \n';
  fileContent += `${Object.keys(filters).map((key: GenericAny) => {
    if (key === 'subdropdown') {
      const tempEntity = filters as unknown as GenericAny;
      return `Area: ${tempEntity.area[0].toUpperCase() + tempEntity.area.substring(1)} - ${
        tempEntity.subdropdown.map(
          (element: GenericAny) => {
            let tempArray = element.split(' ');
            if (tempArray.length > 1) {
              tempArray = tempArray.map(
                (word: string) => {
                  if (word === 'JOA') {
                    return 'JOA ';
                  }
                  if (word === 'AT') {
                    return 'at ';
                  }
                  return `${word[0].toUpperCase() + word.substring(1)} `;
                }
              );
              return `${tempArray}; `;
            }
            return `${element[0].toUpperCase() + element.substring(1)}; `;
          }
        ).toString().slice(0, -2)}\n`;
    }
    if (key === 'healthEq' || key === 'charity') {
      const tempEntity = filters[key] as unknown as GenericAny;
      return `${key === 'healthEq' ? 'Health Equity: '
        : 'Charity & Medicaid Service: '}${tempEntity[0]}%-${tempEntity[1]}%\n`;
    }
    if (Array.isArray(filters[key])) {
      if (typeof filters[key][0] === 'string') {
        const tempEntity = filters[key] as unknown as GenericAny[];
        return `${key !== 'replaceNames' ? key[0].toUpperCase() + key.substring(1) : 'Area'}: ${
          tempEntity.map(
            (item) => `${item[0].toUpperCase() + item.substring(1)}; `
          ).toString().slice(0, -2)}\n`;
      }
      const tempSubFilter = filters[key] as unknown as GenericAny[];
      return tempSubFilter.map((content: GenericAny) => {
        const { value, label } = content;
        if (label === 'New Hire Adjusted Retention Rate') {
          return `${label}: ${value[0]}%-${value[1]}%\n`;
        }
        if (label === 'EBITDA (after special charges)') {
          return `${label}: ${value[0]}% to ${value[1]}%\n`;
        }
        if (label === 'NPSR % Growth') {
          return `${label}: ${value[0]}% to ${value[1]}%\n`;
        }
        let { format } = content;
        if (format === '%') format = '%';
        else format = '';
        return `${label}: ${value[0]}${format}-${value[1]}${format}\n`;
      });
    }
    const tempSubFilter2 = filters[key] as unknown as GenericAny;
    if ((tempSubFilter2.yes !== true || tempSubFilter2.no !== true)
    && key !== 'dropdownName' && key !== 'area') {
      return `${key === 'graduate'
        ? 'Graduate Medical Education: '
        : 'Tax 501(c)(3) Status: '}${parserBooleanToYesNo(tempSubFilter2.yes, tempSubFilter2.no)}\n`;
    }
    return null;
  }).toString().replace(/,/g, '')}\n`;
  fileContent += 'ACS Filters Applied:\n';
  fileContent += `${otherfilters.map(
    (filter: GenericAny) => {
      if (filter.value.includes('min')) {
        return `${filter.label}: ${filter.value.substring(0, 3)
          + filter.value[3].toUpperCase()
          + filter.value.substring(4)}utes Driving\n`;
      }
      if (filter.value.includes('miles')) {
        return `${filter.label}: ${filter.value.substring(0, 3)
          + filter.value[3].toUpperCase()
          + filter.value.substring(4)} Radius\n`;
      }
      let parserFirst = `${filter.format !== undefined
        ? filter.format(filter.value[0] * (filter.multiplier || 1))
        : `${filter.value[0] * (filter.multiplier || 1)}`}`;
      let parserSecond = `${filter.format !== undefined
        ? filter.format(filter.value[1] * (filter.multiplier || 1))
        : `${filter.value[1] * (filter.multiplier || 1)}`}`;
      if (parserFirst.includes('K') || parserSecond.includes('M')) {
        parserFirst = `$${parserFirst.slice(0, -1)}`;
        parserSecond = `$${parserSecond.slice(0, -1)}`;
      }
      const Parservalue = `${`${parserFirst} to ${parserSecond}`}`;
      return `${filter.parentLabel ? `${filter.parentLabel}-` : ''}${filter.label}: ${Parservalue}\n`;
    }
  ).toString().replace(/,/g, '')}\n`;
  fileContent += `${headers.map((head) => head.label).join(',')}\n`;
  const mapFn = (d: TableData) => headers.map((head: HeadCell) => head.format(d[head.id]));
  const stringFormatted = downloadData.map(mapFn);
  fileContent += stringFormatted.map((r: string[]) => `"${r.join('","')}"`).join('\n');
  const blob = new Blob([fileContent], {
    type: 'text/csv'
  });
  const url = window.URL.createObjectURL(
    new Blob([blob])
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    CSV_FILENAME
  );
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};
