import React from 'react';
import { AccordionProvider } from './AccordionProvider';
import { FilterProvider } from './FilterProvider';
import { SidebarFirstTabProvider } from './SidebarFirstTabProvider';
import { SidebarSecondTabProvider } from './SidebarSecondTabProvider';
import { BboxProvider } from './BboxProvider';
import { VisibilityProvider } from './VisibilityProvider';
import { SidebarThirdTabProvider } from './SidebarThirdTabProvider';
import { BoundariesProvider } from './BoundariesProvider';

export const MapProviderWrapper = ({ children }: React.PropsWithChildren<unknown>) => (
  <AccordionProvider>
    <SidebarFirstTabProvider>
      <SidebarSecondTabProvider>
        <SidebarThirdTabProvider>
          <FilterProvider>
            <BboxProvider>
              <BoundariesProvider>
                <VisibilityProvider>
                  {children}
                </VisibilityProvider>
              </BoundariesProvider>
            </BboxProvider>
          </FilterProvider>
        </SidebarThirdTabProvider>
      </SidebarSecondTabProvider>
    </SidebarFirstTabProvider>
  </AccordionProvider>
);
