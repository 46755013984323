import { GenericAny, TableData } from '../@types';
import { downloadCsv } from '../utils';

export const TableActions = ({
  search,
  setSearch,
  data,
  selected,
  getAllData,
  filters,
  otherfilters
}: {
  search: string;
  setSearch: (value: React.SetStateAction<string>) => void;
  data: GenericAny;
  selected: string[];
  getAllData: GenericAny;
  filters: GenericAny;
  otherfilters: GenericAny;
}) => {
  const getData = async () => {
    if (selected.length === 0) {
      const response = await getAllData();
      return response.data;
    }
    return data.data.filter((d: TableData) => selected.includes(d.entityid));
  };

  const handleDownload = async () => {
    const downloadData = await getData();
    downloadCsv(downloadData, filters, otherfilters);
  };

  return (
    <div className="head">
      <div className="field">
        <input
          onChange={(e) => setSearch(e.target.value)}
          className="light"
          type="text"
          value={search}
          placeholder="Search address or Facility Name"
        />
        <i className="icsearch" />
      </div>
      <button type="button" className="light" onClick={handleDownload}>
        <span className="icexport" />
        <span className="txt">Export to CSV</span>
      </button>
    </div>
  );
};
