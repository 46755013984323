import { createContext } from 'react';
import { HospitalSlider } from '../@types';

export const HospitalCharacteristicsState = createContext({
  sliders: []
} as {
  sliders: HospitalSlider[]
});

export const HospitalCharacteristicsDispatch = createContext({
  setSliders: () => {
    console.info('HospitalCharacteristicsDispatch is not set');
  },
  resetSliders: () => {
    console.info('HospitalCharacteristicsDispatch is not set');
  }
} as {
  setSliders: (sliders: HospitalSlider[]) => void,
  resetSliders: () => void
});
