import { useLogin } from '../hooks/useLogin';

export const Login = () => {
  const {
    handleSubmit, setUsername, setPassword, errorText
  } = useLogin();
  return (
    <div className="container">
      <div className="bglogin" />
      <button type="button" className="light opback">
        <span className="icbck" />
        <span className="txtl">Back to Home</span>
      </button>
      <div className="containerlogin">

        <div className="formlogin">
          <div className="logoarea">
            <div className="iclogoblack" />
            <p className="txt">- Geospatial Analysis</p>
          </div>
          <div className="group">
            <div className="item">
              <div className="txt title">Email / Username</div>
              <div className="field">
                <input
                  className="light"
                  type="text"
                  placeholder="Email"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <i className="icouserbk" />
              </div>
            </div>
            <div className="item">
              <div className="txt title">Password</div>
              <div className="field">
                <input
                  className="light"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i className="icotolock" />
              </div>
            </div>
            <div className="item">
              <button type="button" className="light" onClick={handleSubmit}>Log In</button>
            </div>
            <div>
              {errorText}
            </div>
          </div>
          <div className="snarea">
            <div className="blk fb">
              <a href="http://" className="fb" target="_blank" rel="noreferrer">
                <div className="icfb" />
              </a>
            </div>
            <div className="blk cli">
              <a href="http://" className="cli" target="_blank" rel="noreferrer">
                <div className="icli" />
              </a>
            </div>
            <div className="blk ww">
              <a href="http://" className="ww" target="_blank" rel="noreferrer">
                <div className="icww" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
