import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useQuery } from 'react-query';
import { GenericAny, HeadCell, TableData } from '../@types';
import { ENDPOINTS, headers, STATE } from '../constants';
import { SidebarFirstTabState } from '../context/SidebarFirstTabContext';
import { PrimaryAttributesState } from '../context/PrimaryAtributesContext';
import { PatientServicesState } from '../context/PatientServicesContext';
import { HospitalCharacteristicsState } from '../context/HospitalCharacteristicsContext';
import { FinantialHealthState } from '../context/FinantialHealthContext';
import { StaffRetentionState } from '../context/StaffRetentionContext';
import { TableActions } from './TableActions';
import { filterSliderDefaultValue } from '../utils';
import { useGetColumn } from '../hooks/useGetColumn';
import { EconomyState } from '../context/EconomyContext';
import { PopulationState } from '../context/PopulationContext';
import { HousingState } from '../context/HousingContext';
import { LanguageState } from '../context/LanguageContext';
import { TechnologyState } from '../context/TechnologyContext';
import { DrivingState } from '../context/DrivingContext';

const fetchPage = async (page: number, limit: number, search: string, body: GenericAny) => {
  const { data } = await axios.post(ENDPOINTS.GET_FACILITIES(page + 1, limit, search), body);
  return data;
};

const useStyles = makeStyles({
  sticky: {
    position: 'sticky',
    left: 0
  }
});
const stickyColumnColor = 'var(--primary-purple-100) !important';
export const Dashboard = () => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [body, setBody] = useState({});
  const [usedFilters, setUsedFilters] = useState<string[]>([]);
  const [usedThirdTabFilters, setUsedThirdTabFilters] = useState<GenericAny[]>([]);
  const [totalRows, setTotalRows] = useState(1);
  const [search, setSearch] = useState('');
  const { area, subdropdown } = useContext(SidebarFirstTabState);
  const {
    entities, healthEq, charity, graduate, taxStatus
  } = useContext(PrimaryAttributesState);
  const { sliders: hospitalSliders } = useContext(HospitalCharacteristicsState);
  const { sliders: patientSliders } = useContext(PatientServicesState);
  const { sliders: finantialSliders } = useContext(FinantialHealthState);
  const { sliders: staffRetentionSliders } = useContext(StaffRetentionState);
  const { drivingTime: DrivingSelect } = useContext(DrivingState);
  const { drivingLabel: DrivingTitle } = useContext(DrivingState);
  const { sliders: economySliders } = useContext(EconomyState);
  const { sliders: populationSliders } = useContext(PopulationState);
  const { sliders: housingSliders } = useContext(HousingState);
  const { sliders: languageSliders } = useContext(LanguageState);
  const { sliders: technologySliders } = useContext(TechnologyState);
  const [selected, setSelected] = useState<string[]>([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const { drivingTime } = useContext(DrivingState);

  const { data, error } = useQuery(
    ['facilities', page, limit, search, body],
    () => fetchPage(page, limit, search, body)
  );

  const { statesNames } = useGetColumn();
  const getAllData = async () => fetchPage(page, totalRows, search, body);

  useEffect(() => {
    if (area && subdropdown) {
      setBody((oldBody) => ({
        ...oldBody,
        area,
        subdropdown: subdropdown.join(',')
      }));
      if (area === STATE) {
        const replaceNames = subdropdown.map((item) => statesNames.find(
          (elem: GenericAny) => elem.abbrev === item
        )?.name);
        setUsedFilters((oldBody) => ({
          ...oldBody,
          area,
          replaceNames
        }));
      } else {
        setUsedFilters((oldBody) => ({
          ...oldBody,
          area,
          subdropdown
        }));
      }
    }
  }, [area, subdropdown, statesNames]);

  useEffect(() => {
    const areTrue = entities.filter((c) => c.checked).map((c) => c.value.toUpperCase());
    const filterswithoutchanges = entities.filter((c) => c.checked).map((c) => c.value);
    if (areTrue.length > 0) {
      setBody((oldBody) => ({
        ...oldBody,
        entities: areTrue
      }));
      setUsedFilters((oldBody) => ({
        ...oldBody,
        entities: filterswithoutchanges
      }));
    }
    if (graduate !== null) {
      setBody((oldBody) => ({
        ...oldBody,
        graduate
      }));
      setUsedFilters((oldBody) => ({
        ...oldBody,
        graduate
      }));
    }
    if (healthEq[0] !== 0 || healthEq[1] !== 100) {
      setBody((oldBody) => ({
        ...oldBody,
        healthEq
      }));
      setUsedFilters((oldBody) => ({
        ...oldBody,
        healthEq
      }));
    }
    if (charity[0] !== 0 || charity[1] !== 100) {
      setBody((oldBody) => ({
        ...oldBody,
        charity
      }));
      setUsedFilters((oldBody) => ({
        ...oldBody,
        charity
      }));
    }
    if (taxStatus !== null) {
      setBody((oldBody) => ({
        ...oldBody,
        taxStatus
      }));
      setUsedFilters((oldBody) => ({
        ...oldBody,
        taxStatus
      }));
    }
    const newArray = [
      ...economySliders,
      ...populationSliders,
      ...housingSliders,
      ...languageSliders,
      ...technologySliders
    ];
    const parsedData: GenericAny[] = [];
    newArray.forEach((slider) => {
      if (slider.subSliders && slider.subSliders.length > 0) {
        slider.subSliders.forEach((subSlider) => {
          parsedData.push({
            value: [subSlider.value[0] * (subSlider.multiplier || 1), subSlider.value[1] * (subSlider.multiplier || 1)],
            field: subSlider.field
          });
        });
      } else {
        parsedData.push({
          value: [slider.value[0] * (slider.multiplier || 1), slider.value[1] * (slider.multiplier || 1)],
          field: slider.field
        });
      }
    });
    setBody((oldBody) => ({
      ...oldBody,
      data: parsedData,
      table: drivingTime
    }));
  }, [
    entities,
    healthEq,
    charity,
    graduate,
    taxStatus,
    economySliders,
    populationSliders,
    housingSliders,
    languageSliders,
    technologySliders,
    drivingTime
  ]);

  useEffect(() => {
    const ranges: { value: number[]; column: string; }[] = [];
    const rangesUsed: { value: number[]; label: string; format?:string;}[] = [];
    hospitalSliders.forEach((hospital) => {
      ranges.push({
        value: hospital.value,
        column: hospital.field
      });
      if (filterSliderDefaultValue(hospital.max, hospital.value)) {
        rangesUsed.push({
          value: hospital.value,
          label: hospital.label,
        });
      }
    });
    patientSliders.forEach((patient) => {
      ranges.push({
        value: patient.value,
        column: patient.field
      });
      if (filterSliderDefaultValue(patient.max, patient.value)) {
        rangesUsed.push({
          value: patient.value,
          label: patient.label,
          format: '%'
        });
      }
    });
    staffRetentionSliders.forEach((staff) => {
      ranges.push({
        value: staff.value,
        column: staff.field
      });
      if (filterSliderDefaultValue(staff.max, staff.value)) {
        rangesUsed.push({
          value: staff.value,
          label: staff.label,
        });
      }
    });
    finantialSliders.forEach((finantial) => {
      ranges.push({
        value: finantial.value,
        column: finantial.field
      });
      if (filterSliderDefaultValue(finantial.max, finantial.value, finantial.label)) {
        rangesUsed.push({
          value: finantial.value,
          label: finantial.label,
        });
      }
    });
    setBody((oldBody) => ({
      ...oldBody,
      ranges
    }));
    if (rangesUsed.length > 0) {
      setUsedFilters((oldBody) => ({
        ...oldBody,
        rangesUsed
      }));
    }
  }, [hospitalSliders, patientSliders, finantialSliders, staffRetentionSliders]);

  useEffect(
    () => {
      const newArray = [
        ...economySliders,
        ...populationSliders,
        ...housingSliders,
        ...languageSliders,
        ...technologySliders
      ];
      newArray.forEach((slider) => {
        if (slider.subSliders && slider.subSliders.length > 0) {
          slider.subSliders.forEach((subSlider) => {
            if (subSlider.value[0] * (subSlider.multiplier || 1) !== 0
            || subSlider.value[1] * (subSlider.multiplier || 1)
            !== (subSlider.max * (subSlider.multiplier || 1) || 0)) {
              usedThirdTabFilters.push({
                parentLabel: slider.label,
                label: subSlider.label,
                value: [
                  subSlider.value[0] * (subSlider.multiplier || 1), subSlider.value[1] * (subSlider.multiplier || 1)
                ],
                format: subSlider.format
              });
            }
          });
        } else if (slider.value[0] * (slider.multiplier || 1) !== 0
        || slider.value[1] * (slider.multiplier || 1) !== (slider.max * (slider.multiplier || 1) || 0)) {
          usedThirdTabFilters.push({
            label: slider.label,
            value: [
              slider.value[0] * (slider.multiplier || 1), slider.value[1] * (slider.multiplier || 1)
            ],
            format: slider.format
          });
        }
      });
      usedThirdTabFilters.push({
        label: 'Community Profile',
        field: DrivingSelect,
        format: (v: string) => `${v}`,
        value: DrivingTitle
      });
      setUsedThirdTabFilters(usedThirdTabFilters);
    },
    [
      economySliders,
      populationSliders,
      housingSliders,
      languageSliders,
      technologySliders,
      usedThirdTabFilters,
      DrivingSelect,
      DrivingTitle
    ]
  );

  useEffect(() => {
    if (!error && data && data.data.length > 0) {
      setTotalRows(data.data[0].total_count);
    } else {
      setTotalRows(0);
    }
  }, [data, error]);

  const handleChangePage = (_: unknown, newPage: number) => {
    if (page === 0 && newPage === -1) return;
    setSelected([]);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event: React.MouseEvent<unknown>, entityid: string) => {
    const selectedIndex = selected.indexOf(entityid);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, entityid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (entityid: string) => selected.indexOf(entityid) !== -1;

  useEffect(() => {
    const fn = () => {
      setIsScrolled((ref?.current?.scrollLeft ?? 0) > 300);
    };
    ref?.current?.addEventListener('scroll', fn);
    const copy = ref?.current;
    return () => {
      copy?.removeEventListener('scroll', fn);
    };
  }, [setIsScrolled, body]);

  return (
    <div className="datatab">
      {JSON.stringify(body) !== '{}' && (
        <>
          <TableActions
            data={data}
            selected={selected}
            search={search}
            setSearch={setSearch}
            getAllData={getAllData}
            filters={usedFilters}
            otherfilters={usedThirdTabFilters}
          />
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer
              ref={ref}
              className="recordarea"
              style={{ overflowX: 'auto' }}
              sx={{ maxHeight: 'calc(100vh - 216px)' }}
            >
              <Table stickyHeader className="tlight" size="small">
                <TableHead>
                  <TableRow>
                    {
                  headers.map((header: HeadCell, headerIndex: number) => {
                    const isSticky = headerIndex === 2;
                    return (
                      <TableCell
                        key={header.label}
                        className={isSticky ? classes.sticky : undefined}
                        sx={{
                          zIndex: isSticky ? 3 : 1,
                          background: (isSticky && isScrolled) ? stickyColumnColor : undefined
                        }}
                      >
                        <span className="txt">{header.label}</span>
                      </TableCell>
                    );
                  })
                }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                data && data.data.map((facility: TableData) => {
                  const isItemSelected = isSelected(facility.entityid);

                  return (
                    <TableRow
                      key={facility.entityid}
                      selected={isItemSelected}
                      onClick={(event) => handleClick(event, facility.entityid)}
                    >
                      {
                        headers.map((header: HeadCell, headerIndex: number) => {
                          const isSticky = headerIndex === 2;
                          return (
                            <TableCell
                              key={header.id}
                              className={isSticky ? classes.sticky : undefined}
                              sx={{
                                padding: 2,
                                background: (isSticky && (isItemSelected || isScrolled)) ? stickyColumnColor : undefined
                              }}
                            >
                              {header.format(facility[header.id] as never)}
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  );
                })
              }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
    </div>
  );
};
