import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMutation } from 'react-query';
import { GenericAny, LoginInfo } from '../@types';
import { ENDPOINTS } from '../constants/url';
import { AUTH_TOKEN, BAD_REQUEST, MAP_ROUTE } from '../constants';

export const useLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');

  const doLogin = async (user: LoginInfo) => {
    const { data: response } = await axios.post(ENDPOINTS.LOGIN(), user);
    return response.data;
  };

  const { mutate, error } = useMutation(doLogin, {
    onSuccess: (data) => {
      localStorage.setItem(AUTH_TOKEN, data.idToken);
      navigate(MAP_ROUTE);
      setErrorText('');
    },
    onError: (err: GenericAny) => {
      if (err.response.status === BAD_REQUEST) {
        setErrorText('Invalid username or password');
      } else {
        setErrorText('Something went wrong');
      }
    }
  });

  const handleSubmit = () => {
    mutate({ username, password });
  };

  return {
    setUsername,
    setPassword,
    error,
    handleSubmit,
    errorText,
  };
};
