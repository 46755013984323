import React, { useCallback, useMemo, useState } from 'react';
import { LanguageSlider } from '../@types';
import { MAP_CONSTANTS, TOOLTIP_INFO } from '../constants';
import { TechnologyDispatch, TechnologyState } from '../context/TechnologyContext';
import { sliderPercentage } from '../utils';

const options = [
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_NO_INTERNET_ACCESS.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_NO_INTERNET_ACCESS.subtitle,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.INTERNET_SUBSCRIPTION_NO_ACCESS,
    description: TOOLTIP_INFO.HOUSEHOLDS_NO_INTERNET_ACCESS.description,
  },
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_INTERNET_ACCESS.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_INTERNET_ACCESS.subtitle,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.INTERNET_SUBSCRIPTION_TOTAL,
    description: TOOLTIP_INFO.HOUSEHOLDS_INTERNET_ACCESS.description,
  },
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_BROADBAND_INTERNET.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_BROADBAND_INTERNET.subtitle,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.INTERNET_SUBSCRIPTION_BROADBAND,
    description: TOOLTIP_INFO.HOUSEHOLDS_BROADBAND_INTERNET.description,
  },
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_SATELLITE_INTERNET.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_SATELLITE_INTERNET.subtitle,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.INTERNET_SUBSCRIPTION_SATELLITE,
    description: TOOLTIP_INFO.HOUSEHOLDS_SATELLITE_INTERNET.description,
  },
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_DIAL_UP_INTERNET.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_ONLY_DIAL_UP_INTERNET.subtitle,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.INTERNET_SUBSCRIPTION_DIAL_UP,
    description: TOOLTIP_INFO.HOUSEHOLDS_DIAL_UP_INTERNET.description,
  }
];

export const TechnologyProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [sliders, setSliders] = useState<LanguageSlider[]>(
    options.map((option) => ({
      max: option.max,
      min: option.min,
      value: [option.min, option.max],
      format: option.format,
      multiplier: option.multiplier,
      field: option.field,
      label: option.label,
      subtitle: option.subtitle,
      step: option.step,
      description: option.description,
    }))
  );
  const resetSliders = useCallback(() => {
    setSliders(
      options.map((option) => ({
        max: option.max,
        min: option.min,
        value: [option.min, option.max],
        format: option.format,
        multiplier: option.multiplier,
        field: option.field,
        label: option.label,
        subtitle: option.subtitle,
        step: option.step,
        description: option.description,
      }))
    );
  }, [setSliders]);
  const state = useMemo(() => ({
    sliders
  }), [sliders]);
  const setters = useMemo(() => ({
    setSliders, resetSliders
  }), [setSliders, resetSliders]);

  return (
    <TechnologyState.Provider value={state}>
      <TechnologyDispatch.Provider value={setters}>
        {children}
      </TechnologyDispatch.Provider>
    </TechnologyState.Provider>
  );
};
