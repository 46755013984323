import React from 'react';
import { QueryClientProvider } from 'react-query';
import {
  BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material';
import { Map } from './pages/map';
import { Login } from './pages/login';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import queryClient from './client/queryClient';
import PrivateRoute from './components/PrivateRoute';
import { LOGIN_ROUTE, MAP_ROUTE, ROOT_ROUTE } from './constants';
import { theme } from './theme';

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path={ROOT_ROUTE} element={<Navigate to={MAP_ROUTE} />} />
            <Route path={MAP_ROUTE} element={<PrivateRoute />}>
              <Route path={MAP_ROUTE} element={<Map />} />
            </Route>
            <Route path={LOGIN_ROUTE} element={<Login />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  </QueryClientProvider>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
