import React, { useCallback, useMemo, useState } from 'react';
import { LanguageSlider } from '../@types';
import { MAP_CONSTANTS, TOOLTIP_INFO } from '../constants';
import { LanguageDispatch, LanguageState } from '../context/LanguageContext';
import { sliderPercentage } from '../utils';

const options = [
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_LIMITED_ENGLISH.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WITH_LIMITED_ENGLISH.subtitle,
    min: 0,
    max: 100,
    format: sliderPercentage,
    multiplier: 1,
    step: 1,
    field: MAP_CONSTANTS.FIELDS_CSH.HOUSEHOLD_LANGUAGE_ENGLISH,
    description: TOOLTIP_INFO.HOUSEHOLDS_LIMITED_ENGLISH.description,
  },
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_SPEAK_SPANISH.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_SPEAK_SPANISH.subtitle,
    min: 0,
    max: 50,
    format: sliderPercentage,
    multiplier: 1,
    step: 0.01,
    field: MAP_CONSTANTS.FIELDS_CSH.HOUSEHOLD_LANGUAGE_SPANISH_LIMITED_ENGLISH,
    description: TOOLTIP_INFO.HOUSEHOLDS_ONLY_SPANISH.description,
  },
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_INDO_EUROPEAN_LANGUAGE.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_INDO_EUROPEAN_LANGUAGE.subtitle,
    min: 0,
    max: 50,
    format: sliderPercentage,
    multiplier: 1,
    step: 0.01,
    field: MAP_CONSTANTS.FIELDS_CSH.HOUSEHOLD_LANGUAGE_OTHER_INDO_EUROPEAN_LIMITED_ENGLISH,
    description: TOOLTIP_INFO.HOUSEHOLDS_ONLY_INDO_EUROPEAN.description,
  },
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ASIAN_PACIFIC_ISLAND_LANGUAGE.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ASIAN_PACIFIC_ISLAND_LANGUAGE.subtitle,
    min: 0,
    max: 50,
    multiplier: 1,
    format: sliderPercentage,
    step: 0.01,
    field: MAP_CONSTANTS.FIELDS_CSH.HOUSEHOLD_LANGUAGE_ASIAN_AND_PACIFIC_ISLAND_LIMITED_ENGLISH,
    description: TOOLTIP_INFO.HOUSEHOLDS_ONLY_ASIAN_PACIFIC.description,
  },
  {
    label: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ANOTHER_LANGUAGE.title,
    subtitle: TOOLTIP_INFO.PERCENT_OF_HOUSEHOLDS_WHO_ONLY_AN_ANOTHER_LANGUAGE.subtitle,
    min: 0,
    max: 50,
    multiplier: 1,
    format: sliderPercentage,
    step: 0.01,
    field: MAP_CONSTANTS.FIELDS_CSH.HOUSEHOLD_LANGUAGE_OTHER_LIMITED_ENGLISH,
    description: TOOLTIP_INFO.HOUSEHOLDS_ONLY_ANOTHER.description,
  }
];

export const LanguageProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [sliders, setSliders] = useState<LanguageSlider[]>(
    options.map((option) => ({
      max: option.max,
      min: option.min,
      value: [option.min, option.max],
      format: option.format,
      multiplier: option.multiplier,
      field: option.field,
      label: option.label,
      subtitle: option.subtitle,
      step: option.step,
      description: option.description,
    }))
  );
  const resetSliders = useCallback(() => {
    setSliders(
      options.map((option) => ({
        max: option.max,
        min: option.min,
        value: [option.min, option.max],
        format: option.format,
        multiplier: option.multiplier,
        field: option.field,
        label: option.label,
        subtitle: option.subtitle,
        step: option.step,
        description: option.description,
      }))
    );
  }, [setSliders]);
  const state = useMemo(() => ({
    sliders
  }), [sliders]);
  const setters = useMemo(() => ({
    setSliders, resetSliders
  }), [setSliders, resetSliders]);

  return (
    <LanguageState.Provider value={state}>
      <LanguageDispatch.Provider value={setters}>
        {children}
      </LanguageDispatch.Provider>
    </LanguageState.Provider>
  );
};
